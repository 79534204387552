import { Component, OnInit } from '@angular/core';
import { ComponentsTalkService } from '../../services/components-talk.service';

@Component({
  selector: 'app-referencias',
  templateUrl: './referencias.component.html',
  styleUrls: ['./referencias.component.scss']
})
export class ReferenciasComponent implements OnInit {
  //Comun, Conectividad, Proveedores 
  referenciasType = "Comun"

  constructor(private talkService: ComponentsTalkService) { }

  ngOnInit() {
    this.talkService.ObservableReferencias.subscribe(data => {
      this.referenciasType = data
    })
  }

}
