<div style="height: 100vh;">
  <app-loader *ngIf="loaderState"></app-loader>
  <app-login></app-login>
  <app-barra-superior style="overflow: hidden;"></app-barra-superior>
  <app-barra-lateral class="barraLateral" id="barraLateral">
    <!--||| RouterOulet => Filtros | Herramientas |||-->
  </app-barra-lateral>
  <app-marcadores>
    <!--||| Mapa |||-->
  </app-marcadores>
  <!-- <app-referencias></app-referencias> -->
</div>