<div id="sidebar-geo" class="barras sidebar-filters dark col-sm-3 float-left">
  <div class="row bar-title-wrap d-flex flex-row align-items-start">
    <h3 class="bar-title col-11">Herramientas</h3>
    <button type="button" class="navbar-toggler col-1 sidebarFilter" data-toggle="collapse"
      data-target=".navbar-collapse" (click)="collapse()">
      <i class="material-icons" style="cursor: pointer">view_headline</i>
    </button>
  </div>
  <div id="barracoord" class="row">

    <div id="infopolygon">

      <div class="bloque">
        <h4>
          <i class="fa fa-calculator"></i> Calcular Área</h4>
        <p>Calcula el área comprendida entre varios puntos.</p>
        <hr>
        <div class="row buttons">

          <div class="col-xl-6 p-0">
            <button id="" data-bool="false" (click)="addPolygon()" class="btnaddArea btn btn-main green">
              <div class="btn-icon">
                <span class="fas fa-pencil-alt"></span>
              </div>
              <p class="btn-content m-0 p-2">
                Dibujar área
              </p>
            </button>

          </div>
        </div>
        <br>
        <div class="row">
          Área seleccionada:
          <b>
            <b [innerHtml]="areaTotal" class="form-control-static" id="area"></b> </b>
          <br />
        </div>
        <!-- <?php if($this->session->userdata('isLoggedIn')) : ?>
                      <div class="row">
                        <button id="btnEstarea" class="btn btn-main" onclick="gettoken(htmlpoli)"><span class="fas fa-eye"></span> Mostrar establecimientos</button>
                      </div>
                  <?php endif;?> -->
        <hr>
        <div class="row">
          <b>Coordenadas</b>
          <textarea class="form-control" rows="3" id="coords"></textarea>
        </div>
        <br />

      </div>


      <div class="bloque">
        <h4>
          <i class="fa fa-arrows-h"></i> Regla</h4>
        <p>Calcula la distancia entre dos o más puntos.</p>
        <hr>

        <p>Distancia:
          <b [innerHtml]="distanceTotal" class="form-control-static" id="distacia">0</b>
          <b>mts.</b>
        </p>
        <br>
        <div class="row buttons">
          <button id="btnaddDist" (click)="addPolyline()" class="btn btn-main green col-sm">
            <div class="btn-icon">
              <span class="fas fa-map-pin"></span>
            </div>
            <p class="btn-content m-0 p-2">
              Seleccionar puntos
            </p>
          </button>
          <button (click)="endPolyline()" id="btnendDist" class="btn btn-main red col-sm">
            <div class="btn-icon">
              <span class="far fa-times-circle"></span>
            </div>
            <p class="btn-content m-0 p-2">
              Finalizar selección
            </p>
          </button>


        </div>

        <!-- <button id="btnaddDist" class="btn btn-main">
            <span class="fas fa-pencil-square-o"></span> Seleccionar puntos</button>
          <button id="btnendDist" class="btn btn-link">
            <span class="fa fa-close"></span> Finalizar</button> -->
      </div>


    </div>
  </div>
</div>