import { Component, OnInit } from "@angular/core";
import { ComponentsTalkService } from "src/app/services/components-talk.service";
import { RequestsApiService } from "src/app/services/requestsApi.service";
import * as topojson from "topojson-client";
import * as Chart from "chart.js";
import { MarcadoresComponent } from "../marcadores/marcadores.component";

declare var require: any;
declare let google: any;
const departaments = require("../../data/departaments.json");
const internetDptos = require("../../data/internetdptos.json");
declare var $: any;

@Component({
  selector: "app-estadisticas",
  templateUrl: "./estadisticas.component.html",
  styleUrls: ["./estadisticas.component.scss"],
  providers: [MarcadoresComponent]
})
export class EstadisticasComponent implements OnInit {
  dataPolygon = new google.maps.Data();
  map: google.maps.Map;
  departaments: any;
  windowsContainer: any = [];
  deptWindow: any = null;
  porcentaje: any;
  legend: any;
  botonesCheck = [false];
  localizaciones: any;
  localizacionesTotal: any;
  collapsed = false;
  markers: any;

  constructor(
    private marcadores: MarcadoresComponent,
    private apiService: RequestsApiService,
    private talkService: ComponentsTalkService
  ) {
    setTimeout(() => {
      this.map = this.talkService.map;
    }, 100);
  }

  collapse() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      $("#sidebar-estadisticas").removeClass("col-md-3");
      $("#sidebar-estadisticas").addClass("collapsed col-md-1");
    } else {
      $("#sidebar-estadisticas").addClass("col-md-3");
      $("#sidebar-estadisticas").removeClass("collapsed");
    }
  }

  ngOnInit() {
    this.markers = this.talkService.arrayMarkers;
    this.marcadores.eliminarMarcadores(this.markers);
    this.apiService.getLocalizacion().subscribe(locations => {
      console.log(locations)
      this.localizacionesTotal = locations;
    });
    this.localizaciones = this.talkService.arrayLocalizacionFiltered;
    setTimeout(() => {
      this.showDptos();
    }, 200);
    this.legend = document.getElementById("legend");
  }

  ngOnDestroy() {
    this.dataPolygon.setMap(null);
    if (this.botonesCheck[0]) {
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].pop();
    }
    this.dataPolygon.forEach(function (feature) {
      if (feature.getProperty("porcent")) {
        feature.setProperty("porcent", "gray");
      }
    });
  }

  showDptos = () => {
    var geoJsonObject = topojson.feature(
      departaments,
      departaments.objects.departamentos
    );
    this.dataPolygon.addGeoJson(geoJsonObject);
    this.dataPolygon.setMap(this.map);
    this.dataPolygon.setStyle(function (feature) {
      var color = "gray";
      if (feature.getProperty("porcent")) {
        color = feature.getProperty("porcent");
      }
      return /** @type {google.maps.Data.StyleOptions} */ {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 2
      };
    });
  };

  getInternetStats = () => {
    if (this.botonesCheck[0]) {
      this.botonesCheck[0] = false;
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].pop();
      this.dataPolygon.forEach(function (feature) {
        if (feature.getProperty("porcent")) {
          feature.setProperty("porcent", "gray");
        }
      });
      this.localizaciones = [];
    } else {
      this.botonesCheck[0] = true;
      $("#legend").css("display", "block");
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
        this.legend
      );

      if (this.windowsContainer[0]) {
        this.windowsContainer[0].close();
      }
      this.windowsContainer.length = 0;
      this.deptWindow = new google.maps.InfoWindow();
      console.log(this.deptWindow);
      this.windowsContainer.push(this.deptWindow);

      var arrayInternetDptos = internetDptos.Departamentos;
      var arrayLocalizaciones = this.localizaciones;
      var arrayLocalizacionesTotal = this.localizacionesTotal;
      var tieneInternet;
      var noTieneInternet;
      var porcent;
      var arrayDepartamentosInternet = [];
      var departamentoNombre;

      this.dataPolygon.forEach(function (feature) {
        tieneInternet = 0;
        noTieneInternet = 0;
        porcent = 0;
        var result = arrayInternetDptos.filter(function (dpto) {
          return dpto.departamento == feature.getProperty("departamento");
        });

        if (!arrayLocalizaciones || arrayLocalizaciones.length == 0) {
          arrayLocalizacionesTotal.forEach(element => {
            if (
              element.domicilio.localidad.departamento.nombre ==
              result[0]["departamento"]
            ) {
              if (element.tieneInternet == 1) {
                departamentoNombre =
                  element.domicilio.localidad.departamento.nombre;
                tieneInternet++;
              } else {
                noTieneInternet++;
              }
            }
          });
        } else {
          arrayLocalizaciones.forEach(element => {
            if (
              element.domicilio.localidad.departamento.nombre ==
              result[0]["departamento"]
            ) {
              if (element.tieneInternet == 1) {
                departamentoNombre =
                  element.domicilio.localidad.departamento.nombre;
                tieneInternet++;
              } else {
                noTieneInternet++;
              }
            }
          });
        }

        porcent = (tieneInternet * 100) / (tieneInternet + noTieneInternet);
        arrayDepartamentosInternet.push({
          departamento: departamentoNombre,
          tieneInternet: tieneInternet,
          noTieneInternet: noTieneInternet,
          porcent: porcent
        });

        if (porcent <= 25) {
          feature.setProperty("porcent", "red");
        } else if (porcent >= 26 && porcent <= 50) {
          feature.setProperty("porcent", "orange");
        } else if (porcent >= 51 && porcent <= 75) {
          feature.setProperty("porcent", "yellow");
        } else if (porcent >= 76 && porcent <= 100) {
          feature.setProperty("porcent", "green");
        }
      });

      this.dataPolygon.addListener("mouseover", event => {
        var depto = event.feature.getProperty("departamento");

        this.dataPolygon.forEach(function (feature) {
          if (depto == feature.getProperty("departamento")) {
            feature.setProperty("porcent", "black");
          }
        });
        var result = arrayDepartamentosInternet.filter(function (dpto) {
          return dpto.departamento == depto;
        });
        var contentDepto = document.createElement("div");
        contentDepto.style.textAlign = "center";

        if (result[0]) {
          contentDepto.innerText = result[0]["departamento"]
            ? depto + " (click para mas info)"
            : " ";
        }

        this.deptWindow.setContent(contentDepto);

        this.deptWindow.setPosition(event.latLng);
        this.deptWindow.open(this.map);
      });

      this.dataPolygon.addListener("mouseout", event => {
        var depto = event.feature.getProperty("departamento");
        this.dataPolygon.forEach(function (feature) {
          if (depto == feature.getProperty("departamento")) {
            var result = arrayDepartamentosInternet.filter(function (dpto) {
              return dpto.departamento == depto;
            });

            if (result[0]) {
              if (result[0]["porcent"] <= 25) {
                feature.setProperty("porcent", "red");
              } else if (
                result[0]["porcent"] >= 26 &&
                result[0]["porcent"] <= 50
              ) {
                feature.setProperty("porcent", "orange");
              } else if (
                result[0]["porcent"] >= 51 &&
                result[0]["porcent"] <= 75
              ) {
                feature.setProperty("porcent", "yellow");
              } else if (
                result[0]["porcent"] >= 76 &&
                result[0]["porcent"] <= 100
              ) {
                feature.setProperty("porcent", "green");
              }
            } else {
              feature.setProperty("porcent", "gray");
            }
          }
        });

        this.deptWindow.close();
      });

      this.dataPolygon.addListener("click", event => {
        var porcentaje = 0;
        var myHTML = event.feature.getProperty("departamento");

        var result = arrayDepartamentosInternet.filter(function (dpto) {
          return dpto.departamento == myHTML;
        });

        var data = [result[0]["tieneInternet"], result[0]["noTieneInternet"]];

        porcentaje =
          (result[0]["tieneInternet"] * 100) /
          (result[0]["tieneInternet"] + result[0]["noTieneInternet"]);

        var content = document.createElement("div");
        var div = document.createElement("canvas");
        div.id = "myChart";
        var divmain = document.createElement("div");
        divmain.style.textAlign = "center";
        divmain.innerHTML = "<h4>" + myHTML + "</h4>";
        content.appendChild(divmain);
        content.appendChild(div);

        this.deptWindow.setContent(content);

        this.deptWindow.setPosition(event.latLng);
        this.deptWindow.open(this.map);

        setTimeout(() => {
          var canva: any = document.getElementById("myChart");
          var ctx = canva.getContext("2d");
          var myChart = new Chart(ctx, {
            type: "horizontalBar",

            data: {
              labels: ["No Tiene", "Tiene"],

              datasets: [
                {
                  data: [data[1], data[0]],
                  backgroundColor: [
                    "rgba(255, 0, 0, 0.466)",
                    "rgba(0, 128, 0, 0.452)"
                  ],
                  borderColor: ["red", "green"],
                  borderWidth: 2
                }
              ]
            },
            options: {
              title: {
                display: true,
                text:
                  "Tiene: " +
                  porcentaje.toFixed(2) +
                  "%    No tiene: " +
                  (100 - porcentaje).toFixed(2) +
                  "%"
              },
              legend: { display: false },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      max: Math.max(
                        result[0]["tieneInternet"],
                        result[0]["noTieneInternet"]
                      ),
                      min: 0
                    }
                  }
                ]
              }
            }
          });
        }, 100);
      });
    }
  };
}
