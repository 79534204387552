import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentsTalkService {

  //LOADER
  loader: any;
  private loadLoader = new Subject<any>();
  ObservableLoader = this.loadLoader.asObservable()

  sendLoader(loader) {
    this.loader = loader
    this.loadLoader.next(loader)
  }

  //MODAL
  modal: any;
  private loadModal = new Subject<any>();
  ObservableModal = this.loadModal.asObservable()

  sendModal(modal) {
    this.modal = modal
    this.loadModal.next(modal)
  }

  //on MARKER click get BARRA-INFO
  markerbarrainfo: any;
  private loadMarkerBarraInfo = new Subject<any>();
  ObservableMarkerBarraInfo = this.loadMarkerBarraInfo.asObservable();

  sendMarkerBarraInfo(marker) {
    this.markerbarrainfo = marker;
    this.loadMarkerBarraInfo.next(marker);
  }

  //MAP
  map: any;
  private loadMapa = new Subject<any>();
  ObservableMapa = this.loadMapa.asObservable()

  sendMapa(mapa) {
    this.map = mapa
    this.loadMapa.next(mapa)
  }
  //arrayLocationFiltered
  arrayLocationFilter: any;
  private loadArrayLocationFilter = new Subject<[]>();
  ObservableArrayLocationFilter = this.loadArrayLocationFilter.asObservable();

  sendArrayLocationFilter(array) {
    this.arrayLocationFilter = array;
    this.loadArrayLocationFilter.next(array)
  }

  //arrayLocalizacionFiltered
  arrayLocalizacionFiltered: any;
  private loadArrayLocalizacionFiltered = new Subject<[]>();
  ObservableArrayLocalizacionFiltered = this.loadArrayLocalizacionFiltered.asObservable()

  sendArrayLocalizacionFiltered(array) {
    this.arrayLocalizacionFiltered = array
    this.loadArrayLocalizacionFiltered.next(array)
  }

  //arrayMarkers
  arrayMarkers: any;
  private loadArrayMarker = new Subject<[]>();
  ObservableArrayMarker = this.loadArrayMarker.asObservable();

  sendArrayMarkers(array) {
    this.arrayMarkers = array;
    this.loadArrayMarker.next(array)
  }

  //communication between area-component and marcadores-component for latLng of the drawn area
  latLng: any;
  private loadLatLng = new Subject<[]>();
  ObservableLatLng = this.loadLatLng.asObservable();

  sendLatLng(latLng) {
    this.latLng = latLng;
    this.loadLatLng.next(latLng)
  }

  //communication between localizacion-component and marcadores-component for latLng of the selected marker
  latLngCenterCircle: any;
  private loadLatLngCenterCircle = new Subject<[]>();
  ObservableLatLngCenterCircle = this.loadLatLngCenterCircle.asObservable();

  sendLatLngCenterCircle(latLngCenterCircle) {
    this.latLngCenterCircle = latLngCenterCircle;
    this.loadLatLngCenterCircle.next(latLngCenterCircle)
  }

  //communication between localizacion-component and marcadores-component for center and radius circle drawing of the selected marker
  centerRadiusCircle: any;
  private loadCenterRadiusCircle = new Subject<[]>();
  ObservableCenterRadiusCircle = this.loadCenterRadiusCircle.asObservable();

  sendCenterRadiusCircle(centerRadiusCircle) {
    this.centerRadiusCircle = centerRadiusCircle;
    this.loadCenterRadiusCircle.next(centerRadiusCircle)
  }

  //communication between barra-superior component and filtros component by ver mapa de conectividad y ver mapa de proveedores 
  connectProvidersInternet: any;
  private loadConnectProvidersInternet = new Subject<[]>();
  ObservableConnectProvidersInternet = this.loadConnectProvidersInternet.asObservable();

  sendConnectProvidersInternet(connectProvidersInternet) {
    this.connectProvidersInternet = connectProvidersInternet;
    this.loadConnectProvidersInternet.next(connectProvidersInternet)
  }

  //communication between barra-superior component and filtros component to deselect filters when searching by name or cue
  notifyBtnSearch: any;
  private loadNotifyBtnSearch = new Subject<[]>();
  ObservableNotifyBtnSearch = this.loadNotifyBtnSearch.asObservable();

  sendNotifyBtnSearch(notifyBtnSearch) {
    this.notifyBtnSearch = notifyBtnSearch;
    this.loadNotifyBtnSearch.next(notifyBtnSearch)
  }

  //communication between barra-superior component and filtros component to deselect filters when searching by name or cue
  enrollmentLessThan: any;
  distance: any;
  matricula: any;
  private loadEnrollmentLessThan: any = new Subject<[]>();
  ObservableEnrollmentLessThan = this.loadEnrollmentLessThan.asObservable();

  sendEnrollmentLessThan(enrollmentLessThan: any, matricula:any, distance: any) {
    this.enrollmentLessThan = enrollmentLessThan;
    this.distance = distance;
    this.matricula = matricula;
    this.loadEnrollmentLessThan.next([enrollmentLessThan, matricula, distance])
  }

  //communication between barra-superior component and filtros component to deselect filters when searching by name or cue
  zoomMap: any;
  private loadZoomMap = new Subject<[]>();
  ObservableZoomMap = this.loadZoomMap.asObservable();

  sendZoomMap(zoomMap) {
    this.zoomMap = zoomMap;
    this.loadZoomMap.next(zoomMap)
  }

  // between barra-superior component and filtros component to deselect filters when searching by name or cue
  escuelaId: any;
  private loadEscuelaIdMenor5: any = new Subject<[]>();
  ObservableEscuelaIdMenor5 = this.loadEscuelaIdMenor5.asObservable();

  sendEscuelaIdMenor5(escuelaId: any) {
    this.escuelaId = escuelaId;
    this.loadEscuelaIdMenor5.next(escuelaId)
  }

  
  markerSelected: any;
  private loadMarkerSelected = new Subject<[]>();
  ObservableMarkerSelected = this.loadMarkerSelected.asObservable();

  sendMarkerSelected(markerSelected) {
    this.markerSelected = markerSelected;
    this.loadMarkerSelected.next(markerSelected)
  }

  RaGe: any;
  private loadRaGe = new Subject<[]>();
  ObservableRaGe= this.loadRaGe.asObservable();

  sendLoadRaGe(RaGe) {
    this.RaGe = RaGe;
    this.loadRaGe.next(RaGe)
  }


  Referencias: any;
  private loadReferencias = new Subject<any>();
  ObservableReferencias = this.loadReferencias.asObservable()

  sendReferencias(Referencias) {
    //Comun, Conectividad, Proveedores 
    this.Referencias = Referencias
    this.loadReferencias.next(Referencias)
  }



}
