import { Component, OnInit } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { RequestsApiService } from 'src/app/services/requestsApi.service';

declare var $:any;

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  //attributes
  map:google.maps.Map;
  drawingManager:any;
  area: any;


  //COLLAPSED
  collapsed = false

  constructor(private talkService: ComponentsTalkService, private requestsApiService: RequestsApiService) {
    setTimeout(() => {
      this.map = this.talkService.map;  
    }, 1000); 

   }//END constructor()---------------------------------------------------------------------------------------------------
 

   collapse(){
    this.collapsed = !this.collapsed
    if(this.collapsed){
      $("#sidebar-poli").removeClass("col-md-3")
      $("#sidebar-poli").addClass("collapsed col-md-1")
    }else{
      $("#sidebar-poli").addClass("col-md-3")
      $("#sidebar-poli").removeClass("collapsed")
    }
  }

  ngOnInit() {
    //Create the drawing tool managenment objects
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        //Position of the drawing kit tool
        position: google.maps.ControlPosition.LEFT_BOTTOM,
        //Load drawing tools to use
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
     // markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'}, 
      polygonOptions: {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#000000',
        fillOpacity: 0.35,
        zIndex: 1
      },
    });

  }//END ngOnInit()----------------------------------------------------------------------------------------------------


  //method to draw polygon on the map
  addPolygon = () => {  
    //add the drawing manager on the map  
    this.drawingManager.setMap(this.map);

    // Add a listener for the complete polygon event
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.addLatLngPolygon)

    //set the with options polygon
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)

  }//END addPolygon()---------------------------------------------------------------------------------------------------


  //Control the click events on a map and get the latitudes and longitudes of the click.And calculate the area
  addLatLngPolygon = (event) => {
    //sends the latitudes and longitudes of the drawn polygon to the markers component
    this.talkService.sendLatLng(event.overlay.latLngs.g[0].g)

    //delete drawing manager
    this.drawingManager.setMap(null);
  
    this.showLocationsByArea();
 
  }//END addLatLngPolygon()-----------------------------------------------------------------------------------------------

  
  //execute different tasks once the polygon drawing is finished
  showLocationsByArea = () => {
    //request to the api the all locations and send them to the markers comonent to draw marker to according to the area drawn
    this.requestsApiService.getLocalizacion().subscribe(locations => {
      this.talkService.sendArrayLocalizacionFiltered(locations);
    });

    //show the fiters component
    $("#filtrosComponent").css("display", "block");
    //hide fiters by departaments
    $("#btnDep").css("display", "none");
    //hide filters by locations
    $("#btnLoc").css("display", "none");
    $("#sidebar-filters").css("maxWidth", "100%");

  }//END showEstablishments()----------------------------------------------------------------------------------------------

}
