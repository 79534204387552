import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { ComponentsTalkService } from './components-talk.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private componentTalk:ComponentsTalkService){}
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        
        
        
        return next.handle(req);
    }
}