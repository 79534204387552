import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { AuthService } from 'src/app/services/auth.service';
import { CssSelector } from '@angular/compiler';

//variable for jquery
declare var $: any;

@Component({
  selector: 'app-barra-info',
  templateUrl: './barra-info.component.html',
  styleUrls: ['./barra-info.component.scss']
})
export class BarraInfoComponent implements OnInit, OnDestroy {
  showInfo: boolean;
  escuelas: any;
  markers: any;
  conexiones: any;
  infoActive: any
  isLogin
  arrayInfo
  arrayCons

  //COLLAPSED
  collapsed = false
  constructor(private talkService: ComponentsTalkService, private authService: AuthService) { }

  ngOnInit() {
    this.talkService.ObservableArrayLocalizacionFiltered.subscribe(data => {
      this.escuelas = data
      if (data.length != 0) {
        this.showInfo = true
      } else {
        this.showInfo = false
      }
    })

    this.authService.currentUser.subscribe(data => {
      this.isLogin = data
    })

    this.talkService.ObservableArrayLocalizacionFiltered.subscribe(data => {
      this.forCSV(data);
    })

    this.talkService.ObservableArrayMarker.subscribe(
      /*Next*/(markers: any) => {
        if (markers.length != 0) {
          this.showInfo = true
        } else {
          this.showInfo = false
        }
        this.conexiones = markers.conexiones
        return this.markers = markers
      },
      /**Error*/(err) => { console.log("Error", err) },
      /*Completed*/() => { console.log("Complete Marker Observable") })

    this.talkService.ObservableMarkerBarraInfo.subscribe(data => {
      let item = {
        id: data.id,
        lat: data.domicilio.position[1],
        lng: data.domicilio.position[0],
        mark: true,
      }
      this.mostrarInfo(item);
    })
  }

  forCSV = (filtro) => {
    //AUX
    let filteredMarkDataAux = []

    this.markers.forEach(marker => {
      filtro.forEach(filtro => {
        if (marker.id == filtro.id) {
          filteredMarkDataAux.push(filtro);
        }
      });
    });
    this.arrayInfo = this.formatForDownloadinfo(filteredMarkDataAux);
    this.arrayCons = this.formatForDownloadCons(filteredMarkDataAux);
  }

  mostrarInfo(item, evento?) {
    if (item.mark) {
      this.infoActive = item.id
      if (this.talkService.map.zoom < 15) {
        this.talkService.map.setZoom(15);
        this.talkService.map.setCenter(new google.maps.LatLng(item.lat, item.lng - 0.015))
      }
      setTimeout(() => {
        $("#sidebar-info").scrollTop(0)
        $("#sidebar-info").scrollTop($(`#info${item.id}`).first().position().top - 10)
      }, 500);
      return
    }

    if (item.id != this.infoActive) {
      this.infoActive = item.id
      this.talkService.map.setZoom(15);
      this.talkService.map.setCenter(new google.maps.LatLng(item.lat, item.lng - 0.015))
      google.maps.event.trigger(item.marker, 'rightclick')
    } else {
      this.infoActive = null
    }

  }

  formatForDownloadinfo(data) {
    let data1 = data.map(x => {
      let nuevo = Object.assign({}, x, x.establecimiento)
      delete nuevo["establecimiento"]
      for (var index in nuevo) {
        let temp = {}
        if (typeof nuevo[index] == 'object' && !(Array.isArray(nuevo[index]))) {
          if (index == "domicilio") {
            temp["calle"] = nuevo["domicilio"].calle
            temp["codPostal"] = nuevo["domicilio"]["codPostal"]
            temp["latitud"] = nuevo["domicilio"]["position"][1]
            temp["longitud"] = nuevo["domicilio"]["position"][0]
            temp["localidad"] = nuevo["domicilio"]["localidad"]["nombre"]
            temp["departamento"] = nuevo["domicilio"]["localidad"]["departamento"]["nombre"]
            temp["ambito"] = x["ambito"]["descripcion"]
            delete nuevo["domicilio"]
          }
          else {
            if (index == "eje") {
              delete nuevo["eje"]
            } else {
              if (index == "ambito" || index == "sector") {
                temp[index] = nuevo[index]["descripcion"]
              }
              delete nuevo[index]
            }
          }
        }
        if (typeof nuevo[index] == 'object' && (Array.isArray(nuevo[index]))) {
          delete nuevo["ofertas"]
          delete nuevo["conexiones"]
        }
        nuevo = Object.assign(nuevo, temp)
        nuevo.nombre = nuevo.nombre.replace(/"/g, "");
        nuevo.nombre = nuevo.nombre.replace(/;/g, ",");
        delete nuevo["tieneInternet"]
        delete nuevo["fechaActualizacion"]
        delete nuevo["sitioWeb"]
        delete nuevo["email"]
      }
      return nuevo
    })
    return data1
  }

  formatForDownloadCons(data) {
    let data2 = data.reduce((prev, cur) => {
      length = cur.conexiones.length
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          prev.push(
            {
              "id": cur.id,
              "nombre": cur.nombre,
              "latitud": cur.domicilio.position[1],
              "longitud": cur.domicilio.position[0],
              "departamento": cur.domicilio.localidad.departamento.nombre,
              "localidad": cur.domicilio.localidad.nombre,
              "ambito": cur.ambito.descripcion,
              "tieneInternet": cur.tieneInternet == 1 ? "tiene" : "no tiene",
              "estado": cur.conexiones[i].conexionEstado.descripcion,
              "tipo": cur.conexiones[i].conexionTipo.descripcion,
              "proveedor": cur.conexiones[i].proveedor.descripcion,
              "pago": cur.conexiones[i].pago
            }
          )
        }
      } else {
        prev.push(
          {
            "id": cur.id,
            "nombre": cur.nombre,
            "latitud": cur.domicilio.position[1],
            "longitud": cur.domicilio.position[0],
            "departamento": cur.domicilio.localidad.departamento.nombre,
            "localidad": cur.domicilio.localidad.nombre,
            "ambito": cur.ambito.descripcion,
            "tieneInternet": cur.tieneInternet == 1 ? "tiene" : "no tiene",
            "estado": "",
            "tipo": "",
            "proveedor": "",
            "pago": ""
          }
        )
      }

      return prev
    }, [])

    return data2
  }

  ngOnDestroy() {
  }

  collapse() {
    this.collapsed = !this.collapsed
    if (this.collapsed) {
      $("#sidebar-info").removeClass("col-md-3")
      $("#sidebar-info").addClass("collapsed col-md-1")
      $("#mainInfo").css("display", "none")
      $("#infoEsc").css("display", "none")
      $("#sidebar-info").css("maxWidth", "10%", "!important")
    } else {
      $("#sidebar-info").addClass("col-md-3")
      $("#sidebar-info").removeClass("collapsed")
      $("#mainInfo").css("display", "block")
      $("#infoEsc").css("display", "block")
      $("#sidebar-info").css("maxWidth", "30%")
    }
  }

}
