<div class="ref float-left">
    <div *ngIf="referenciasType == 'Comun'" id="refs" class="ref-content ">
        <p> <img src="/assets/images/inicial.svg" style="width:12px;" /> Nivel Inicial</p>
        <p> <img src="/assets/images/primaria.svg" style="width:12px;" /> Nivel Primario</p>
        <p> <img src="/assets/images/secundaria.svg" style="width:12px;" /> Nivel Secundario</p>
        <p> <img src="/assets/images/superior.svg" style="width:12px;" /> Nivel Superior</p>
        <p> <img src="/assets/images/varios.svg" style="width:12px;" /> Varios Niveles</p>
        <p> <img src="/assets/images/servcomp.svg" style="width:12px;" /> Servicios Complementarios</p>
        <p> <img src="/assets/images/singeo.svg" style="width:12px;" /> Sin Geolocalización</p>
    </div>
    <div *ngIf="referenciasType == 'Conectividad'" id="refs" class="ref-content ">
        <p><img src="/assets/images/ConexionTiene.png" style="width:12px;" /> Tiene</p>
        <p><img src="/assets/images/ConexionNoTiene.png" style="width:12px;" /> No Tiene</p>
        <p><img src="/assets/images/ConexionAConectar.png" style="width:12px;" /> A Conectar</p>

    </div>
    <div *ngIf="referenciasType == 'Proveedores'" id="refs" class="ref-content ">
        <p><img src="/assets/images/providerNoTiene.png" style="width:12px;" /> No Tiene</p>
        <p><img src="/assets/images/providerArsat.png" style="width:12px;" /> Arsat</p>
        <p><img src="/assets/images/providerCodexSA.png" style="width:12px;" /> Codex SA</p>
        <p><img src="/assets/images/providerGigared.png" style="width:12px;" /> Gigared</p>
        <p><img src="/assets/images/providerNoRegistrados.png" style="width:12px;" /> No Registrados</p>
        <p><img src="/assets/images/providerOtros.png" style="width:12px;" /> Otros</p>
        <p><img src="/assets/images/providerRadioClub.png" style="width:12px;" /> Radio Club</p>
        <p><img src="/assets/images/providerCIBERNEK.png" style="width:12px;" /> CIBERNEK S.R.L</p>
        <p><img src="/assets/images/providerRentasProvincia.png" style="width:12px;" /> RentasProvincia</p>
    </div>
</div>