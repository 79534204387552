<!-- Informacion -->
<div style="position: relative;display: flex;">
    <div *ngIf="showInfo" id="sidebar-info" class="barras col-md-3 scrollbar">


        <div class="row  d-flex flex-row align-items-start bar-title-wrap" style="min-width: 100%;" id="infoHeader">
            <h3 class="bar-title col-11">Información</h3>
            <button id="colapsedBtn" type="button" class="navbar-toggler col-1 sidebarFilter" data-toggle="collapse"
                data-target=".navbar-collapse" (click)="collapse()">
                <i class="material-icons" style="cursor: pointer">view_headline</i>
            </button>
        </div>


        <div class="infoesc row" id="infoEsc">
            <span>Total:{{markers.length}}<b class="totalesc"></b> </span>
            <span id="downloadBtn">
                <!-- is login -->
                <app-json-to-csv *ngIf="isLogin" [JSON]="arrayInfo" Report="escuelas" ShowLabel="true"
                    Title="establecimiento" aName="cloud_download
                "> </app-json-to-csv>
                <app-json-to-csv *ngIf="isLogin" [JSON]="arrayCons" Report="conexiones" ShowLabel="true"
                    Title="conexiones" aName="cloud_download
                "> </app-json-to-csv>
            </span>

        </div>

        <div id="mainInfo" class="row">
            <ul class="row" class="sidebar-nav" *ngFor="let item of markers">
                <div #info id="info{{item.id}}" id="infoesc" class="row col-md-12 infodesc"
                    (click)="mostrarInfo(item,info)">
                    <div class="row headerinfo">
                        <div class="titulo">
                            <div class="">
                                <span style="float: left;margin: 0 10px 0 0;"><img src="{{item.imgToBarraInfo}}"></span>
                                <h3 class="firstHeading" id="firstHeading">{{item.marker.title}}</h3>
                                <span class="cue-custom">CUE: {{item.cue}}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Informacion Extra -->
                    <div id="infoExtra{{item.id}}"
                        [ngClass]="item.id==infoActive?'sideInfo noprop active showInfo':'notShowInfo'">
                        <h4>Información General</h4>
                        <p><b>Sector</b>: {{item.sector}} </p>
                        <p><b>Ambito</b>: {{item.ambito}} </p>
                        <p><b>Ofertas</b>: {{item.ofertas}} </p>
                        <hr>
                        <h4>Datos de Contacto</h4>
                        <p><b>Domicilio</b>: {{item.domicilios}} </p>
                        <p><b>Localidad</b>: {{item.localidad}} </p>
                        <p><b>Departamento</b>: {{item.dpto}} </p>
                        <p><b>Internet</b>: {{item.internet}} </p>{{item.efInfo}}
                        <p><b>Matricula Total</b>: {{item.matricula}} </p>
                        <p *ngIf="item.internet != 'No tiene'"><b>Proveedores</b>:
                            <span *ngIf="item.conexiones[0]">{{item.conexiones[0]?.proveedor.descripcion}}</span>
                            <span *ngIf="item.conexiones[1]">, {{item.conexiones[1]?.proveedor.descripcion}}</span>
                            <span *ngIf="item.conexiones[2]">, {{item.conexiones[2]?.proveedor.descripcion}}</span>
                            <span *ngIf="item.conexiones[3]">, {{item.conexiones[3]?.proveedor.descripcion}}</span>
                            <span *ngIf="item.conexiones[4]">, {{item.conexiones[4]?.proveedor.descripcion}}</span>
                            <span *ngIf="item.conexiones[5]">, {{item.conexiones[5]?.proveedor.descripcion}}</span>
                        </p>
                    </div>

                </div>
            </ul>
        </div>

    </div>
    <app-referencias></app-referencias>
</div>

<!-- FIN Informacion -->