<!-- Sidebar -->
<div id="sidebar-planes" class="barras sidebar-filters dark col-md-3 scrollbar active float-left">

    <div class="row bar-title-wrap d-flex flex-row align-items-start justify-content-between">

        <h3 class="bar-title col-10">Planes</h3>
        <button type="button" class="navbar-toggler col-2" data-toggle="collapse" data-target=".navbar-collapse"
            (click)="collapse()">
            <i class="material-icons" style="cursor: pointer;">view_headline</i>
        </button>

    </div>
    <ul class="sidebar-nav">


        <!-- Escuela del Futuro -->
        <div>
            <li style="display: grid">

                <div #escuelaDelFuturoPadre id='escFuturoPadre' class="btn btn-sidebar"
                    (click)="showChildFilters(escuelaDelFuturoPadre)">
                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxEscuelaFuturo value='' type="checkbox" id='escFuturo'
                                class="chkbox masterbox" (click)="onCheckBoxFather(checkBoxEscuelaFuturo)" />
                            <label for='escFuturo'></label>
                        </div>
                        <p>Escuela del Futuro</p>

                    </div>
                </div>
            </li>

            <div #escuelaDelFuturoHijoCategorias id="escFuturoHijoCat" class="escFut toToggle checkbox changes"
                data-nombre="categorias=" style='display: none;'>
                <p>Categorias</p>
                <div id="efCatbox" class=" efbox checkboxes">
                    <div class="item" id="escCat" *ngFor="let item of escuelaFuturoCategoriaArray">
                        <div class="chkbox">
                            <input #checkBoxCategoria type="checkbox" id="{{item.id}}" value='{{item.nombre}}'
                                (click)="onCheckBoxChildren()" />
                            <label for="{{item.id}}"></label>
                        </div>
                        <p>{{item.nombre}}</p>
                    </div>
                </div>



            </div>
            <div #escuelaDelFuturoHijoEjes id="escFuturoHijoEje" class="escFut toToggle checkbox changes"
                data-nombre="ejes=" style='display:none;'>

                <p>Ejes</p>
                <div id="efEjebox" class=" efbox checkboxes">
                    <div class="item" *ngFor="let item of escuelaFuturoEjeArray">
                        <div class="chkbox">
                            <input #checkBoxEje type="checkbox" id="{{item.id}}" value='{{item.nombre}}'
                                (click)="onCheckBoxChildren()" />
                            <label for="{{item.id}}"></label>
                        </div>
                        <p>{{item.nombre}}</p>
                    </div>
                </div>

            </div>
        </div>


        <!-- Conectar igualdad -->
        <div>
            <li style="display: grid">

                <div #escuelaConectarIgualdadPadre id='escConectarIgualdad'
                    class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(escuelaConectarIgualdadPadre)">
                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxConectIgualdad value='' type="checkbox" id='Conig' class="chkbox masterbox"
                                (click)="onCheckBoxFather(checkBoxConectIgualdad)" />
                            <label for='Conig'></label>
                        </div>
                        <p>Conectar igualdad</p>

                    </div>
                </div>
            </li>

            <div #conectarIgualdadItems id="conectarIgualdadItems" class="conIguldad toToggle checkbox changes"
                data-nombre="items=" style='display: none;'>
                <p>Items</p>
                <div id="pitembox" class="checkboxes">
                    <div class="item" id='ConectIgualdadItems' *ngFor="let item of escuelaFuturoConectarIgualdadItem">
                        <div class="chkbox">
                            <input #checkBoxItems type="checkbox" id="{{item.nombre}}" value='{{item.nombre}}'
                                (click)="onCheckBoxChildren()" />
                            <label for="{{item.nombre}}"></label>
                        </div>
                        <p>{{item.nombre}}</p>
                    </div>
                </div>


            </div>
            <div #conectarIgualdadAnios id="conectarIgualdadAnios" class="conIguldad toToggle checkbox changes"
                data-nombre="años=" style='display: none;'>

                <p>Años</p>
                <div id="paniobox" class="checkboxes">
                    <div class="item" id='ConectIgualdadAnios' *ngFor="let item of yearArray">
                        <div #checkBoxAnios class="chkbox" (click)="onCheckBoxChildren()">
                            <input type="checkbox" id="{{item.nombre}}" value='{{item.nombre}}' />
                            <label for="{{item.nombre}}"></label>
                        </div>
                        <p>{{item.nombre}}</p>
                    </div>
                </div>

            </div>
            <div #conectarIgualdadTipoDeEntrega id="conectarIgualdadTipoDeEntrega"
                class="conIguldad toToggle checkbox changes" data-nombre="entregas=" style='display: none;'>

                <p>Tipo de Entrega</p>
                <div id="pEntregabox" class="checkboxes">
                    <div class="item" id='ConectIgualdadTipoDeEntrega'
                        *ngFor="let item of escuelaFuturoConectarIgualdadEntrega">
                        <div class="chkbox">
                            <input #checkBoxTipoEntrega type="checkbox" id="{{item.nombre}}" value='{{item.nombre}}'
                                (click)="onCheckBoxChildren()" />
                            <label for="{{item.nombre}}"></label>
                        </div>
                        <p>{{item.nombre}}</p>
                    </div>
                </div>

            </div>
        </div>

        <!-- Faro -->
        <div class="row buttons">
            <button id="btnFaro" class="btn btn-main green col-xl-6" (click)='faroFilter()'>
                <div class="btn-icon"><span class="fas fa-list-ol"></span></div>
                <p class="btn-content m-0 p-2">Escuelas Faro</p>
            </button>
        </div>

        <!-- Mitai -->
        <div class="row buttons">
            <button id="btnMitai" class="btn btn-main green col-xl-6" (click)='mitaiFilter()'>
                <div class="btn-icon"><span class="fas fa-list-ol"></span></div>
                <p class="btn-content m-0 p-2">Mitai Digital</p>
            </button>
        </div>

        <!-- Varkey -->
        <div>
            <li style="display: grid">

                <div #varkeyPadre id='varkeyPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(varkeyPadre)">
                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxVarkey value='' type="checkbox" id='varkey' class="chkbox masterbox"
                                (click)='onCheckBoxFather(checkBoxVarkey)' />
                            <label for='varkey'></label>
                        </div>
                        <p>Varkey</p>

                    </div>
                </div>
            </li>

            <div #varkeyCategorias id="varkeyCat" class="escFut toToggle checkbox changevarkey"
                data-nombre="categorias=" style='display: none;'>
                <p>Categorias</p>
                <div id="vkCatbox" class=" vkbox checkboxes">
                    <div class="item" id='vakeyCatItems' *ngFor="let item of varkeyArray">
                        <div #checkBoxVarkeyCat class="chkbox" (click)="onCheckBoxChildren()">
                            <input type="checkbox" id="{{item}}" value='{{item}}' />
                            <label for="{{item}}"></label>
                        </div>
                        <p>{{item}}</p>
                    </div>
                </div>


            </div>

        </div>

        <!-- Obras -->
        <div>
            <li style="display: grid">

                <div #obrasPadre id='obrasPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(obrasPadre)">
                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxObras value='' type="checkbox" id='obras' class="chkbox masterbox"
                                (click)='onCheckBoxFather(checkBoxObras)' />
                            <label for='obras'></label>
                        </div>
                        <p>Obras</p>

                    </div>
                </div>
            </li>

            <div #obrasHijo id="obrasHijo" class="escFut toToggle checkbox changevarkey" data-nombre="obras="
                style='display: none;'>
                <div id="vkCatbox" class=" vkbox checkboxes">
                    <div class="item" id='obrasHijoItems' *ngFor="let item of obrasEstado">
                        <div #checkBoxObrasHijo class="chkbox" (click)="onCheckBoxChildren()">
                            <input type="checkbox" id="{{item.etapa}}" value='{{item.etapa}}' />
                            <label for="{{item.etapa}}"></label>
                        </div>
                        <p>{{item.etapa}}</p>
                    </div>
                </div>


            </div>

        </div>

        <!-- terminan los filtros
        <div class="ref">
            <hr />
            <h4>Referencias
                <span class="glyphicon glyphicon-info-sign"></span>
            </h4>

            <div id="refs" class="ref-content ">
                <p>
                    <img src="/assets/images/inicial.svg" style="width:12px;" /> Nivel Inicial</p>
                <p>
                    <img src="/assets/images/primaria.svg" style="width:12px;" /> Nivel Primario</p>
                <p>
                    <img src="/assets/images/secundaria.svg" style="width:12px;" /> Nivel Secundario</p>
                <p>
                    <img src="/assets/images/superior.svg" style="width:12px;" /> Nivel Superior</p>
                <p>
                    <img src="/assets/images/varios.svg" style="width:12px;" /> Varios Niveles</p>
                <p>
                    <img src="/assets/images/servcomp.svg" style="width:12px;" /> Servicios Complementarios</p>
                <p>
                    <img src="/assets/images/singeo.svg" style="width:12px;" /> Sin Geolocalización</p>

            </div>
        </div> -->
    </ul>

</div>






<!-- FIN FIltros -->