<header id="header" class="row">
    <div class="col-md-2 desktop">
        <img class="logo-ctes" src="../../../assets/images/LogoCtes.svg">
    </div>
    <div class="col-md-2 desktop">
        <img class="logo-mapa" src="../../../assets/images/LogoMapa.svg">
    </div>
    <img class="logo-mapa mobile" src="../../../assets/images/LogoMapa.svg">
  
        <div class="m-2" id="btnResponsive" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
            <a  type="button" class="btn btn-success" href="http://mapa.mec.gob.ar:8084/" target="_blank">Anexo Mapa</a>
        </div>
 
    <!-- BUSQUEDA -->
    <div class="search col-md-6" id="search">
        <div class="input-group" >
            <div class="input-group-btn search-panel" (click)="showHideSeeker(dropdownMenu.id)">
                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <span id="search_concept">BUSCAR SEGÚN</span> <span class="caret"></span>
                </button>
                <ul #dropdownMenu class="dropdown-menu" role="menu" id="dropdownMenu">
                    <li><a id="nom" href="#" (click)="showHideSeeker(txtBusquedanomb.id)">NOMBRE</a></li>             
                    <!-- <li><a id="cue" href="#">CUE</a></li> -->
                    <li><a id="cueanexo" href="#" (click)="showHideSeeker(txtBusquedacue.id)">CUE-ANEXO</a></li>
                </ul>
            </div>
            <input type="hidden" name="search_param" value="all" id="search_param">
            <input #txtBusqueda type="text" id="txtBusqueda" class="form-control" name="x" placeholder="Seleccione el filtro" disabled>         
            <input  #txtBusquedanomb type="text" id="txtBusquedanomb" class="form-control" name="x" placeholder="Ingrese una busqueda" style="display: none;" value="" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredNameSchools | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete> 
            <input #txtBusquedacue type="text" id="txtBusquedacue" class="form-control" name="x" placeholder="Ingrese uno o varios CUE separados por coma" style="display: none" value="" >      
            <span class="input-group-btn">
                <button class="btn btn-default" (click)="btnSearch()" id="btnSearch" type="button" target="_blank"><span class="fas fa-search"><i class="material-icons">search</i></span></button>           
            </span>
        </div>
    </div>
    <!-- FIN BUSQUEDA -->
    
    
    <div class="col-2" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
      <div class="m-2" id="btnDesktop">
          <a  type="button" class="btn btn-success" href="http://mapa.mec.gob.ar:8084/" target="_blank">Anexo Mapa</a>
      </div>
    </div>      
</header>
