<div id="sidebar-poli" class="barras sidebar-filters dark col-sm-3 " >


        <div id="tittleArea" class="row bar-title-wrap d-flex flex-row align-items-start">
            <h3  class="bar-title col-11">Áreas</h3>
            <button type="button" class="navbar-toggler col-1 sidebarFilter" data-toggle="collapse" data-target=".navbar-collapse" (click)="collapse()">
                <i class="material-icons" style="cursor: pointer;color: #ccc;">view_headline</i>
            </button>
    
        </div>
        <div id="barracoord" class="row">
    
            <div id="infopolygon">
    
                <div class="bloque">
                    <h4>
                        <i class="fas fa-calculator"></i> Calcular Área</h4>
                    <p>Muestra los establecimientos ubicados en el área comprendida entre varios puntos.</p>
                    <hr>
                    <div class="row buttons">
            
                            <button id="" data-bool="true" class="btnaddArea btn btn-main green col-sm" (click)="addPolygon()">
                                <div class="btn-icon">
                                    <span class="fas fa-pencil-alt"></span>
                                </div>
                                <p class="btn-content m-0 p-2">
                                    Dibujar área
                                </p>
                            </button>
                                         
                    </div>
                    <br>
                    <!-- <div class="row">
                        Área seleccionada:
                        <b>
                            <b class="form-control-static area" id="">0</b> m&#178</b>
                        <br />
                    </div> -->
                    
                    <!-- <div class="row">
                        <b>Coordenadas</b>
                        <textarea class="form-control" rows="3" id="coords"></textarea>
                    </div>
                    <br /> -->
    
                </div>
                <!-- filtros -->
                <div class="filterarea" style="display:none">
                    <div class="movingfilters">
    
                    </div>
                </div>
    
    
            </div>
            
        </div>
        <app-filtros  id="filtrosComponent"></app-filtros>
    </div>
    
