import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import cabecerasPrimarias from "../data/cabecerasPrimarias.json";



@Injectable({
  providedIn: 'root'
})
export class RequestsApiService {
  //Urls of the apis
  readonly urlServerLocalizacion = 'http://mapa.mec.gob.ar:8082/api/localizacion';
  readonly urlServerLocalidad = 'http://mapa.mec.gob.ar:8082/api/localidad';
  readonly urlServerDpto = 'http://mapa.mec.gob.ar:8082/api/departamento';
  readonly urlServerModality = 'http://mapa.mec.gob.ar:8082/api/modalidad';
  readonly urlServerSector = 'http://mapa.mec.gob.ar:8082/api/sector';
  readonly urlServerAmbit = 'http://mapa.mec.gob.ar:8082/api/ambito';
  readonly urlServerIndexSchool = 'http://mapa.mec.gob.ar:8082/api/localizacion/index';
  readonly urlServerLevelInitial = 'http://mapa.mec.gob.ar:8082/api/localizacion?ofertas=152,155,121,122,100,101';
  readonly urlServerLevelPrimary = 'http://mapa.mec.gob.ar:8082/api/localizacion?ofertas=136,123,126,140,102,105,153,156,158';
  readonly urlServerLevelSecondary = 'http://mapa.mec.gob.ar:8082/api/localizacion?ofertas=143,144,108,109,110,111,129,130,147,148,151,154,157,159,131,132,149,150,138';
  readonly urlServerLevelHigher = 'http://mapa.mec.gob.ar:8082/api/localizacion?ofertas=117,115';
  readonly urlServerLogin = 'http://mapa.mec.gob.ar:8082/login'
  readonly urlServerProviders = 'http://mapa.mec.gob.ar:8082/api/proveedor';
  readonly urlServerVarkey = 'http://mapa.mec.gob.ar:8082/api/varkey';
  readonly urlServerEscuelaFuturo = 'http://mapa.mec.gob.ar:8082/api/escuelaFuturo';
  readonly urlServerEscuelaFuturoCategoria = 'http://mapa.mec.gob.ar:8082/api/escuelaFuturo/categoria';
  readonly urlServerEscuelaFuturoEje = 'http://mapa.mec.gob.ar:8082/api/escuelaFuturo/eje';
  readonly urlServerEscuelaConectarIgualdad = 'http://mapa.mec.gob.ar:8082/api/conectarIgualdad'
  readonly urlServerEscuelaConectarIgualdadItem = 'http://mapa.mec.gob.ar:8082/api/conectarIgualdad/tipoItem';
  readonly urlServerEscuelaConectarIgualdadTipoEntrega = 'http://mapa.mec.gob.ar:8082/api/conectarIgualdad/tipoEntrega';
  readonly urlServerAverageDistance = 'http://mapa.mec.gob.ar:8082/api/localizacion/';
  readonly urlServerMitai = 'http://mapa.mec.gob.ar:8082/api/mitai'
  readonly urlServerFaro = 'http://mapa.mec.gob.ar:8082/api/faro'
  readonly urlServerDependencia = "http://mapa.mec.gob.ar:8082/api/dependencia"
  readonly urlServerEstado = "http://mapa.mec.gob.ar:8082/api/estado"
  readonly urlServerJurisdicciones = "http://mapa.mec.gob.ar:8082/api/jurisdicciones"
  readonly urlServerOrganismoDependencia = "http://mapa.mec.gob.ar:8082/api/organismodependencia"
  readonly urlRelevamientoAnual = "http://mapa.mec.gob.ar:8082/api/relevamientosanuales"
  readonly urlGestionEducativa = "http://mapa.mec.gob.ar:8082/api/gestioneseducativas"
  readonly urlIndicadores = "http://mapa.mec.gob.ar:8082/api/indicadores"
  readonly urlAguaPotable = "http://mapa.mec.gob.ar:8082/api/aguapotable"

  constructor(private http: HttpClient) {

  }


  //Request Http and return all locations in json format
  getLocalizacion() {
    return this.http.get(this.urlServerLocalizacion);
  }

  //Request Http and return all locations in json format
  getLocalidad() {
    return this.http.get(this.urlServerLocalidad);
  }

  //Request Http and return all Departaments in json format
  getDptos() {
    return this.http.get(this.urlServerDpto);
  }

  //Request Http and return all modalities in json format
  getModality() {
    return this.http.get(this.urlServerModality);
  }

  //Request Http and return all sector in json format
  getSector() {
    return this.http.get(this.urlServerSector);
  }

  //Request Http and return all ambit in json format
  getAmbit() {
    return this.http.get(this.urlServerAmbit);
  }

  //Request Http and return all index of school in json format
  getIndexSchool() {
    return this.http.get(this.urlServerIndexSchool);
  }

  //Request Http and return all school of level initial in json format
  getLevelInitial() {
    return this.http.get(this.urlServerLevelInitial);
  }

  //Request Http and return all school of level primarie in json format
  getLevelPrimary() {
    return this.http.get(this.urlServerLevelPrimary);
  }

  //Request Http and return all school of level secondarie in json format
  getLevelSecondary() {
    return this.http.get(this.urlServerLevelSecondary);
  }

  //Request Http and return all school of level higher in json format
  getLevelHigher() {
    return this.http.get(this.urlServerLevelHigher);
  }

  //Request Http and return all providers of level higher in json format
  getProviders() {
    return this.http.get(this.urlServerProviders);
  }

  postLogin(user, pass) {
    return this.http.post(this.urlServerLogin, { username: user, password: pass })
  }

  getVarkey() {
    return this.http.get(this.urlServerVarkey);
  }

  getEscuelaFuturo() {
    return this.http.get(this.urlServerEscuelaFuturo);
  }

  getEscuelaFuturoCategoria() {
    return this.http.get(this.urlServerEscuelaFuturoCategoria);
  }

  getEscuelaFuturoEje() {
    return this.http.get(this.urlServerEscuelaFuturoEje);
  }

  getEscuelaFuturoConectarIgualdad() {
    return this.http.get(this.urlServerEscuelaConectarIgualdad);
  }

  getEscuelaFuturoConectarIgualdadItem() {
    return this.http.get(this.urlServerEscuelaConectarIgualdadItem);
  }

  getEscuelaFuturoConectarIgualdadEntrega() {
    return this.http.get(this.urlServerEscuelaConectarIgualdadTipoEntrega);
  }

  getEscuelaFuturoMitai() {
    return this.http.get(this.urlServerMitai);
  }

  getEscuelaFuturoFaro() {
    return this.http.get(this.urlServerFaro);
  }

  //Request Http and return average distance of the points
  getAverageDistance(idLocations, distance) {
    return this.http.get(this.urlServerAverageDistance + idLocations + "/" + distance);
  }

  getDependencia() {
    return this.http.get(this.urlServerDependencia)
  }

  getEstado() {
    return this.http.get(this.urlServerEstado)
  }

  getJurisdicciones() {
    return this.http.get(this.urlServerJurisdicciones)
  }

  getOrganismoDependencia() {
    return this.http.get(this.urlServerOrganismoDependencia)
  }

  getRelevamientoAnual() {
    return this.http.get(this.urlRelevamientoAnual)
  }

  getGestionEducativa() {
    return this.http.get(this.urlGestionEducativa)
  }

  getIndicadores() {
    return this.http.get(this.urlIndicadores)
  }

  getAguaPotable() {
    return this.http.get(this.urlAguaPotable)
  }

  getCabecerasPrimaria() {
    return cabecerasPrimarias;
  }

}
