import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { LIVE_ANNOUNCER_PROVIDER } from '@angular/cdk/a11y';
import { AuthService } from 'src/app/services/auth.service';
import { RequestsApiService } from 'src/app/services/requestsApi.service';
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier"

// import { google } from '@agm/core/services/google-maps-types';
//ClusterMarkerer
declare var MarkerClusterer: any;

declare var $: any;
//const fs = require('fs');
declare var require: any;
declare const google: any;

@Component({
  selector: 'app-marcadores',
  templateUrl: './marcadores.component.html',
  styleUrls: ['./marcadores.component.scss'],
  providers: [RequestsApiService]
})
export class MarcadoresComponent implements OnInit {

  // Variables
  markerCluster: any;
  markersToCluster = [];
  markers: any = [];
  debug = 0;
  polygonCorrientes = new google.maps.Polygon({ paths: [{ lat: 25.383901054871824, lng: 16.36982506086167 }, { lat: 5.33182687704563, lng: -114.41142493913833 }, { lat: -63.44060434511506, lng: -104.74345618913833 }, { lat: -38.77107329230272, lng: 25.15888756086167 }] });
  map: any;
  map2: any
  drawingManager: any;
  circleCenter = new google.maps.LatLng(-28.789344058036477, -57.86776562440559);
  circleRadius = 50000000;
  arrayLocations: any;
  circleEnrollment: any;
  circleRav: any;
  graphsEnrollments: any;
  escuelaMenorAcinco: any;
  polylineEnrollments: any = [];
  schoolsGraphsEnrollments: any = [];
  currentUser;
  circle: any
  icon = ""
  infoWindow = new google.maps.InfoWindow();
  flagInternet = 0;
  flagProvider = 0;
  enrollments: any
  markerRaGe: any;
  escuelaRaGe: any;
  relevamientoAnual: any;
  gestionEducativa: any;



  constructor(private talkService: ComponentsTalkService, private elementRef: ElementRef, private renderer: Renderer, private authService: AuthService, private requestApiService: RequestsApiService) {
    Window["myComponent"] = this;

  }


  ngOnInit() {
    this.talkService.ObservableMapa.subscribe(map => {
      this.map = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true
      });
      this.map2 = map
    });


    this.authService.currentUser.subscribe(data => {
      this.currentUser = data;
    });

    //LISTENING ARRAY LOCALIZACION FILTERED
    this.talkService.ObservableArrayLocalizacionFiltered.subscribe((data: any) => {
      //delete circle
      this.circuloEliminar();

      // delete polylines
      this.polylineEliminar();

      this.arrayLocations = data;
      this.crearMarcadores(data/*, this.scaledSizeWidth, this.scaledSizeHeigth*/);
    })

    this.talkService.ObservableLatLng.subscribe(latLngPolygon => {
      this.polygonCorrientes = new google.maps.Polygon({ paths: latLngPolygon });
    });

    this.talkService.ObservableCenterRadiusCircle.subscribe(centerRadiusCircle => {
      for (var i = 0; i < centerRadiusCircle.length - 1; i++) {
        this.circleCenter = centerRadiusCircle[i];
        this.circleRadius = centerRadiusCircle[i + 1];
      }
    });

    this.talkService.ObservableEnrollmentLessThan.subscribe(data => {
      // delete polylines
      this.polylineEliminar();
      this.graphsSchools(data[0], data[1], data[2]);
    });

    this.talkService.ObservableRaGe.subscribe(data => {
      var contentString =
        '<div id="piechart" style="margin:0;display: inline; width: 100%;"></div>';
      this.infoWindow.setContent(contentString);
      this.drawChart();
    });

    this.requestApiService.getRelevamientoAnual().subscribe(data => {
      this.relevamientoAnual = data;
      this.enrollments = data
    })

    this.requestApiService.getGestionEducativa().subscribe(data => {
      this.gestionEducativa = data;
    })


  }//END ngOnInit()-------------------------------------------------------------------------------------------------------------------------------------------------------------


  ngOnDestroy() {
    // delete polylines
    this.polylineEliminar();
    this.eliminarMarcadores()
  }//END ngOnDestroy()-----------------------------------------------------------------------------------------------------------------------------------------------------------



  crearMarcadores = (data) => {
    this.eliminarMarcadores()

    for (var i in data) {
      var myLatLng = new google.maps.LatLng(data[i].domicilio.position[1], data[i].domicilio.position[0]);

      //check if the latitude and longitude of the locations is within the polygon (when an area is drawn in the area component), 
      //but compare if it is  within the polygon this.polygonCorrientes
      if (google.maps.geometry.poly.containsLocation(myLatLng, this.polygonCorrientes) && google.maps.geometry.spherical.computeDistanceBetween(this.circleCenter, myLatLng) <= this.circleRadius) {



        var marker = new google.maps.Marker({
          position: myLatLng,
          icon: "",
          label: "",
          title: data[i].nombre,
        });

        var mapHasIdled = false;
        google.maps.event.addListener(this.map, 'idle', function () { mapHasIdled = true; });

        ((marker, data) => {
          this.spiderClickMarcadores(marker, data)
          this.dobleClickMarcadores(marker, data)
          this.clickDerechoMarcadores(marker, data)
        })(marker, data[i]);

        if (this.tiposIconosAsignar(data, i)[1] == 1) {
          this.setIconMarkerProvider(marker);
        } else {
          this.setIconMarker(marker);
        }

        this.markers.push(this.infoMarkerCrear(data[i], marker, this.tiposIconosAsignar(data, i)[0]));
        this.markersToCluster.push(marker);
        this.map.addMarker(marker, (e) => { console.log("SPIDER", e) })
      }
    }

    this.flagInternet = 0;
    this.flagProvider = 0;



    this.talkService.sendArrayMarkers(this.markers);
    this.talkService.sendLoader(false);
  }//END crearMarcadores()-------------------------------------------------------------------------------------------------------------------------------------------------


  eliminarMarcadores = (markers?: any) => {

    if (!markers) {
      this.markers.forEach(data => {
        data.marker.setMap(null)
      });
    } else {
      markers.forEach(data => {
        data.marker.setMap(null)
      });
    }

    this.markers = [];
    this.markersToCluster = [];
  }//END eliminarMarcadores()-------------------------------------------------------------------------------------------------------------------------------------------------


  spiderClickMarcadores = (marker, data) => {
    google.maps.event.addListener(marker, 'spider_click', (e) => {
      //if (! mapHasIdled) return;
      this.icon = this.asignarIcono(data.ofertas);

      var dataObra = data.obra ?
        ` Proyecto: ${data.obra.PROYECTO} <br/>
          Etapa: ${data.obra.ETAPA} <br/>
          Plan: ${data.obra.PLAN} <br/>
          Procentaje: ${data.obra.PORCENTAJE}`
        : ""

      var contentString =
        '<div style="margin:0;display: inline; width: 100%;">' +
        '<div style="display: inline;">' +
        '<div id="' + data.id + '" class="">' +
        '<span style="float: left;margin: 0 10px 0 0;"><img src="' + this.icon + '" style="width:25px;" ></span> <h3 id="firstHeading" style="font-size: 14px;">' + data.nombre + '</h3>' +
        '<span>CUE ' + data.id  + "<br/>" +
        dataObra +
        "</span>" +
        '</div>' +
        '</div>' +
        '</div>';

      this.markerRaGe = marker;
      this.escuelaRaGe = data;
      this.talkService.sendMarkerSelected(this.markerRaGe);


      this.infoWindow.setContent(contentString);
      this.talkService.sendMarkerBarraInfo(data);
      this.infoWindow.open(this.map, marker);


    });

  }//END spiderClickMarcadores()-------------------------------------------------------------------------------------------------------------------------------------------------


  dobleClickMarcadores = (marker, data) => {
    google.maps.event.addListener(marker, 'dblclick', (e) => {
      // delete polylines
      this.polylineEliminar();
      //delete circle
      this.circuloEliminar();
      //  this.schoolsGraphsEnrollments.push(this.escuelaMenorAcinco);
      this.circuloDibujar(data, 5);
      // this.polylineEnrollments.push(this.graphsEnrollments);
      this.icon = this.asignarIcono(data.ofertas);
      let a = []
      a.push(data)
      this.crearMarcadores(a);
      this.talkService.sendMarkerBarraInfo(data);
      this.talkService.sendEscuelaIdMenor5(data)
      this.escuelaMenorAcinco = data;
    });

  }//END dobleClickMarcadores()-------------------------------------------------------------------------------------------------------------------------------------------------


  clickDerechoMarcadores = (marker, data) => {
    google.maps.event.addListener(marker, "rightclick", (e) => {
      //if (! mapHasIdled) return;
      this.icon = this.asignarIcono(data.ofertas);

      var dataObra = data.obra ?
        ` Proyecto: ${data.obra.PROYECTO} <br/>
          Etapa: ${data.obra.ETAPA} <br/>
          Plan: ${data.obra.PLAN} <br/>
          Procentaje: ${data.obra.PORCENTAJE}`
        : ""

      var contentString =
        '<div style="margin:0;display: inline; width: 100%;">' +
        '<div style="display: inline;">' +
        '<div id="' + data.id + '" class="">' +
        '<span style="float: left;margin: 0 10px 0 0;"><img src="' + this.icon + '" style="width:25px;" ></span> <h3 id="firstHeading" style="font-size: 14px;">' + data.nombre + '</h3>' +
        '<span>CUE ' + data.id + "<br/>" +
        dataObra +
        "</span>" +
        '</div>' +
        '</div>' +
        '</div>';

      var latLngCircle = new google.maps.LatLng(data.domicilio.position[1], data.domicilio.position[0]);
      console.log(latLngCircle)
      var latLngidLocations = [];
      latLngidLocations.push(latLngCircle, data.id)
      this.talkService.sendLatLngCenterCircle(latLngidLocations);
      this.infoWindow.setContent(contentString);
      this.infoWindow.open(this.map, marker);
    });

  }//END clickDerechoMarcadores()-------------------------------------------------------------------------------------------------------------------------------------------------

  test = () => {
    console.log(this.markerRaGe)
  }

  //assign icons to markers according to your school level
  asignarIcono = (ofertas) => {
    var inicial = [152, 155, 121, 122, 100, 101];
    var primaria = [136, 123, 126, 140, 102, 105, 153, 156, 158];
    var secundaria = [143, 144, 108, 109, 110, 111, 129, 130, 147, 148, 151, 154, 157, 159, 131, 132, 149, 150, 138];
    var superior = [117, 115];
    var of = [0, 0, 0, 0, 0, 0];

    ofertas.forEach(ele => {
      if (inicial.indexOf(ele.id) != -1) {
        of[0] = 1;
      }
      if (primaria.indexOf(ele.id) != -1) {
        of[1] = 1;
      }
      if (secundaria.indexOf(ele.id) != -1) {
        of[2] = 1;
      };
      if (superior.indexOf(ele.id) != -1) {
        of[3] = 1;
      };
    });

    var sum = 0

    of.forEach(ele => {
      sum += ele
    });

    if (sum > 1) {
      of[4] = 1;
    } else if (sum == 0) {
      of[5] = 1;
    }

    var icon = "";
    if (of[4] == 1) {
      icon = '/assets/images/varios.svg';
    } else if (of[0] == 1) {
      icon = '/assets/images/inicial.svg';
    } else if (of[1] == 1) {
      icon = '/assets/images/primaria.svg';
    } else if (of[2] == 1) {
      icon = '/assets/images/secundaria.svg';
    } else if (of[3] == 1) {
      icon = '/assets/images/superior.svg';
    } else if (of[5] == 1) {
      icon = '/assets/images/servcomp.svg'
    } else {
      icon = '/assets/images/singeo.svg';
    }

    return icon

  }//END asignarIcono()---------------------------------------------------------------------------------------------------------------------------------------------------------


  //this method assign markers icons depending on wehter or not it has internet connectivity
  assignIconInternet = (data) => {
    var icon = "";
    if (data.tieneInternet == 1) {
      for (var j in data.conexiones) {
        if (data.conexiones[j].conexionEstado.descripcion == "A Conectar") {
          icon = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
          return icon;
        }
      }
      icon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      return icon;
    } else {
      icon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
      return icon;
    }

  }//END assignIconInternet()-------------------------------------------------------------------------------------------------------------------------------------------------------------


  //this method assign markers icons depending on wehter or not it has internet connectivity
  assignIconProvider = (location) => {
    var icon = "";

    if (location.tieneInternet == 1) {
      if (location.conexiones.length > 1) {
        console.log(location)
        for (var k = 0; k < location.conexiones.length - 1; k++) {
          // console.log(location.conexiones[k].proveedor.id)
          console.log(k + 1)
          if (location.conexiones[k].proveedor.id != location.conexiones[k + 1].proveedor.id) {
            icon = 'green';
            console.log(this.debug)
            return icon;
          }
        }

      } else if (location.conexiones[0].proveedor.id >= 0 && location.conexiones[0].proveedor.id <= 6) {
        switch (location.conexiones[0].proveedor.id) {
          case 0:
            //CIBERNEK S.R.L.
            icon = 'red'
            break;
          case 1:
            //Rentas Provincia
            icon = '#ff9900'
            break;
          case 2:
            //Gigared
            icon = '#6498ff'
            break;
          case 3:
            //Codex SA
            icon = '#69e3e3'
            break;
          case 4:
            //NoRegistrados
            icon = '#8e67fd'
            break;
          case 5:
            //ARSAT
            icon = '#e661ac'
            break;
          case 6:
            //RadioClub
            icon = 'gray'
            break;

        }

      } else {
        //Otros
        icon = 'brown';
      }

    } else {
      //No tiene
      icon = 'black'
    }

    return icon;

  }//END assignIconInternet()----------------------------------------------------------------------------------------------------------------------------------------------------


  graphsSchools = (params, matriculaCantidad, distancia) => {
    //declare array of marker for draw in maps
    this.schoolsGraphsEnrollments = [];
    //declare array of schools with enrollments less than X
    var enrollmentLessFive = [];
    //declare array of schools with enrollments greater than X
    var enrollmentHigherFive = [];

    //function for separe the schools with enrollments less than X and schools with enrollment greater than X
    this.escuelasSepararPorCantidadMatricula(params, matriculaCantidad, enrollmentLessFive, enrollmentHigherFive);

    //If there is no distance, then connect the chools with polylines
    if (distancia == undefined) {
      this.escuelasUnirConPolylines(enrollmentLessFive, enrollmentHigherFive)
    } else {// if there is a distance, then draw circle around the selected school
      this.escuelasDibujarDentroCirculo(params, distancia);
    }

    //draw markers
    this.crearMarcadores(this.schoolsGraphsEnrollments);

  }//END graphsScholls()----------------------------------------------------------------------------------------------------------------------------------------------------------


  circuloDibujar = (escuela, distancia) => {
    this.circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      center: new google.maps.LatLng(escuela.domicilio.position[1], escuela.domicilio.position[0]),
      radius: distancia * 1000
    });

    this.circle.setMap(this.map2);

  }//END circuloDibujar()--------------------------------------------------------------------------------------------------------------------------------------------------


  circuloEliminar = () => {
    if (this.circle != undefined) {
      this.circle.setMap(null);
      this.circle.setVisible(false)
    }

  }//END circuloEliminar()--------------------------------------------------------------------------------------------------------------------------------------------------


  polylineDibujar = (latLng1, latLng2) => {
    // Define a symbol using SVG path notation, with an opacity of 1.
    var lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeOpacity: 1,
      scale: 4
    };

    this.graphsEnrollments = new google.maps.Polyline({
      path: [latLng1,
        latLng2,
      ],
      icons: [{
        icon: lineSymbol,
        offset: '1',
      }],
      geodesic: true,
      strokeColor: '#000000',
      strokeOpacity: 0.7,
      strokeWeight: 1,

    });

  }//END polylineDibujar()--------------------------------------------------------------------------------------------------------------------------------------------------


  polylineEliminar = () => {
    if (this.polylineEnrollments.length != 0) {
      for (var i = 0; i < this.polylineEnrollments.length; i++) {
        this.polylineEnrollments[i].setMap(null); //or line[i].setVisible(false);
      }
    }

  }//END polylineEliminar()--------------------------------------------------------------------------------------------------------------------------------------------------


  escuelasSepararPorCantidadMatricula = (localizaciones, matriculaCantidad, escuelasMatriculaMenorX, escuelasMatriculaMayorX) => {
    this.enrollments.forEach(element => {
      if (element.matriculaTotal <= matriculaCantidad) {
        localizaciones.forEach(locations => {
          if (element.cueAnexo == locations.id) {
            escuelasMatriculaMenorX.push(locations)
          }
        });

      } else {
        localizaciones.forEach(locations => {
          if (element.cueAnexo == locations.id) {
            escuelasMatriculaMayorX.push(locations)
          }
        });
      }
    });

  }//END escuelasSepararPorCantidadMatricula()--------------------------------------------------------------------------------------------------------------------------------------------------


  escuelasUnirConPolylines = (enrollmentLessFive, enrollmentHigherFive) => {
    var idEnrollmentHigherFive;
    var distance = 0;
    var latLng: any;
    enrollmentLessFive.forEach(lessFive => {
      var flag = 0;
      var distanceAux = 9999999999999999999;

      for (let index = 0; index < enrollmentHigherFive.length; index++) {
        if (lessFive.domicilio.localidad.departamento.nombre == enrollmentHigherFive[index].domicilio.localidad.departamento.nombre) {
          for (var k in lessFive.ofertas) {
            for (var j in enrollmentHigherFive[index].ofertas) {
              if (lessFive.ofertas[k].base.descripcion == enrollmentHigherFive[index].ofertas[j].base.descripcion) {
                if (google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lessFive.domicilio.position[1], lessFive.domicilio.position[0]), new google.maps.LatLng(enrollmentHigherFive[index].domicilio.position[1], enrollmentHigherFive[index].domicilio.position[0])) < distanceAux) {
                  distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lessFive.domicilio.position[1], lessFive.domicilio.position[0]), new google.maps.LatLng(enrollmentHigherFive[index].domicilio.position[1], enrollmentHigherFive[index].domicilio.position[0]))
                  latLng = new google.maps.LatLng(enrollmentHigherFive[index].domicilio.position[1], enrollmentHigherFive[index].domicilio.position[0]);
                  flag = 1;
                  idEnrollmentHigherFive = enrollmentHigherFive[index];
                }
                distanceAux = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lessFive.domicilio.position[1], lessFive.domicilio.position[0]), new google.maps.LatLng(enrollmentHigherFive[index].domicilio.position[1], enrollmentHigherFive[index].domicilio.position[0]));
              }
            }
          }
        }
      }


      if (flag == 1) {
        this.schoolsGraphsEnrollments.push(lessFive, idEnrollmentHigherFive);
        this.polylineDibujar(new google.maps.LatLng(lessFive.domicilio.position[1], lessFive.domicilio.position[0]), latLng);
        this.graphsEnrollments.setMap(this.map2);
        this.polylineEnrollments.push(this.graphsEnrollments);
      }

      latLng = undefined;
    });

  }//END escuelasUnirConPolylines()--------------------------------------------------------------------------------------------------------------------------------------------------


  escuelasDibujarDentroCirculo = (localizaciones, distancia) => {
    for (let index = 0; index < localizaciones.length; index++) {
      for (var j in localizaciones[index].ofertas) {
        for (var k in this.escuelaMenorAcinco.ofertas) {
          if (this.escuelaMenorAcinco.ofertas[k].base.descripcion == localizaciones[index].ofertas[j].base.descripcion) {
            if (google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(this.escuelaMenorAcinco.domicilio.position[1], this.escuelaMenorAcinco.domicilio.position[0]), new google.maps.LatLng(localizaciones[index].domicilio.position[1], localizaciones[index].domicilio.position[0])) <= (distancia * 1000)) {
              if (!this.schoolsGraphsEnrollments.includes(localizaciones[index])) {
                this.schoolsGraphsEnrollments.push(localizaciones[index]);
              }
            }
          }
        }

      }
    }

    //delete circle
    this.circuloEliminar();
    if (!this.schoolsGraphsEnrollments.includes(this.escuelaMenorAcinco)) {
      this.schoolsGraphsEnrollments.push(this.escuelaMenorAcinco);
    }

    this.circuloDibujar(this.escuelaMenorAcinco, distancia);

  }//END escuelasDibujarDentroCirculo()--------------------------------------------------------------------------------------------------------------------------------------------------


  setIconMarkerProvider = (marker) => {
    marker.setIcon({
      path: "M54.65,0A54.72,54.72,0,0,0,0,54.65c0,12.14,7.75,31.93,23.69,60.49C35,135.35,46.06,152.2,46.53,152.91l8.12,12.31,8.13-12.31c.46-.71,11.55-17.56,22.83-37.77,15.94-28.56,23.69-48.35,23.69-60.49A54.71,54.71,0,0,0,54.65,0Zm0,82.61A28.33,28.33,0,1,1,83,54.28,28.33,28.33,0,0,1,54.65,82.61Z",
      fillColor: this.icon,
      fillOpacity: 1,
      scale: 0.2,
      anchor: new google.maps.Point(20, 20),
      strokeColor: "black",
      strokeWeight: 1,
    })

  }//END setIconMarkerProvider()--------------------------------------------------------------------------------------------------------------------------------------------------


  setIconMarker = (marker) => {
    marker.setIcon({
      url: this.icon,
      anchor: new google.maps.Point(20, 20),
      scaledSize: new google.maps.Size(45, 45),
      labelOrigin: new google.maps.Point(0, 0),
    })

  }//END setIconMarker()--------------------------------------------------------------------------------------------------------------------------------------------------


  infoMarkerCrear = (dataI, pMarker, pImgToBarraInfo) => {
    var domicilios
    var efInfo
    var localidad
    var dpto
    var ofertas
    var enrollment

    if (dataI.hasOwnProperty("eje")) {
      efInfo = { nombre: dataI.eje.nombre, categoria: dataI.categoria.nombre }
    }
    if (dataI.domicilio.calle) {
      domicilios = dataI.domicilio.calle;
      if (dataI.domicilio.nro) {
        domicilios = domicilios + ' ' + dataI.domicilio.nro;
        if (dataI.domicilio.barrio) {
          domicilios = domicilios + ", Barrio: " + dataI.domicilio.barrio;
        }
      }
    }
    if (dataI.domicilio.localidad) {
      localidad = dataI.domicilio.localidad.nombre;
      if (dataI.domicilio.localidad.departamento) {
        dpto = dataI.domicilio.localidad.departamento.nombre;
      }
    }
    ofertas = dataI.ofertas.reduce((prev, cur) =>
      prev += "Modalidad: " + cur.modalidad.descripcion + ", Nivel :" + cur.base.descripcion + ". ", "");

    this.enrollments.forEach(element => {
      if (dataI.id == element.cueAnexo) {
        enrollment = element.matriculaTotal;
      }
    });

    var infoMarker = {
      marker: pMarker,
      id: dataI.id,
      cue: dataI.id,
      sector: dataI.establecimiento.sector.descripcion ? dataI.establecimiento.sector.descripcion : "",
      ambito: dataI.ambito.descripcion ? dataI.ambito.descripcion : "",
      ofertas: ofertas,
      domicilios: domicilios,
      localidad: localidad,
      dpto: dpto,
      internet: dataI.tieneInternet == 0 ? "No tiene" : "Tiene",
      efinfo: efInfo,
      lat: dataI.domicilio.position[1],
      lng: dataI.domicilio.position[0],
      conexiones: dataI.conexiones,
      imgToBarraInfo: pImgToBarraInfo,
      matricula: enrollment,
    }

    return infoMarker;

  }//END infoMarkerCrear()--------------------------------------------------------------------------------------------------------------------------------------------------


  tiposIconosAsignar = (pData, i) => {
    //assign marker icon depending on the of filter
    var imgToBarraInfo = "";

    //it is filtered by internet connectivity, then the assignIconInternet() function is invoked 
    if (pData[pData.length - 1] == "internetConnect" || this.flagInternet == 1) {
      //remove the last element from the array only the first time you enter (in this way remove the string "internetConnect")
      if (this.flagInternet != 1) {
        pData.pop();
      }
      //put flag = 1, then it will continue invoking the assignIconInternet() function, and will not take out the last element of the array anymore
      this.flagInternet = 1;
      //send the tieneInternet value to assign the marker accordingly, to draw on the map
      this.icon = this.assignIconInternet(pData[i]);
      //in imgToBarraInfo the icon the url is savedaccording to the school level, this is used for the bar-info component
      imgToBarraInfo = this.asignarIcono(pData[i].ofertas);

    } else if (pData[pData.length - 1] == "internetProvider" || this.flagProvider == 1) {
      var flagSetIconProv = 1;
      if (this.flagProvider != 1) {
        pData.pop();
      }
      this.flagProvider = 1;
      //send the location value to assign the marker accordingly, to draw on the map
      this.icon = this.assignIconProvider(pData[i]);
      //in imgToBarraInfo the icon the url is savedaccording to the school level, this is used for the bar-info component
      imgToBarraInfo = this.asignarIcono(pData[i].ofertas);
    }

    else {
      //assign icon to draw on the map
      this.icon = this.asignarIcono(pData[i].ofertas);
      //assign icon for the bar-info component ()
      imgToBarraInfo = this.asignarIcono(pData[i].ofertas);
    }

    return [imgToBarraInfo, flagSetIconProv]

  }//END tiposIconosAsignar()-------------------------------------------------------------------------------------------------------------------------------------------------------------


  drawChart() {

    var data = google.visualization.arrayToDataTable([
      ['Tipo de Registro', 'Cantidad de Alumnos', { role: 'style' }],
      ['Relevamiento Anual', this.matriculaPorEscuelaRA(), 'color: orange'],
      ['Gestion Educativa', this.matriculaPorEscuelaGE(), 'color: #76A7FA']
    ]);


    // Set chart options
    var options = {
      title: "Comparacion de: Relevamiento Anual - Gestion Educativa",
      vAxis: {
        minValue: 0,
      },
      width: 500,
      height: 300,
      bar: { groupWidth: "70%" },
      legend: { position: "none" },
    };
    var chart = new google.visualization.ColumnChart(document.getElementById('piechart'));
    chart.draw(data, options);

  }//END drawChart()-------------------------------------------------------------------------------------------------------------------------------------------------------------


  //returns the number of enrolled annual survey by schools
  matriculaPorEscuelaRA = () => {
    var matriculaRA;
    this.relevamientoAnual.forEach(element => {
      if (element.cueAnexo == this.escuelaRaGe.id) {
        matriculaRA = element.matriculaTotal;
      }
    });
    return parseInt(matriculaRA);

  }//END matriculaPorEscuelaRA()-------------------------------------------------------------------------------------------------------------------------------------------------------------


  //returns the number of enrolled in educational management by schools
  matriculaPorEscuelaGE = () => {
    var matriculaGE;
    this.gestionEducativa.forEach(element => {
      if (element.cueAnexo == this.escuelaRaGe.id) {
        matriculaGE = element.matriculaGestionEducativa;
      }
    });
    return parseInt(matriculaGE);

  }//END matriculaPorEscuelaGE()-------------------------------------------------------------------------------------------------------------------------------------------------------------



}//END class marcadores-------------------------------------------------------------------------------------------------------------------------------------------------------------




