import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';


//variable for jquery
declare var $:any;

@Component({
  selector: 'app-herramientas',
  templateUrl: './herramientas.component.html',
  styleUrls: ['./herramientas.component.scss']
})

export class HerramientasComponent implements OnInit {

  //attributes
  map:google.maps.Map;
  drawingManager:any;
  polyline: any;
  pathInit: any;
  distanceTotal: number = 0;
  areaTotal: string = "0m2";
  latlng: any;
  zoomMap: number;

  //COLLAPDSED
  collapsed = false


  constructor(private talkService: ComponentsTalkService) {
    setTimeout(() => {
      this.map = this.talkService.map;  
    }, 1000); 
     
  }//END constructor()--------------------------------------------------------------------------------------------
 
  collapse(){
    this.collapsed = !this.collapsed
    if(this.collapsed){
      $("#sidebar-geo").removeClass("col-md-3")
      $("#sidebar-geo").addClass("collapsed col-md-1")
    }else{
      $("#sidebar-geo").addClass("col-md-3")
      $("#sidebar-geo").removeClass("collapsed")
    }
  }

  ngOnInit() {
    
      //Create the drawing tool managenment objects
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          //Position of the drawing kit tool
          position: google.maps.ControlPosition.LEFT_BOTTOM,
          //Load drawing tools to use
          drawingModes: [google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.POLYGON,
            ]
        },
        markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'}, 
        //properties of the drawing of a polygon
        polygonOptions: {
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#000000',
          fillOpacity: 0.35,
          zIndex: 1
        },
      });   
      
    /*  setTimeout(() => {
        this.getZoomMap();
      }, 2000);*/
  
  }//END ngOnInit()-----------------------------------------------------------------------------------------------------


 /* getZoomMap = () => {
    google.maps.event.addListener(this.map, 'zoom_changed',this.lolo);
    
 
  }*/
 /* lolo = () => {
     var a = [];
     a.push(1,2,3,this.map.getZoom())
    this.talkService.sendZoomMap(a)
   
  }*/



  //methods to draw lines on the map
  addPolyline = () => {
    this.distanceTotal = 0
    
    //deselect the polygon tool if selected
    this.drawingManager.setDrawingMode(null)
    //create a new polilne with its properties
    this.polyline = new google.maps.Polyline({
      strokeColor: '#000000',
      strokeOpacity: 1.0,
      strokeWeight: 3
    });

    //add polyline to mapa
    this.polyline.setMap(this.map);
    // Add a listener for the click event
    this.map.addListener('click', this.addLatLngPolyline);
     
  }//END addPolyline()--------------------------------------------------------------------------------------------------


  // Handles click events on a map, and adds a new point to the Polyline. And calculate the distance
  addLatLngPolyline = (event) => {
  var path = this.polyline.getPath();

  // Because path is an MVCArray, we can simply append a new coordinate
  // and it will automatically appear.
  path.push(event.latLng);
  console.log(event.latLng)
  // Add a new marker at the new plotted point on the polyline.
  var marker = new google.maps.Marker({
    position: event.latLng,
    title: '#' + path.getLength(),
    animation: google.maps.Animation.DROP,
    map: this.map
  });

  // if it is the first point created on the map then its latitude and longitude is saved in order to calculate the distance with the following
  if(path.getLength() == "1"){
    this.pathInit = event.latLng;
  }
  //accumulator of the distances measured in the entire marked route
  this.distanceTotal += google.maps.geometry.spherical.computeDistanceBetween(this.pathInit, event.latLng);
  this.pathInit = event.latLng;
  }//END addLatLng()--------------------------------------------------------------------------------------------

  
  //method to cancel the use of the polyline
  endPolyline = () => {
    //polyline destroy
    this.polyline = null
  }//END endPolyline()--------------------------------------------------------------------------------------------

  
  //method to draw polygon on the map
  addPolygon = () => {
  this.areaTotal = "0m2";
 
  //add the drawing manager on the map  
  this.drawingManager.setMap(this.map);
  // Add a listener for the click event
  google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.addLatLngPolygon)
  //set the with options polygon
  this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
  }//END addPolygon()--------------------------------------------------------------------------------------------
 
  
  //Control the click events on a map and get the latitudes and longitudes of the click.And calculate the area
  addLatLngPolygon = (event) => {
  //event.overlay.latLngs.j[0].j (access to the latitude and the longitude array marked by the polygon)
  this.areaTotal = google.maps.geometry.spherical.computeArea(event.overlay.latLngs.g[0].g).toFixed(3) + "m2";

  this.drawingManager.setDrawingMode(null)

  }//END addLatLngPolygon()-----------------------------------------------------------------------------------------------
 
  
  
}//END Class HerramientasComponent --------------------------------------------------------------------------------------------
