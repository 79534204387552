<!-- Establecimientos radio -->

<div id="sidebar-radios" class="barras sidebar-filters dark col-sm-3 scrollbar" >

        <div class="row bar-title-wrap d-flex flex-row align-items-start">
              <h3 class="bar-title col-11">Localizaciones</h3>
              <button type="button" class="navbar-toggler col-1 sidebarFilter" data-toggle="collapse" data-target=".navbar-collapse" (click)="collapse()">
                <i class="material-icons" style="cursor: pointer">view_headline</i>
              </button>
      
          </div>
        <div class="bloque">
          <h4>Establecimientos por radio</h4>
          <p>Muestra los establecimientos que se encuentran en un radio determinado a partir de otro establecimiento central.</p>
          <hr>
          <div id="inforadios" class="row">
      
            <ol>
              <!-- <li><button id="btnAddCenter" class="btn btn-main btn-icon"><i class="fa fa-map-pin"></i></button> Indicar centro </li>
              <li> -->
              <li>Seleccionar centro haciendo <b>click derecho</b> en un establecimiento</li>
              <li>
                <p>Definir radio</p>
                <div class="range-slider" style="display:none" id="inputRadio">
                  <input (change)="newCircle()" class="range-slider__range" type="range" list="tickmarks" id="Sliderdist"  value="10" min="0" max="20"
                    step="1">
                  <span class="range-slider__value">
                    <b id="range">{{radiusValue}}</b> km</span>
                </div>
              </li>
            </ol>
            <p>Promedio de distancias:
              <b id="avgdist">{{averageDistance}}</b>Kms.</p>
      
      
          </div>
          <div id="estmainInfo" class="row">
            <ul id="estinfo" class="sidebar-nav">
      
            </ul>
          </div>
        </div>
      
        <hr>
      
        <!-- filtros -->
        <div class="filterarearadius" style="display:none">
      
          <div class="movingfilters">
      
          </div>
      
      
      
        </div>
        <app-filtros  id="filtrosComponent"></app-filtros>
      </div>
      
      <!-- FIN establecimientos -->
