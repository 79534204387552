import { Component, OnInit, ElementRef } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { RequestsApiService } from 'src/app/services/requestsApi.service';

declare var require: any;
const obrasJson = require("../../data/obras.json");

//variable for jquery
declare var $: any;

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.scss']
})
export class PlanesComponent implements OnInit {
  //ArrayFiltered
  filteredIdArray = []
  filteredArray = []
  //Arrays
  localizacionArray
  varkeyArray
  varkeyArrayData
  escuelaFuturoArray
  escuelaFuturoCategoriaArray
  escuelaFuturoEjeArray
  escuelaFuturoConectarIgualdad
  escuelaFuturoConectarIgualdadItem
  escuelaFuturoConectarIgualdadEntrega
  mitaiArray
  faroArray


  //Obras
  obras = obrasJson.obras
  obrasEstado = [
    { etapa: "POR COMENZAR" },
    { etapa: "EN EJECUCION" },
    { etapa: "FINALIZADO" }
  ]

  //Array of Years
  yearArray = [
    { nombre: '2010' },
    { nombre: '2011' },
    { nombre: '2012' },
    { nombre: '2013' },
    { nombre: '2014' },
    { nombre: '2015' },
    { nombre: '2016' },
    { nombre: '2017' },
  ]

  //Elements
  escuelaDelFuturoHijoCategorias = false
  escuelaDelFuturoHijoEjes = false
  conectarIgualdadItems = false
  conectarIgualdadAnios = false
  conectarIgualdadTipoDeEntrega = false
  varkeyCategorias = false
  obrasHijo = false

  //COLLAPSED
  collapsed = false

  constructor(private talkService: ComponentsTalkService, private apiService: RequestsApiService) { }

  collapse() {
    this.collapsed = !this.collapsed
    if (this.collapsed) {
      $("#sidebar-planes").removeClass("col-md-3")
      $("#sidebar-planes").addClass("collapsed col-md-1")
    } else {
      $("#sidebar-planes").addClass("col-md-3")
      $("#sidebar-planes").removeClass("collapsed")
    }
  }

  ngOnInit() {
    this.apiService.getLocalizacion().subscribe(data => {
      console.log('Localizacion', data)
      this.localizacionArray = data
    })
    this.apiService.getVarkey().subscribe((data: any) => {
      this.varkeyArrayData = data
      this.varkeyArray = []
      data.map((varkey: any) => {
        Object.keys(varkey.categorias).map(catIndice => {
          if (this.varkeyArray.indexOf(catIndice) == -1) {
            this.varkeyArray.push(catIndice)
          }
        })
      });
    })
    this.apiService.getEscuelaFuturo().subscribe(data => {
      console.log('EscFuturo', data)
      this.escuelaFuturoArray = data;
    })
    this.apiService.getEscuelaFuturoCategoria().subscribe(data => {
      //console.log('EscFuturoCat',data)
      this.escuelaFuturoCategoriaArray = data;
    })
    this.apiService.getEscuelaFuturoEje().subscribe(data => {
      this.escuelaFuturoEjeArray = data;
    })
    this.apiService.getEscuelaFuturoConectarIgualdad().subscribe(data => {
      this.escuelaFuturoConectarIgualdad = data;
    })
    this.apiService.getEscuelaFuturoConectarIgualdadItem().subscribe(data => {
      this.escuelaFuturoConectarIgualdadItem = data;
    })
    this.apiService.getEscuelaFuturoConectarIgualdadEntrega().subscribe(data => {
      this.escuelaFuturoConectarIgualdadEntrega = data;
    })
    this.apiService.getEscuelaFuturoMitai().subscribe(data => {
      this.mitaiArray = data;
    })
    this.apiService.getEscuelaFuturoFaro().subscribe(data => {
      this.faroArray = data;
    })
  }

  showChildFilters = (element: any) => {
    if (element.id == "escFuturoPadre") {
      this.escuelaDelFuturoHijoCategorias = !this.escuelaDelFuturoHijoCategorias
      this.escuelaDelFuturoHijoCategorias ? $('#escFuturoHijoCat').show() : $('#escFuturoHijoCat').hide();
      this.escuelaDelFuturoHijoEjes = !this.escuelaDelFuturoHijoEjes
      this.escuelaDelFuturoHijoEjes ? $('#escFuturoHijoEje').show() : $('#escFuturoHijoEje').hide();
    }
    if (element.id == 'escConectarIgualdad') {
      this.conectarIgualdadItems = !this.conectarIgualdadItems
      this.conectarIgualdadItems ? $('#conectarIgualdadItems').show() : $('#conectarIgualdadItems').hide();

      this.conectarIgualdadAnios = !this.conectarIgualdadAnios
      this.conectarIgualdadAnios ? $("#conectarIgualdadAnios").show() : $("#conectarIgualdadAnios").hide();

      this.conectarIgualdadTipoDeEntrega = !this.conectarIgualdadTipoDeEntrega
      this.conectarIgualdadTipoDeEntrega ? $("#conectarIgualdadTipoDeEntrega").show() : $("#conectarIgualdadTipoDeEntrega").hide()

    }
    if (element.id == 'varkeyPadre') {
      this.varkeyCategorias = !this.varkeyCategorias
      this.varkeyCategorias ? $('#varkeyCat').show() : $('#varkeyCat').hide()
    }

    if (element.id == 'obrasPadre') {
      this.obrasHijo = !this.obrasHijo
      this.obrasHijo ? $('#obrasHijo').show() : $('#obrasHijo').hide()
    }


  }

  onCheckBoxFather = (ele: any) => {
    if (ele.id == 'escFuturo') {
      if (ele.checked) {
        $('#escFuturoHijoCat input').each((index, elem) => {
          $(elem).prop("checked", true)
        })
      } else {
        $('#escFuturoHijoCat input').each((index, elem) => {
          $(elem).prop("checked", false)
        })
      }
      // $('#escFuturoHijoEje input').each( (index,elem) => {
      //   $(elem).prop("checked",true)
      // })
    }
    if (ele.id == 'Conig') {
      if (ele.checked) {
        $('#ConectIgualdadItems input').each((index, elem) => {
          $(elem).prop("checked", true)
        })
      } else {
        $('#ConectIgualdadItems input').each((index, elem) => {
          $(elem).prop("checked", false)
        })
      }
      //$('#ConectIgualdadAnios input:checked')
      //$('#ConectIgualdadTipoDeEntrega input:checked')
    }
    if (ele.id == 'varkey') {
      if (ele.checked) {
        $('#vakeyCatItems input').each((index, elem) => {
          $(elem).prop("checked", true)
        })
      } else {
        $('#vakeyCatItems input').each((index, elem) => {
          $(elem).prop("checked", false)
        })
      }
    }

    if (ele.id == 'obras') {
      if (ele.checked) {
        $('#obrasHijoItems input').each((index, elem) => {
          $(elem).prop("checked", true)
        })
      } else {
        $('#obrasHijoItems input').each((index, elem) => {
          $(elem).prop("checked", false)
        })
      }
    }

    this.filtrar()
  }

  onCheckBoxChildren = () => {
    let escFuturoPadre = [false, false]
    let conectIgualdadPadre = [false, false, false]
    let varkeyPadre = [false]
    let obrasPadre = [false]
    $('#escFuturoHijoCat input:checked').each((i, item) => {
      if (i >= 0) {
        escFuturoPadre[0] = true
      }
    })
    $('#escFuturoHijoEje input:checked').each((i, item) => {
      if (i >= 0) {
        escFuturoPadre[1] = true
      }
    })
    $('#ConectIgualdadItems input:checked').each((i, item) => {
      if (i >= 0) {
        conectIgualdadPadre[0] = true
      }
    })
    $('#ConectIgualdadAnios input:checked').each((i, item) => {
      if (i >= 0) {
        conectIgualdadPadre[1] = true
      }
    })
    $('#ConectIgualdadTipoDeEntrega input:checked').each((i, item) => {
      if (i >= 0) {
        conectIgualdadPadre[2] = true
      }
    })
    $('#vakeyCatItems input:checked').each((i, item) => {
      if (i >= 0) {
        varkeyPadre[0] = true
      }
    })
    $('#obrasHijoItems input:checked').each((i, item) => {
      if (i >= 0) {
        obrasPadre[0] = true
      }
    })

    if (escFuturoPadre[0] || escFuturoPadre[1]) {
      $('#escFuturo').prop("checked", true)
    } else { $('#escFuturo').prop("checked", false) }

    if (conectIgualdadPadre[0] || conectIgualdadPadre[1] || conectIgualdadPadre[2]) {
      $('#Conig').prop("checked", true)
    } else { $('#Conig').prop("checked", false) }

    if (varkeyPadre[0]) {
      $('#varkey').prop("checked", true)
    } else { $('#varkey').prop("checked", false) }

    if (obrasPadre[0]) {
      $('#obras').prop("checked", true)
    } else { $('#obras').prop("checked", false) }

    this.filtrar()
  }

  filtrar = () => {
    this.filteredArray = []
    this.filteredIdArray = []

    //FILL ARRAY OF IDs
    $('#escFuturoHijoCat input:checked').each((i, item) => {
      this.escuelaFuturoArray.map((esc: any) => {
        if (item.value == esc.eje.categoria.nombre) {
          if (!this.filteredIdArray.includes(esc.id)) {
            this.filteredIdArray.push(esc.id)
          }
        }
      })
    })

    $('#escFuturoHijoEje input:checked').each((i, item) => {
      this.escuelaFuturoArray.map((esc: any) => {
        if (item.value == esc.eje.nombre) {
          if (!this.filteredIdArray.includes(esc.id)) {
            this.filteredIdArray.push(esc.id)
          }
        }
      })
    })

    $('#ConectIgualdadItems input:checked').each((i, item) => {
      this.escuelaFuturoConectarIgualdad.map(esc => {
        if (esc.tipoItem.nombre == item.value) {
          if (!this.filteredIdArray.includes(esc.cueanexo)) {
            this.filteredIdArray.push(esc.cueanexo)
          }

        }
      })
    })

    $('#ConectIgualdadAnios input:checked').each((i, item) => {
      this.escuelaFuturoConectarIgualdad.map(esc => {
        if (esc.añoEntrega == item.value) {
          if (!this.filteredIdArray.includes(esc.cueanexo)) {
            this.filteredIdArray.push(esc.cueanexo)
          }
        }
      })
    })

    $('#ConectIgualdadTipoDeEntrega input:checked').each((i, item) => {
      this.escuelaFuturoConectarIgualdad.map(esc => {
        if (esc.tipoEntrega.nombre == item.value) {
          if (!this.filteredIdArray.includes(esc.cueanexo)) {
            this.filteredIdArray.push(esc.cueanexo)
          }
        }
      })
    })

    $('#vakeyCatItems input:checked').each((i, item) => {
      this.varkeyArrayData.map((esc: any) => {
        Object.keys(esc.categorias).map(escValue => {
          if (escValue == item.value) {
            if (!this.filteredIdArray.includes(esc.id)) {
              this.filteredIdArray.push(esc.id);
            }
          }
        })
      })
    })

    $('#obrasHijoItems input:checked').each((i, item) => {
      this.obras.map((obra: any) => {
        if (obra.ETAPA == item.value) {
          if (!this.filteredIdArray.includes(obra.CUE)) {
            this.filteredIdArray.push(obra.CUE);
          }
        }
      })
    })

    //FILTER WITH ID
    this.filteredIdArray.map(id => {
      this.localizacionArray.map(loc => {
        if (loc.id == id) {
          this.obras.map((obra: any) => {
            if (obra.CUE == loc.id) {
              loc.obra = obra
            }
          })
          this.filteredArray.push(loc)
        }
      })
    })

    //SEND TO MARKERS
    this.talkService.sendArrayLocalizacionFiltered(this.filteredArray)
  }

  faroFilter = () => {
    this.filteredArray = []
    this.filteredIdArray = []

    this.localizacionArray.map(loc => {
      this.faroArray.map(faro => {
        if (faro.id == loc.id) {
          if (!this.filteredArray.includes(faro.id)) {
            this.filteredArray.push(loc)
          }
        }
      })
    })

    //SEND TO MARKERS
    this.talkService.sendArrayLocalizacionFiltered(this.filteredArray)
  }

  mitaiFilter = () => {
    this.filteredArray = []
    this.filteredIdArray = []

    this.localizacionArray.map(loc => {
      this.mitaiArray.map(mitai => {
        if (mitai.id == loc.id) {
          if (!this.filteredArray.includes(mitai.id)) {
            this.filteredArray.push(loc)
          }
        }
      })
    })

    //SEND TO MARKERS
    this.talkService.sendArrayLocalizacionFiltered(this.filteredArray)
  }
}
