import { Component, OnInit } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { RequestsApiService } from 'src/app/services/requestsApi.service';

declare var $:any;

@Component({
  selector: 'app-localizaciones',
  templateUrl: './localizaciones.component.html',
  styleUrls: ['./localizaciones.component.scss']
})
export class LocalizacionesComponent implements OnInit {

  //attributes
  map:google.maps.Map;
  drawingManager:any;
  latLngCircleCenter: any;
  circle: any
  radiusValue = 10;
  averageDistance = 0;
  idLocations: any;
  flag = 0;

  //COLLAPSED
  collapsed = false

  constructor(private talkService: ComponentsTalkService, private requestsApiService: RequestsApiService) {
    setTimeout(() => {
      this.map = this.talkService.map;  
    }, 1000); 

  }//END Constructor()----------------------------------------------------------------------------------------------------

  collapse(){
    this.collapsed = !this.collapsed
    if(this.collapsed){
      $("#sidebar-radios").removeClass("col-md-3")
      $("#sidebar-radios").addClass("collapsed col-md-1")
    }else{
      $("#sidebar-radios").addClass("col-md-3")
      $("#sidebar-radios").removeClass("collapsed")
    }
  }
 
  ngOnInit() {
    //capture the latitude, longitude and id of the marker that was right clicked   
    this.talkService.ObservableLatLngCenterCircle.subscribe(data => {
      for(var j = 0; j < data.length-1; j++){
        this.latLngCircleCenter = data[j];
        this.idLocations = data[j+1];
      }
      //show input to modify the radius 
      $("#inputRadio").css("display", "block");
      //draw the circle on the selected marker
      console.log(this.circle)
      this.drawCircle(this.latLngCircleCenter, 10);    
    });

  }//END ngOnInit()----------------------------------------------------------------------------------------------------

  
  ngOnDestroy(){
    if(this.circle != undefined){
      var centerAndRadiusCircle = [];     
      centerAndRadiusCircle.push(new google.maps.LatLng(-28.789344058036477, -57.86776562440559), 5000000);

      //delete circle of map
      this.circle.setMap(null);
      //send center and circle of the radius to draw any marker 
      this.talkService.sendCenterRadiusCircle(centerAndRadiusCircle);
    }
   
  }//END ngOnDestroy()---------------------------------------------------------------------------------------------------

  
  //method to draw the circle
  drawCircle = (latLngCircleCenter, distance?) => {
    console.log("lolo")
    //declar array that stores the center and the radius on the circle to send to the bookmarks component
    var centerAndRadiusCircle = [];
    //capture the value of the input to use as a circle radius
    this.radiusValue = $('#Sliderdist').val();

    //delete circle
    if(this.circle != undefined){
        this.circle.setMap(null);
    }

    //draw circle
    this.circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      center: latLngCircleCenter,
      radius: distance * 1000
    });
    this.circle.setMap(this.map);
  
    centerAndRadiusCircle.push(this.circle.getCenter(), this.circle.getRadius());
    //send center and radius of the circle so that the marker component filters the locations to be displayed
    this.talkService.sendCenterRadiusCircle(centerAndRadiusCircle);
    //show the filters component and send all locations
    this.showLocationsByCircle();

    //subscribes to the request to the api that brings the average distance of a locations according to its radius
    this.requestsApiService.getAverageDistance(this.idLocations, this.radiusValue).subscribe(data => {
     this.averageDistance = Object.values(data)[0].value.toFixed(2)
    });
 
  }//END drawCircle()----------------------------------------------------------------------------------------------------

  //draw a new circle when the input values changes
  newCircle = () => {  
    this.drawCircle(this.latLngCircleCenter, $('#Sliderdist').val());

  }//END radiusCircle()----------------------------------------------------------------------------------------------------

  
  //method that shows the filters component and send the locations to the markers components
  showLocationsByCircle = () => {
    //request to the api the all locations and send them to the markers comonent to draw marker to according to the area drawn
    this.requestsApiService.getLocalizacion().subscribe(locations => {
      this.talkService.sendArrayLocalizacionFiltered(locations);
    });
   
    //show the fiters component
    $("#filtrosComponent").css("display", "block");
    //hide fiters by departaments
    $("#btnDep").css("display", "none");
    //hide filters by locations
    $("#btnLoc").css("display", "none");
    $("#sidebar-filters").css("maxWidth", "95%");
    $("#sidebar-filters").css("margin-left", "2%");

  }//END showLocationsByCircle()----------------------------------------------------------------------------------------------


}//END CLASS LOCALIZACION**************************************************************************************************
