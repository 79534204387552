import { Component, OnInit } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { AuthService } from 'src/app/services/auth.service';

//variable for jquery
declare var $: any;

@Component({
  selector: 'app-barra-lateral',
  templateUrl: './barra-lateral.component.html',
  styleUrls: ['./barra-lateral.component.scss'],
})
export class BarraLateralComponent implements OnInit {
  currentUser

  constructor(private talkService: ComponentsTalkService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(data => {
      this.currentUser = data;
    })
  }


  modal() {
    this.talkService.sendModal("open")
  }

  logout() {
    this.authService.logout()
  }

  /*btnActive = (id) => {
    $(".barras").removeClass('active');
	  $("#id").addClass('active');
  }*/




}
