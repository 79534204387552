import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit, AfterViewInit {

  @ViewChild('map', { static: false }) mapElement: any;
  map: google.maps.Map;

  constructor(private talkService:ComponentsTalkService) {

  }
  ngAfterViewInit(): void {
    const mapProperties = {
      center: new google.maps.LatLng(-27.483333333333, -58.816666666667),
      zoom: 7,
      scrollwheel:true,
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: !0,
      mapTypeControlOptions: { style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid "], position: google.maps.ControlPosition.RIGHT_TOP }
    };
    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.talkService.sendMapa(this.map)
    }, 1000);
  }
  ngOnInit() {
 console.log(document.getElementById('map'))
  }


 

}
