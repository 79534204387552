<div id="sidebar-filters" class="barras sidebar-filters col-md-3 scrollbar active float-left">

    <div class="row bar-title-wrap d-flex flex-row align-items-start justify-content-between">

        <h3 class="bar-title col-10">Filtros</h3>
        <button type="button" class="navbar-toggler col-2" data-toggle="collapse" data-target=".navbar-collapse"
            (click)="collapse()">
            <i class="material-icons" style="cursor: pointer;">view_headline</i>
        </button>
    </div>
    <ul class="sidebar-nav">
        <!-- Departamentos -->
        <div>
            <li style="display: grid">
                <div #filtroDepartamentoPadre id='filtroDepartamentoPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroDepartamentoPadre)">

                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxDepartamentoPadre value="" type="checkbox" id='checkBoxDepartamentoPadre'
                                class="chkbox masterbox masterdept"
                                (click)="onCheckBoxFatherDepartamento(checkBoxDepartamentoPadre)" />
                            <label for='checkBoxDepartamentoPadre' class=""></label>
                        </div>
                        <p>Departamentos</p>
                    </div>

                </div>

            </li>

            <div #filtroDepartamentoHijo id="filtroDepartamentoHijo"
                class="toToggle checkbox chkboxdep changes changesdep" data-nombre="departamentos="
                style="display: none">
                <div id="deptbox" class="checkboxes">
                    <div class="item" *ngFor="let data of DptosArray">
                        <div class="chkbox">
                            <input #checkBoxDepartamentoHijo value='{{data.id}}' type="checkbox" id="{{data.nombre}}"
                                class="chkbox masterbox masterdept childrenDptoFilter"
                                (click)="onCheckBoxChildrenDepartamento();centrarMapa(data.nombre,checkBoxDepartamentoHijo)" />
                            <label for="{{data.nombre}}" class="dptoLabel"></label>
                        </div>
                        <p>{{data.nombre}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Localidades -->
        <div>
            <li style="display: grid">
                <div #filtroLocalidadPadre id='filtroLocalidadPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroLocalidadPadre)">
                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxLocalidadPadre value='' type="checkbox" id='checkBoxLocalidadPadre'
                                class="chkbox masterbox masterloc "
                                (click)="onCheckBoxFather(checkBoxLocalidadPadre)" />
                            <label for='checkBoxLocalidadPadre'></label>
                        </div>
                        <p>Localidades</p>
                    </div>

                </div>
            </li>

            <div #filtroLocalidadHijo id="filtroLocalidadHijo" class="toToggle checkbox chkboxloc changes"
                data-nombre="localidades=" style="display: none">
                <div id="locbox" class="checkboxes">
                    <div class="item" *ngFor="let data of arrayLocalidadFilter">
                        <div class="chkbox ">
                            <input #checkBoxLocalidadHijo value='{{data.id}}' type="checkbox" id="{{data.id}}"
                                checked="true" (click)="onCheckBoxChildren()" />
                            <label for="{{data.id}}"></label>
                        </div>
                        <p>{{data.nombre}}</p>
                    </div>
                </div>
                <p id="nodep">Seleccione un Departamento</p>
            </div>
        </div>

        <!-- Dependencia -->
        <div *ngIf="currentUser">
            <li style="display: grid">
                <div #filtroDependenciaPadre id='filtroDependenciaPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroDependenciaPadre)">

                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxDependenciaPadre value="" type="checkbox" id='checkBoxDependenciaPadre'
                                class="chkbox masterbox masterdept"
                                (click)="onCheckBoxFather(checkBoxDependenciaPadre)" />
                            <label for='checkBoxDependenciaPadre' class=""></label>
                        </div>
                        <p>Dependencia</p>
                    </div>

                </div>

            </li>

            <div #filtroDependenciaHijo id="filtroDependenciaHijo"
                class="toToggle checkbox chkboxdep changes changesdep" style="display: none">
                <div id="deptbox" class="checkboxes">
                    <div class="item" *ngFor="let data of DependenciasArray">
                        <div class="chkbox">
                            <input #checkBoxDependenciaHijo value='{{data.id}}' type="checkbox"
                                id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                            <label for="{{data.descripcion}}"></label>
                        </div>
                        <p>{{data.descripcion}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Estados -->
        <div *ngIf="currentUser">
            <li style="display: grid">
                <div #filtroEstadoPadre id='filtroEstadoPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroEstadoPadre)">

                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxEstadoPadre value=' ' type="checkbox" id='checkBoxEstadoPadre'
                                class="chkbox masterbox masterint " (click)="onCheckBoxFather(checkBoxEstadoPadre)" />
                            <label for='checkBoxEstadoPadre' class=""></label>
                        </div>
                        <p>Estado</p>
                    </div>

                </div>

            </li>

            <div #filtroEstadoHijo id="filtroEstadoHijo" class="toToggle checkbox chkboxint changes changesint"
                style="display: none">
                <div id="intbox" class="checkboxes">
                    <div class="item" *ngFor="let data of estadosArray">
                        <div class="chkbox">
                            <input #checkBoxEstadoHijo value='{{data.id}}' type="checkbox" id="{{data.descripcion}}"
                                (click)="onCheckBoxChildren()" />
                            <label for="{{data.descripcion}}"></label>
                        </div>
                        <p>{{data.descripcion}}</p>
                    </div>
                </div>

            </div>

        </div>

        <!-- Jurisdicciones -->
        <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
            <li style="display: grid">
                <div #filtroJurisdiccionPadre id='filtroJurisdiccionPadre' class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroJurisdiccionPadre)">

                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxJurisdiccionPadre value="" type="checkbox" id='checkBoxJurisdiccionPadre'
                                class="chkbox masterbox masterdept"
                                (click)="onCheckBoxFather(checkBoxJurisdiccionPadre)" />
                            <label for='checkBoxJurisdiccionPadre' class=""></label>
                        </div>
                        <p>Jurisdiccion</p>
                    </div>

                </div>

            </li>

            <div #filtroJurisdiccionHijo id="filtroJurisdiccionHijo"
                class="toToggle checkbox chkboxdep changes changesdep" style="display: none">
                <div id="deptbox" class="checkboxes">
                    <div class="item" *ngFor="let data of JurisdiccionesArray">
                        <div class="chkbox">
                            <input #checkBoxJurisdiccionHijo value='{{data.id}}' type="checkbox"
                                id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                            <label for="{{data.descripcion}}"></label>
                        </div>
                        <p>{{data.nombre}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- OrganismoDependencia -->
        <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
            <li style="display: grid">
                <div #filtroOrganismoDependenciaPadre id='filtroOrganismoDependenciaPadre'
                    class="togglers filterButton btn btn-sidebar"
                    (click)="showChildFilters(filtroOrganismoDependenciaPadre)">

                    <div class="item">
                        <div class="chkbox noprop">
                            <input #checkBoxOrganismoDependenciaPadre value="" type="checkbox"
                                id='checkBoxOrganismoDependenciaPadre' class="chkbox masterbox masterdept"
                                (click)="onCheckBoxFather(checkBoxOrganismoDependenciaPadre)" />
                            <label for='checkBoxOrganismoDependenciaPadre' class=""></label>
                        </div>
                        <p>Organismo Dependencia</p>
                    </div>

                </div>

            </li>

            <div #filtroOrganismoDependenciaHijo id="filtroOrganismoDependenciaHijo"
                class="toToggle checkbox chkboxdep changes changesdep" style="display: none">
                <div id="deptbox" class="checkboxes">
                    <div class="item" *ngFor="let data of OrganismoDependenciaArray">
                        <div class="chkbox">
                            <input #checkBoxOrganismoDependenciaHijo value='{{data.id}}' type="checkbox"
                                id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                            <label for="{{data.descripcion}}"></label>
                        </div>
                        <p>{{data.nombre}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="movingfilters">
            <div class="detachable">
                <!-- Nivel -->
                <div>
                    <li style="display: grid">
                        <div #filtroNivelPadre id='filtroNivelPadre' class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroNivelPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxNivelPadre value='' type="checkbox" id='checkBoxNivelPadre'
                                        class="chkbox masterbox " (click)="onCheckBoxFather(checkBoxNivelPadre)" />
                                    <label for='checkBoxNivelPadre'></label>
                                </div>
                                <p>Nivel</p>
                            </div>
                        </div>
                    </li>

                    <div #filtroNivelHijo id="filtroNivelHijo" class="toToggle checkbox chkboxofer changes"
                        data-nombre="ofertas=" style="display: none">

                        <div id="oferbox" class="checkboxes">
                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxNivelHijo value="152,155,121,122,100,101" class="ofer"
                                        type="checkbox" id="inicial" (click)="onCheckBoxChildren()" />
                                    <label for="inicial"></label>
                                </div>
                                <p>Inicial</p>
                            </div>

                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxNivelHijo value="136,123,126,140,102,105,153,156,158" class="ofer"
                                        type="checkbox" id="primaria" (click)="onCheckBoxChildren()" />
                                    <label for="primaria"></label>
                                </div>
                                <p>Primaria</p>
                            </div>

                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxNivelHijo
                                        value="143,144,108,109,110,111,129,130,147,148,151,154,157,159,131,132,149,150,138"
                                        class="ofer" type="checkbox" id="secundaria" (click)="onCheckBoxChildren()" />
                                    <label for="secundaria"></label>
                                </div>
                                <p>Secundaria</p>
                            </div>

                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxNivelHijo value="117,115" class="ofer" type="checkbox" id="superior"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="superior"></label>
                                </div>
                                <p>Superior</p>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Modalidad -->
                <div>
                    <li style="display: grid">
                        <div #filtroModalidadPadre id='filtroModalidadPadre'
                            class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroModalidadPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxModalidadPadre value=' ' type="checkbox" id='checkBoxModalidadPadre'
                                        class="chkbox masterbox " (click)="onCheckBoxFather(checkBoxModalidadPadre)" />
                                    <label for='checkBoxModalidadPadre'></label>
                                </div>
                                <p>Modalidad</p>
                            </div>
                        </div>
                    </li>

                    <div #filtroModalidadHijo id="filtroModalidadHijo" class="toToggle checkbox chkboxmod changes"
                        data-nombre="modalidades=" style="display: none">
                        <div id="modbox" class=" modbox checkboxes">
                            <div class="item" *ngFor="let data of modalityArray">
                                <div class="chkbox ">
                                    <input #checkBoxModalidadHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>


                    </div>
                </div>
                <!-- Gestion -->
                <div>
                    <li style="display: grid">

                        <div #filtroGestionPadre id='filtroGestionPadre' class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroGestionPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxGestionPadre value='' type="checkbox" id='checkBoxGestionPadre'
                                        class="chkbox masterbox" (click)="onCheckBoxFather(checkBoxGestionPadre)" />
                                    <label for='checkBoxGestionPadre'></label>
                                </div>
                                <p>Gestión</p>

                            </div>
                        </div>
                    </li>

                    <div #filtroGestionHijo id="filtroGestionHijo" class="toToggle checkbox chkboxsec changes"
                        data-nombre="sectores=" style="display: none">
                        <div id="secbox" class=" secbox checkboxes">
                            <div class="item" *ngFor="let data of sectorArray">
                                <div class="chkbox">
                                    <input #checkBoxGestionHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Ambito -->
                <div>
                    <li style="display: grid">
                        <div #filtroAmbitoPadre id='filtroAmbitoPadre' class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroAmbitoPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxAmbitoPadre value=' ' type="checkbox" id='checkBoxAmbitoPadre'
                                        class="chkbox masterbox " (click)="onCheckBoxFather(checkBoxAmbitoPadre)" />
                                    <label for='checkBoxAmbitoPadre'></label>
                                </div>
                                <p>Ámbito</p>
                            </div>
                        </div>
                    </li>

                    <div #filtroAmbitoHijo id="filtroAmbitoHijo" class="toToggle checkbox chkboxamb changes"
                        data-nombre="ambitos=" style="display: none">
                        <div id="ambbox" class="ambbox checkboxes">
                            <div class="item" *ngFor="let data of ambitArray">
                                <div class="chkbox">
                                    <input #checkBoxAmbitoHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                    <li style="display: grid">
                        <div id='btnAdm' class=" filterButton btn btn-sidebar">
                            <div class="item changes" data-nombre="adm=">
                                <div class="chkbox noprop checkboxes">
                                    <input #checkBoxADMPadre value='1' type="checkbox" id='checkBoxADMPadre'
                                        class="chkbox masterbox " (click)="onCheckBoxFather(checkBoxADMPadre)" />
                                    <label for='checkBoxADMPadre' class=""></label>
                                </div>
                                <p>Aula Digital Móvil</p>
                            </div>
                        </div>
                    </li>
                </div>

                <div *ngIf="currentUser && currentUser.rol == 'ADMIN' || currentUser && currentUser.rol == 'INTERNET'">
                    <li style="display: grid">
                        <div #filtroInternetPadre id='filtroInternetPadre' class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroInternetPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxInternetPadre value=' ' type="checkbox" id='checkBoxInternetPadre'
                                        class="chkbox masterbox masterint "
                                        (click)="onCheckBoxFather(checkBoxInternetPadre)" />
                                    <label for='checkBoxInternetPadre' class=""></label>
                                </div>
                                <p>Internet</p>
                            </div>
                        </div>
                    </li>
                    <div #filtroInternetHijo id="filtroInternetHijo"
                        class="toToggle checkbox chkboxint changes changesint" data-nombre="tieneInternet="
                        style="display: none">
                        <div id="intbox" class="checkboxes">

                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxInternetHijo value="1" type="checkbox" id="coninternet"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="coninternet"></label>
                                </div>
                                <p>Tiene</p>
                            </div>
                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxInternetHijo value="0" type="checkbox" id="sininternet"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="sininternet"></label>
                                </div>
                                <p>No tiene</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                    <li style="display: grid">
                        <div #filtroLineaCPadre id='filtroLineaCPadre' class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroLineaCPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxLineaCPadre value=' ' type="checkbox" id='checkBoxLineaCPadre'
                                        class="chkbox masterbox masterint "
                                        (click)="onCheckBoxFather(checkBoxLineaCPadre)" />
                                    <label for='checkBoxLineaCPadre' class=""></label>
                                </div>
                                <p>Plan de Conectividad</p>
                            </div>
                        </div>
                    </li>
                    <div #filtroLineaCHijo id="filtroLineaCHijo"
                        class="toToggle checkbox chkboxint changes changesint" data-nombre="tieneLineaC="
                        style="display: none">
                        <div id="intbox" class="checkboxes">

                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxLineaCHijo value="1" type="checkbox" id="conLineaC"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="conLineaC"></label>
                                </div>
                                <p>Tiene línea C</p>
                            </div>
                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxLineaCHijo value="0" type="checkbox" id="sinLineaC"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="sinLineaC"></label>
                                </div>
                                <p>No tiene línea C</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                    <li style="display: grid">
                        <div #filtroAguaPotablePadre id='filtroAguaPotablePadre'
                            class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroAguaPotablePadre)">

                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxAguaPotablePadre value=' ' type="checkbox"
                                        id='checkBoxAguaPotablePadre' class="chkbox masterbox masterint "
                                        (click)="onCheckBoxFather(checkBoxAguaPotablePadre)" />
                                    <label for='checkBoxAguaPotablePadre' class=""></label>
                                </div>
                                <p>Agua Potable</p>
                            </div>
                        </div>
                    </li>
                    <div #filtroAguaPotableHijo id="filtroAguaPotableHijo"
                        class="toToggle checkbox chkboxint changes changesint" data-nombre="tieneAguaPotable="
                        style="display: none">
                        <div id="intbox" class="checkboxes">
                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxAguaPotableHijo value="1" type="checkbox" id="conAguaPotable"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="conAguaPotable"></label>
                                </div>
                                <p>Tiene</p>
                            </div>
                            <div class="item">
                                <div class="chkbox">
                                    <input #checkBoxAguaPotableHijo value="0" type="checkbox" id="sinAguaPotable"
                                        (click)="onCheckBoxChildren()" />
                                    <label for="sinAguaPotable"></label>
                                </div>
                                <p>No tiene</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                    <li style="display: grid">

                        <div #filtroProveedoresPadre id='filtroProveedoresPadre'
                            class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroProveedoresPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxProveedoresPadre value=' ' type="checkbox"
                                        id='checkBoxProveedoresPadre' class="chkbox masterbox"
                                        (click)="onCheckBoxFather(checkBoxProveedoresPadre)" />
                                    <label for='checkBoxProveedoresPadre'></label>
                                </div>
                                <p>Proveedores</p>

                            </div>
                        </div>
                    </li>

                    <div #filtroProveedoresHijo id="filtroProveedoresHijo" class="toToggle checkbox changes chkboxprov"
                        data-nombre="proveedores=" style="display: none">

                        <div id="provbox" class=" secbox checkboxes">
                            <div class="item" *ngFor="let data of providers">
                                <div class="chkbox ">
                                    <input #checkBoxProveedoresHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">

                    <!-- AGRUPACIONES -->
                    <li style="display: grid">

                        <div #filtroAgrupacionesPadre id='filtroAgrupacionesPadre'
                            class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroAgrupacionesPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxAgrupacionesPadre value='' type="checkbox"
                                        id='checkBoxAgrupacionesPadre' class="chkbox masterbox"
                                        (click)="onCheckBoxFather(checkBoxAgrupacionesPadre)" />
                                    <label for='checkBoxAgrupacionesPadre'></label>
                                </div>
                                <p>Agrupaciones</p>

                            </div>
                        </div>
                    </li>

                    <div #filtroAgrupacionesHijo id="filtroAgrupacionesHijo"
                        class="toToggle checkbox changes chkboxagrup" data-nombre="agrupaciones=" style="display: none">

                        <div id="agrupbox" class=" secbox checkboxes">
                            <div class="item" *ngFor="let data of agrupaciones">
                                <div class="chkbox ">
                                    <input #checkBoxAgrupacionesHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="currentUser && currentUser.rol == 'ADMIN'">

                    <!-- CABECERAS -->
                    <li style="display: grid">

                        <div #filtroCabecerasPadre id='filtroCabecerasPadre'
                            class="togglers filterButton btn btn-sidebar"
                            (click)="showChildFilters(filtroCabecerasPadre)">
                            <div class="item">
                                <div class="chkbox noprop">
                                    <input #checkBoxCabecerasPadre value='' type="checkbox"
                                        id='checkBoxCabecerasPadre' class="chkbox masterbox"
                                        (click)="onCheckBoxFather(checkBoxCabecerasPadre)" />
                                    <label for='checkBoxCabecerasPadre'></label>
                                </div>
                                <p>Cabeceras</p>

                            </div>
                        </div>
                    </li>

                    <div #filtroCabecerasHijo id="filtroCabecerasHijo"
                        class="toToggle checkbox changes chkboxagrup" data-nombre="cabeceras=" style="display: none">

                        <div id="agrupbox" class=" secbox checkboxes">
                            <div class="item" *ngFor="let data of cabeceras">
                                <div class="chkbox ">
                                    <input #checkBoxCabecerasHijo value='{{data.id}}' type="checkbox"
                                        id="{{data.descripcion}}" (click)="onCheckBoxChildren()" />
                                    <label for="{{data.descripcion}}"></label>
                                </div>
                                <p>{{data.descripcion}}</p>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="row buttons" *ngIf="currentUser">

                    <div class="col-xl-6 p-0">
                        <button class="showInternetIcon btn btn-main green" (click)="btnConnectInternet()">
                            <div class="btn-icon" style="justify-content: center;">
                                <i *ngIf="botonesCheck[0]" class="material-icons">done</i>
                            </div>
                            <p class="btn-content m-0 p-2">
                                Ver mapa de conectividad
                            </p>
                        </button>
                    </div>

                    <div class="col-xl-6 p-0" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                        <button class="markerByProv btn btn-main green" (click)="btnProviderInternet()">
                            <div class="btn-icon" style="justify-content: center;">
                                <i *ngIf="botonesCheck[1]" class="material-icons">done</i>
                            </div>
                            <p class="btn-content m-0 p-2">
                                Ver mapa de proveedores
                            </p>
                        </button>
                    </div>

                    <div class="col-xl-6 p-0" *ngIf="currentUser && currentUser.rol == 'ADMIN' ">
                        <button class="markerByProv btn btn-main green" (click)="matriculaMenorA()">
                            <div class="btn-icon" style="justify-content: center;">
                                <i *ngIf="botonesCheck[2]" class="material-icons">done</i>
                            </div>
                            <p class="btn-content m-0 p-2">
                                Escuelas con matricula menor a
                                <input [(ngModel)]="matriculaCantidad"
                                    style="width: 35%;height: 30%;text-align: center;"
                                    (click)="$event.stopPropagation()">
                            </p>
                        </button>
                    </div>

                    <div class="col-xl-6 p-0" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
                        <button class="markerByProv btn btn-main green"
                            (click)="this.botonesCheck[3]= !this.botonesCheck[3];filtrar()">
                            <div class="btn-icon" style="justify-content: center;">
                                <i *ngIf="botonesCheck[3]" class="material-icons">done</i>
                            </div>
                            <p class="btn-content m-0 p-2">
                                Escuelas sin georeferencia
                            </p>
                        </button>
                    </div>
                    <div class="col-xl-6 p-0 " id="btnRaGe" style="display: none" (click)="btnRAGE()">
                        <button class="markerByProv btn btn-main green">
                            <div class="btn-icon" style="justify-content: center;">
                                <i *ngIf="botonesCheck[1]" class="material-icons">done</i>
                            </div>
                            <p class="btn-content m-0 p-2">
                                RA-GE
                            </p>
                        </button>
                    </div>

                    <div style="display:none" class="range-slider" id="inputRadio">
                        <input (change)="distanciaEscuelas()" class="range-slider__range" type="range" list="tickmarks"
                            id="Sliderdist" value="5" min="0" max="20" step="1">
                        <span class="range-slider__value">
                            <b id="range">{{distanciaEscuelasMatriculas}}</b> km</span>
                    </div>





                </div>



            </div>
            <!--fin detachable -->
        </div>
        <!--fin moving filters -->

        <!-- terminan los filtros -->
    </ul>

</div>