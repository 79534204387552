import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { RequestsApiService } from "../../services/requestsApi.service";
import { ComponentsTalkService } from "src/app/services/components-talk.service";
import { AuthService } from "src/app/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { IfStmt } from "@angular/compiler";
import { element } from "protractor";

declare var require: any;
const dptoLocation = require("../../data/departaments-latlng.json");
declare let google: any;
const LineaC = require("../../data/lineaC.json");

//variable for jquery
declare var $: any;

@Component({
  selector: "app-filtros",
  templateUrl: "./filtros.component.html",
  styleUrls: ["./filtros.component.scss"],
  providers: [RequestsApiService],
})
export class FiltrosComponent implements OnInit {
  //ArrayFiltered
  filteredIdArray = [];
  filteredArray = [];
  //array that contains the datas of the request apis
  localizacionesArray: any;
  DptosArray: any;
  DependenciasArray: any;
  estadosArray: any;
  JurisdiccionesArray: any;
  OrganismoDependenciaArray: any;
  localidadesArray: any;
  modalityArray: any;
  sectorArray: any;
  ambitArray: any;
  arrayLocalidadFilter: any = [];
  arrayLocationFilter: any = [];
  allLevelInitialSchool: any;
  allLevelPrimarySchool: any;
  allLevelSecondarySchool: any;
  allLevelHigherSchool: any;
  providers: any;
  escuelaMatriculaMenorAcinco: any;
  distanciaEscuelasMatriculas: any;
  currentUser;
  markerRaGe: any;
  aguaPotable: any;
  agrupaciones = [
    {
      id: "efas",
      descripcion: "EFAS",
    },
  ];
  cabeceras = [
    {
      id: "primariasCabecera",
      descripcion: "PRIMARIAS",
    },
  ];
  cabecerasPrimaria: any;
  map: google.maps.Map;

  //Elements
  filtrosDepartamentoHijo = false;
  filtroLocalidadHijo = false;
  filtroDependencia = false;
  filtroEstado = false;
  filtroJurisdiccion = false;
  filtroOrganismoDependecia = false;
  filtroNivelHijo = false;
  filtroModalidadHijo = false;
  filtroGestionHijo = false;
  filtroAmbitoHijo = false;
  filtroInternetHijo = false;
  filtroLineaCHijo = false;
  filtroProveedoresHijo = false;
  filtroAgrupacionesHijo = false;
  filtroCabecerasHijo = false;
  filtroAguaPotableHijo = false;

  botonesCheck = [false, false, false, false];
  matriculaCantidad = 5;

  //collapse toggle
  collapsed = false;

  constructor(
    private apiService: RequestsApiService,
    private authService: AuthService,
    private talkService: ComponentsTalkService
  ) {
    $("body").on("click", ".dptoLabel", this.helperToLoations);
  }

  helperToLoations = () => {
    // setTimeout(() => {
    //   this.onCheckBoxChildren()
    // }, 100);
  };

  collapse() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      $("#sidebar-filters").removeClass("col-md-3");
      $("#sidebar-filters").addClass("collapsed col-md-1");
    } else {
      $("#sidebar-filters").addClass("col-md-3");
      $("#sidebar-filters").removeClass("collapsed");
    }
  }

  centrarMapa = (dpto, checkbox) => {
    if (checkbox.checked) {
      this.talkService.map.setZoom(10);
      this.talkService.map.setCenter(
        new google.maps.LatLng(
          dptoLocation[dpto].lat,
          dptoLocation[dpto].lng - 0.5
        )
      );
    }
  };

  ngOnInit() {
    this.talkService.ObservableMarkerSelected.subscribe((data) => {
      $("#btnRaGe").css("display", "block");
      this.markerRaGe = data;
    });

    this.talkService.ObservableEscuelaIdMenor5.subscribe((data) => {
      this.escuelaMatriculaMenorAcinco = data;
      $("#inputRadio").css("display", "block");
      this.distanciaEscuelasMatriculas = 5;
      $("#Sliderdist").val(5);
      this.botonesCheck[2] = true;
    });

    //Check Current User
    this.authService.currentUser.subscribe((data) => {
      this.currentUser = data;
    });

    //
    this.talkService.ObservableNotifyBtnSearch.subscribe((data) => {
      //this.deselctedInputCheck();
    });

    //stores in the DptosArray the departaments brough from the api
    this.apiService.getLocalizacion().subscribe((locations) => {
      this.localizacionesArray = locations;
      this.talkService.sendLoader(false);
    });

    //stores in the DptosArray the departaments brough from the api
    this.apiService.getDptos().subscribe((data) => {
      this.DptosArray = data;
    });

    //stores in the localidadesArray the locations brough from the api
    this.apiService.getLocalidad().subscribe((data) => {
      this.localidadesArray = data;
    });

    //stores in the modalityArray the modality brough from the api
    this.apiService.getModality().subscribe((data) => {
      this.modalityArray = data;
    });

    //stores in the sectorArray the sector brough from the api
    this.apiService.getSector().subscribe((data) => {
      this.sectorArray = data;
    });

    //stores in the ambitArray the ambit brough from the api
    this.apiService.getAmbit().subscribe((data) => {
      this.ambitArray = data;
    });

    //stores in the allLevelInitialSchool the school of level initial brough from the api
    this.apiService.getLevelInitial().subscribe((data) => {
      this.allLevelInitialSchool = data;
    });

    //stores in the allLevelPrimarySchool the schools of levels Primaries brough from the api
    this.apiService.getLevelPrimary().subscribe((data) => {
      this.allLevelPrimarySchool = data;
    });

    //stores in the allLevelSecondarySchool the school of levels Secondaries brough from the api
    this.apiService.getLevelSecondary().subscribe((data) => {
      this.allLevelSecondarySchool = data;
    });

    //stores in the allLevelInitialSchool the school of levels higher brough from the api
    this.apiService.getLevelHigher().subscribe((data) => {
      this.allLevelHigherSchool = data;
    });

    //stores in the providers all providers brough from the api
    this.apiService.getProviders().subscribe((data) => {
      this.providers = data;
    });

    //stores in dependenciaArray all Dependencais
    this.apiService.getDependencia().subscribe((data) => {
      this.DependenciasArray = data;
    });

    //stores in estadoArray all Estados
    this.apiService.getEstado().subscribe((data) => {
      this.estadosArray = data;
    });

    //stores in jurisdiccionesArray all Jurisdicciones
    this.apiService.getJurisdicciones().subscribe((data) => {
      this.JurisdiccionesArray = data;
    });

    //stores in estadoArray all Estados
    this.apiService.getOrganismoDependencia().subscribe((data) => {
      this.OrganismoDependenciaArray = data;
    });

    this.apiService.getAguaPotable().subscribe((data) => {
      this.aguaPotable = data;
    });

    this.cabecerasPrimaria = this.apiService.getCabecerasPrimaria();
  } //END ngOnInit()-------------------------------------------------------------------------------------------------------

  showChildFilters = (element: any) => {
    console.log(element);
    if (element.id == "filtroDepartamentoPadre") {
      this.filtrosDepartamentoHijo = !this.filtrosDepartamentoHijo;
      this.filtrosDepartamentoHijo
        ? $("#filtroDepartamentoHijo").show()
        : $("#filtroDepartamentoHijo").hide();
    }

    if (element.id == "filtroLocalidadPadre") {
      this.filtroLocalidadHijo = !this.filtroLocalidadHijo;
      this.filtroLocalidadHijo
        ? $("#filtroLocalidadHijo").show()
        : $("#filtroLocalidadHijo").hide();
    }

    if (element.id == "filtroDependenciaPadre") {
      this.filtroDependencia = !this.filtroDependencia;
      this.filtroDependencia
        ? $("#filtroDependenciaHijo").show()
        : $("#filtroDependenciaHijo").hide();
    }

    if (element.id == "filtroEstadoPadre") {
      this.filtroEstado = !this.filtroEstado;
      this.filtroEstado
        ? $("#filtroEstadoHijo").show()
        : $("#filtroEstadoHijo").hide();
    }

    if (element.id == "filtroJurisdiccionPadre") {
      this.filtroJurisdiccion = !this.filtroJurisdiccion;
      this.filtroJurisdiccion
        ? $("#filtroJurisdiccionHijo").show()
        : $("#filtroJurisdiccionHijo").hide();
    }

    if (element.id == "filtroOrganismoDependenciaPadre") {
      this.filtroOrganismoDependecia = !this.filtroOrganismoDependecia;
      this.filtroOrganismoDependecia
        ? $("#filtroOrganismoDependenciaHijo").show()
        : $("#filtroOrganismoDependenciaHijo").hide();
    }

    if (element.id == "filtroNivelPadre") {
      this.filtroNivelHijo = !this.filtroNivelHijo;
      this.filtroNivelHijo
        ? $("#filtroNivelHijo").show()
        : $("#filtroNivelHijo").hide();
    }

    if (element.id == "filtroModalidadPadre") {
      this.filtroModalidadHijo = !this.filtroModalidadHijo;
      this.filtroModalidadHijo
        ? $("#filtroModalidadHijo").show()
        : $("#filtroModalidadHijo").hide();
    }

    if (element.id == "filtroGestionPadre") {
      this.filtroGestionHijo = !this.filtroGestionHijo;
      this.filtroGestionHijo
        ? $("#filtroGestionHijo").show()
        : $("#filtroGestionHijo").hide();
    }

    if (element.id == "filtroAmbitoPadre") {
      this.filtroAmbitoHijo = !this.filtroAmbitoHijo;
      this.filtroAmbitoHijo
        ? $("#filtroAmbitoHijo").show()
        : $("#filtroAmbitoHijo").hide();
    }

    if (element.id == "filtroInternetPadre") {
      this.filtroInternetHijo = !this.filtroInternetHijo;
      this.filtroInternetHijo
        ? $("#filtroInternetHijo").show()
        : $("#filtroInternetHijo").hide();
    }

    if (element.id == "filtroLineaCPadre") {
      this.filtroLineaCHijo = !this.filtroLineaCHijo;
      this.filtroLineaCHijo
        ? $("#filtroLineaCHijo").show()
        : $("#filtroLineaCHijo").hide();
    }

    if (element.id == "filtroAguaPotablePadre") {
      this.filtroAguaPotableHijo = !this.filtroAguaPotableHijo;
      this.filtroAguaPotableHijo
        ? $("#filtroAguaPotableHijo").show()
        : $("#filtroAguaPotableHijo").hide();
    }

    if (element.id == "filtroProveedoresPadre") {
      this.filtroProveedoresHijo = !this.filtroProveedoresHijo;
      this.filtroProveedoresHijo
        ? $("#filtroProveedoresHijo").show()
        : $("#filtroProveedoresHijo").hide();
    }

    if (element.id == "filtroAgrupacionesPadre") {
      this.filtroAgrupacionesHijo = !this.filtroAgrupacionesHijo;
      this.filtroAgrupacionesHijo
        ? $("#filtroAgrupacionesHijo").show()
        : $("#filtroAgrupacionesHijo").hide();
    }

    if (element.id == "filtroCabecerasPadre") {
      this.filtroCabecerasHijo = !this.filtroCabecerasHijo;
      this.filtroCabecerasHijo
        ? $("#filtroCabecerasHijo").show()
        : $("#filtroCabecerasHijo").hide();
    }
  };

  onCheckBoxFatherDepartamento = (element: any) => {
    if (element.id == "checkBoxDepartamentoPadre") {
      if (element.checked) {
        $("#filtroDepartamentoHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
        this.onCheckBoxChildren();
      } else {
        this.arrayLocalidadFilter = [];
        $("#filtroDepartamentoHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }
    this.onCheckBoxChildrenDepartamento();
  };

  onCheckBoxFather = (element: any) => {
    if (element.id == "checkBoxLocalidadPadre") {
      if (element.checked) {
        $("#filtroLocalidadHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroLocalidadHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxJurisdiccionPadre") {
      if (element.checked) {
        $("#filtroJurisdiccionHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroJurisdiccionHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }
    if (element.id == "checkBoxDependenciaPadre") {
      if (element.checked) {
        $("#filtroDependenciaHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroDependenciaHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxEstadoPadre") {
      if (element.checked) {
        $("#filtroEstadoHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroEstadoHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    //FALTA JURISDICCION

    if (element.id == "checkBoxOrganismoDependenciaPadre") {
      if (element.checked) {
        $("#filtroOrganismoDependenciaHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroOrganismoDependenciaHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxNivelPadre") {
      if (element.checked) {
        $("#filtroNivelHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroNivelHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxModalidadPadre") {
      if (element.checked) {
        $("#filtroModalidadHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroModalidadHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxGestionPadre") {
      if (element.checked) {
        $("#filtroGestionHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroGestionHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxAmbitoPadre") {
      if (element.checked) {
        $("#filtroAmbitoHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroAmbitoHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxInternetPadre") {
      if (element.checked) {
        $("#filtroInternetHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroInternetHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxLineaCPadre") {
      if (element.checked) {
        $("#filtroLineaCHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroLineaCHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxAguaPotablePadre") {
      if (element.checked) {
        $("#filtroAguaPotableHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroAguaPotableHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxProveedoresPadre") {
      if (element.checked) {
        $("#filtroProveedoresHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroProveedoresHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxAgrupacionesPadre") {
      if (element.checked) {
        $("#filtroAgrupacionesHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroAgrupacionesHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    if (element.id == "checkBoxCabecerasPadre") {
      if (element.checked) {
        $("#filtroCabecerasHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroCabecerasHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
      }
    }

    //Filtrar
    this.filtrar();
  };

  //Funcion solo para departamento (Fix de tiempo para lectura de localidades con JQuery)
  onCheckBoxChildrenDepartamento = () => {
    //Restart Arr of Loc
    this.arrayLocalidadFilter = [];
    let departamentoPadre = [false];
    $("#filtroDepartamentoHijo input:checked")
      .each((i, item) => {
        if (i >= 0) {
          departamentoPadre[0] = true;
        }
      })
      .promise()
      .done((e) => {
        //Rellena Arr of Loc
        if (e.length != 0) {
          this.localidadesArray.map((locItem) => {
            for (let i = 0; i < e.length; i++) {
              if (parseInt(e[i].value) === locItem.departamento.id) {
                if (!this.arrayLocalidadFilter.includes(locItem))
                  this.arrayLocalidadFilter.push(locItem);
              }
            }
          });
          $("#filtroDependenciaHijo input").each((i, item) => {
            if (item.id == "Provincial") $(item).prop("checked", true);
          });
          $("#filtroEstadoHijo input").each((i, item) => {
            if (item.id == "Activo") $(item).prop("checked", true);
          });
        }
      });
    if (departamentoPadre[0] /*|| departamentoPadre[1]*/) {
      $("#checkBoxDepartamentoPadre").prop("checked", true);
    } else {
      $("#checkBoxDepartamentoPadre").prop("checked", false);
    }
    setTimeout(() => {
      this.onCheckBoxChildren();
    }, 100);
  };

  onCheckBoxChildren = () => {
    //Cantidad de Elements
    let localidadPadre = [
      false /*Si tiene mas de un elemnto se rellena con booleanos este arreglo*/,
    ];
    let dependenciaPadre = [false];
    let estadoPadre = [false];
    let jurisdiccionPadre = [false];
    let organismoDependenciaPadre = [false];
    let nivelPadre = [false];
    let modalidadPadre = [false];
    let gestionPadre = [false];
    let ambitoPadre = [false];
    let internetPadre = [false];
    let lineaCPadre = [false];
    let aguaPotablePadre = [false];
    let proveedoresPadre = [false];
    let agrupacionesPadre = [false];
    let cabecerasPadre = [false];

    //Si hay un hijo checked cambia estado true
    $("#filtroLocalidadHijo input:checked").each((i, item) => {
      if (i >= 0) localidadPadre[0] = true;
    });

    $("#filtroDependenciaHijo input:checked").each((i, elem) => {
      if (i >= 0) dependenciaPadre[0] = true;
    });

    $("#filtroEstadoHijo input:checked").each((i, elem) => {
      if (i >= 0) estadoPadre[0] = true;
    });

    $("#filtroJurisdiccionHijo input:checked").each((i, elem) => {
      if (i >= 0) jurisdiccionPadre[0] = true;
    });

    $("#filtroOrganismoDependenciaHijo input:checked").each((i, elem) => {
      if (i >= 0) organismoDependenciaPadre[0] = true;
    });

    $("#filtroNivelHijo input:checked").each((i, item) => {
      if (i >= 0) nivelPadre[0] = true;
    });

    $("#filtroModalidadHijo input:checked").each((i, item) => {
      if (i >= 0) modalidadPadre[0] = true;
    });

    $("#filtroGestionHijo input:checked").each((i, item) => {
      if (i >= 0) gestionPadre[0] = true;
    });

    $("#filtroAmbitoHijo input:checked").each((i, item) => {
      if (i >= 0) ambitoPadre[0] = true;
    });

    $("#filtroInternetHijo input:checked").each((i, item) => {
      if (i >= 0) internetPadre[0] = true;
    });

    $("#filtroLineaCHijo input:checked").each((i, item) => {
      if (i >= 0) lineaCPadre[0] = true;
    });

    $("#filtroAguaPotableHijo input:checked").each((i, item) => {
      if (i >= 0) aguaPotablePadre[0] = true;
    });

    $("#filtroProveedoresHijo input:checked").each((i, item) => {
      if (i >= 0) proveedoresPadre[0] = true;
    });

    $("#filtroAgrupacionesHijo input:checked").each((i, item) => {
      if (i >= 0) agrupacionesPadre[0] = true;
    });

    $("#filtroCabecerasHijo input:checked").each((i, item) => {
      if (i >= 0) cabecerasPadre[0] = true;
    });

    //Si hay un hijo de cualquier elemento checked marca padre checked
    if (localidadPadre[0]) {
      $("#checkBoxLocalidadPadre").prop("checked", true);
    } else {
      $("#checkBoxLocalidadPadre").prop("checked", false);
    }

    if (dependenciaPadre[0]) {
      $("#checkBoxDependenciaPadre").prop("checked", true);
    } else {
      $("#checkBoxDependenciaPadre").prop("checked", false);
    }

    if (estadoPadre[0]) {
      $("#checkBoxEstadoPadre").prop("checked", true);
    } else {
      $("#checkBoxEstadoPadre").prop("checked", false);
    }

    if (jurisdiccionPadre[0]) {
      $("#checkBoxJurisdiccionPadre").prop("checked", true);
    } else {
      $("#checkBoxJurisdiccionPadre").prop("checked", false);
    }

    if (organismoDependenciaPadre[0]) {
      $("#checkBoxOrganismoDependenciaPadre").prop("checked", true);
    } else {
      $("#checkBoxOrganismoDependenciaPadre").prop("checked", false);
    }

    if (nivelPadre[0]) {
      $("#checkBoxNivelPadre").prop("checked", true);
    } else {
      $("#checkBoxNivelPadre").prop("checked", false);
    }

    if (modalidadPadre[0]) {
      $("#checkBoxModalidadPadre").prop("checked", true);
    } else {
      $("#checkBoxModalidadPadre").prop("checked", false);
    }

    if (gestionPadre[0]) {
      $("#checkBoxGestionPadre").prop("checked", true);
    } else {
      $("#checkBoxGestionPadre").prop("checked", false);
    }

    if (ambitoPadre[0]) {
      $("#checkBoxAmbitoPadre").prop("checked", true);
    } else {
      $("#checkBoxAmbitoPadre").prop("checked", false);
    }

    if (internetPadre[0]) {
      $("#checkBoxInternetPadre").prop("checked", true);
    } else {
      $("#checkBoxInternetPadre").prop("checked", false);
    }

    if (lineaCPadre[0]) {
      $("#checkBoxLineaCPadre").prop("checked", true);
    } else {
      $("#checkBoxLineaCPadre").prop("checked", false);
    }

    if (aguaPotablePadre[0]) {
      $("#checkBoxAguaPotablePadre").prop("checked", true);
    } else {
      $("#checkBoxAguaPotablePadre").prop("checked", false);
    }

    if (proveedoresPadre[0]) {
      $("#checkBoxProveedoresPadre").prop("checked", true);
    } else {
      $("#checkBoxProveedoresPadre").prop("checked", false);
    }

    if (agrupacionesPadre[0]) {
      $("#checkBoxAgrupacionesPadre").prop("checked", true);
    } else {
      $("#checkBoxAgrupacionesPadre").prop("checked", false);
    }

    if (cabecerasPadre[0]) {
      $("#checkBoxCabecerasPadre").prop("checked", true);
    } else {
      $("#checkBoxCabecerasPadre").prop("checked", false);
    }

    //Filtrar
    this.filtrar();
  };

  filtrarPorLocalidad = () => {
    $("#filtroLocalidadHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e) => {
        if (e.length != 0) {
          this.localizacionesArray.map((ele: any) => {
            for (let i = 0; i < e.length; i++) {
              if (ele.domicilio && e[i].value == ele.domicilio.localidad.id) {
                if (!this.filteredIdArray.includes(ele.id)) {
                  this.filteredIdArray.push(ele.id);
                }
              }
            }
          });
        }
      });
  };

  filtrarPorDependencia = () => {
    $("#filtroDependenciaHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e) => {
        let filteredDependencia = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                loc.establecimiento &&
                loc.establecimiento.dependencia &&
                e[i].id == loc.establecimiento.dependencia.descripcion &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredDependencia.includes(loc.id))
                  filteredDependencia.push(loc.id);
              }
            }
          });
        }
        this.filteredIdArray = filteredDependencia;
      });
  };

  filtrarPorEstado = () => {
    $("#filtroEstadoHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e) => {
        let filteredEstado = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                loc.estado &&
                loc.estado.descripcion &&
                e[i].id == loc.estado.descripcion &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredEstado.includes(loc.id))
                  filteredEstado.push(loc.id);
              }
            }
          });
        }
        this.filteredIdArray = filteredEstado;
      });
  };

  filtrarPorJurisdiccion = () => {
    $("#filtroJurisdiccionHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredJurisdiccion = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                e[i].id == loc.jurisdiccion &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredJurisdiccion.includes(loc.id))
                  filteredJurisdiccion.push(loc.id);
              }
            }
          });
          this.filteredIdArray = filteredJurisdiccion;
        }
      });
  };

  filtrarPorOrganismoDependencia = () => {
    $("#filtroOrganismoDependenciaHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredOrganismoDependencia = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                e[i].id == loc.niveljur &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredOrganismoDependencia.includes(loc.id))
                  filteredOrganismoDependencia.push(loc.id);
              }
            }
          });
          this.filteredIdArray = filteredOrganismoDependencia;
        }
      });
  };

  filtrarPorNivel = () => {
    let nivelSelected = [];
    $("#filtroNivelHijo input:checked")
      .each((i, item) => {
        if (item.id == "inicial") {
          nivelSelected[0] = true;
        }
        if (item.id == "primaria") {
          nivelSelected[1] = true;
        }
        if (item.id == "secundaria") {
          nivelSelected[2] = true;
        }
        if (item.id == "superior") {
          nivelSelected[3] = true;
        }
      })
      .promise()
      .done((e) => {
        let filteredNivel = [];
        if (e.length != 0) {
          this.filteredIdArray.map((id: any) => {
            if (nivelSelected[0]) {
              this.allLevelInitialSchool.map((inicial) => {
                if (id == inicial.id) {
                  if (!filteredNivel.includes(id)) filteredNivel.push(id);
                }
              });
            }
            if (nivelSelected[1]) {
              this.allLevelPrimarySchool.map((primaria: any) => {
                if (id == primaria.id) {
                  if (!filteredNivel.includes(id)) filteredNivel.push(id);
                }
              });
            }
            if (nivelSelected[2]) {
              this.allLevelSecondarySchool.map((secundaria: any) => {
                if (id == secundaria.id) {
                  if (!filteredNivel.includes(id)) filteredNivel.push(id);
                }
              });
            }
            if (nivelSelected[3]) {
              this.allLevelHigherSchool.map((mayor: any) => {
                if (id == mayor.id) {
                  if (!filteredNivel.includes(id)) filteredNivel.push(id);
                }
              });
            }
          });
          this.filteredIdArray = filteredNivel;
        }
      });
  };

  filtrarPorModalidad = () => {
    let modalidadSelected = [];
    $("#filtroModalidadHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredModalidad = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            if (Array.isArray(loc.ofertas)) {
              loc.ofertas.map((ofertas: any) => {
                for (let i = 0; i < e.length; i++) {
                  if (
                    e[i].id == ofertas.modalidad.descripcion &&
                    this.filteredIdArray.includes(loc.id)
                  ) {
                    if (!filteredModalidad.includes(loc.id))
                      filteredModalidad.push(loc.id);
                  }
                }
              });
            }
          });
          this.filteredIdArray = filteredModalidad;
        }
      });
  };

  filtrarPorGestion = () => {
    let gestionSelected = [];

    $("#filtroGestionHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredGestion = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                loc.establecimiento &&
                loc.establecimiento.sector &&
                e[i].id == loc.establecimiento.sector.descripcion &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredGestion.includes(loc.id))
                  filteredGestion.push(loc.id);
              }
            }
          });
          this.filteredIdArray = filteredGestion;
        }
      });
  };

  filtrarPorAmbito = () => {
    let ambitoSelected = [];
    $("#filtroAmbitoHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredAmbito = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (
                loc.ambito &&
                loc.ambito.descripcion &&
                e[i].id == loc.ambito.descripcion &&
                this.filteredIdArray.includes(loc.id)
              ) {
                if (!filteredAmbito.includes(loc.id))
                  filteredAmbito.push(loc.id);
              }
            }
          });
          this.filteredIdArray = filteredAmbito;
        }
      });
  };

  filtrarPorAdm = () => {
    let filteredADM = [];
    if ($("#checkBoxADMPadre").prop("checked")) {
      this.localizacionesArray.map((loc: any) => {
        if (loc.adm != null && this.filteredIdArray.includes(loc.id)) {
          if (!filteredADM.includes(loc.id)) filteredADM.push(loc.id);
        }
      });
      this.filteredIdArray = filteredADM;
    }
  };

  filtrarPorInternet = () => {
    let internetSelected = [];
    $("#filtroInternetHijo input:checked")
      .each((i, item) => {
        if (item.id == "coninternet") {
          internetSelected[0] = true;
        }
        if (item.id == "sininternet") {
          internetSelected[1] = true;
        }
      })
      .promise()
      .done((e: any) => {
        let filteredInternet = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            if (
              loc.tieneInternet == 1 &&
              internetSelected[0] &&
              this.filteredIdArray.includes(loc.id)
            ) {
              if (!filteredInternet.includes(loc.id))
                filteredInternet.push(loc.id);
            }
            if (
              loc.tieneInternet == 0 &&
              internetSelected[1] &&
              this.filteredIdArray.includes(loc.id)
            ) {
              if (!filteredInternet.includes(loc.id))
                filteredInternet.push(loc.id);
            }
          });
          this.filteredIdArray = filteredInternet;
        }
      });
  };

  filtrarPorLineaC = () => {
    let lineaCSelected = [];
    $("#filtroLineaCHijo input:checked")
      .each((i, item) => {
        if (item.id == "conLineaC") {
          lineaCSelected[0] = true;
        }
        if (item.id == "sinLineaC") {
          lineaCSelected[1] = true;
        }
      })
      .promise()
      .done((e: any) => {
        let filteredLineaC = [];
        if (e.length != 0) {
          LineaC.map((loc: any) => {
            if (
              lineaCSelected[0] &&
              this.filteredIdArray.includes(loc.CueAnexo)
            ) {
              if (!filteredLineaC.includes(loc.CueAnexo))
                filteredLineaC.push(loc.CueAnexo);
            }
          });

          if (lineaCSelected[1]) {
            this.filteredIdArray.map((id: any) => {
              var flag = false;
              LineaC.map((loc: any) => {
                if (loc.CueAnexo == id) {
                  flag = true;
                }
              });
              if (!flag) filteredLineaC.push(id);
            });
          }
          this.filteredIdArray = filteredLineaC;
        }
      });
  };

  filtrarPorAguaPotable = () => {
    let aguaPotableSelected = [];
    $("#filtroAguaPotableHijo input:checked")
      .each((i, item) => {
        if (item.id == "conAguaPotable") {
          aguaPotableSelected[0] = "Si";
        }
        if (item.id == "sinAguaPotable") {
          aguaPotableSelected[1] = "No";
        }
      })
      .promise()
      .done((e: any) => {
        let filteredAguaPotable = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            this.aguaPotable.map((element: any) => {
              if (
                loc.id === element.cueAnexo &&
                element.aguaPotable === aguaPotableSelected[0] &&
                this.filteredIdArray.includes(loc.id)
              ) {
                console.log(element.aguaPotable);
                if (!filteredAguaPotable.includes(loc.id))
                  filteredAguaPotable.push(loc.id);
              }
              if (
                loc.id === element.cueAnexo &&
                element.aguaPotable === aguaPotableSelected[1] &&
                this.filteredIdArray.includes(loc.id)
              ) {
                console.log(element.aguaPotable);
                if (!filteredAguaPotable.includes(loc.id))
                  filteredAguaPotable.push(loc.id);
              }
            });
          });
          this.filteredIdArray = filteredAguaPotable;
        }
      });
  };

  filtrarPorProveedores = () => {
    let proveedoresSelected = [];
    $("#filtroProveedoresHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredProveedores = [];
        if (e.length != 0) {
          this.localizacionesArray.map((loc: any) => {
            for (let i = 0; i < e.length; i++) {
              if (loc.conexiones && loc.conexiones.length != 0) {
                loc.conexiones.map((conexion: any) => {
                  if (
                    e[i].id == conexion.proveedor.descripcion &&
                    this.filteredIdArray.includes(loc.id)
                  ) {
                    if (!filteredProveedores.includes(loc.id))
                      filteredProveedores.push(loc.id);
                  }
                });
              }
            }
          });
          this.filteredIdArray = filteredProveedores;
        }
      });
  };

  filtrarPorAgrupaciones = () => {
    $("#filtroAgrupacionesHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredAgrupaciones = [];
        let EFAS: any = [
          "180073100",
          "180083900",
          "180122200",
          "180134900",
          "180135000",
          "180137000",
          "180145200",
          "180146200",
          "180150200",
          "180150300",
          "180151400",
          "180154500",
          "180156700",
          "180156800",
          "180157000",
          "180157100",
          "180157200",
          "180163400",
          "180171900",
          "180189400",
        ];
        if (e.length != 0) {
          for (let i = 0; i < e.length; i++) {
            if (e[i].id == "EFAS") {
              EFAS.map((efas: any) => {
                this.filteredIdArray.push(efas);
              });
            }
          }
          //this.filteredIdArray = filteredAgrupaciones
        }
      });
  };

  filtrarPorCabeceras = () => {
    $("#filtroCabecerasHijo input:checked")
      .each((i, item) => {})
      .promise()
      .done((e: any) => {
        let filteredCabeceras = [];
        let EFAS: any = [
          "180073100",
          "180083900",
          "180122200",
          "180134900",
          "180135000",
          "180137000",
          "180145200",
          "180146200",
          "180150200",
          "180150300",
          "180151400",
          "180154500",
          "180156700",
          "180156800",
          "180157000",
          "180157100",
          "180157200",
          "180163400",
          "180171900",
          "180189400",
        ];
        if (e.length != 0) {
          for (let i = 0; i < e.length; i++) {
            if (e[i].id == "PRIMARIAS") {
              this.cabecerasPrimaria.map((primarias) => {
                this.filteredIdArray.push(primarias["CUA-ANEXO"]);
              });
            }
          }
        }
      });
  };

  filtrar = () => {
    this.filteredArray = [];
    this.filteredIdArray = [];

    this.filtrarPorLocalidad();
    if (this.currentUser) this.filtrarPorDependencia();
    if (this.currentUser) this.filtrarPorEstado();
    this.filtrarPorJurisdiccion();
    this.filtrarPorOrganismoDependencia();
    this.filtrarPorNivel();
    this.filtrarPorModalidad();
    this.filtrarPorGestion();
    this.filtrarPorAmbito();
    this.filtrarPorAdm(); // Aula Digital Movil
    this.filtrarPorInternet();
    this.filtrarPorLineaC();
    this.filtrarPorAguaPotable();
    this.filtrarPorProveedores();
    this.filtrarPorAgrupaciones();
    this.filtrarPorCabeceras();
    this.btnSinGeoreferencia();

    //FILTER WITH ID
    this.filteredIdArray.map((id) => {
      this.localizacionesArray.map((loc) => {
        if (loc.id == id) {
          this.filteredArray.push(loc);
        }
      });
    });

    //SEND TO MARKERS
    this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);

    //Buttons Check
    if (this.botonesCheck[0] && this.filteredArray.length != 0) {
      this.filteredArray.push("internetConnect");
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
    }
    if (this.botonesCheck[1] && this.filteredArray.length != 0) {
      this.filteredArray.push("internetProvider");
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
    }
    if (this.botonesCheck[2] && this.filteredArray.length != 0) {
      this.talkService.sendEnrollmentLessThan(
        this.filteredArray,
        this.matriculaCantidad,
        this.distanciaEscuelasMatriculas
      );
    }
  };

  matriculaMenorA = () => {
    if (!this.botonesCheck[2] && this.filteredArray.length != 0) {
      this.matriculaCantidad; //Input del HTML
      this.talkService.sendEnrollmentLessThan(
        this.filteredArray,
        this.matriculaCantidad,
        this.distanciaEscuelasMatriculas
      );
      //Chequea botones
      this.botonesCheck[0] = false;
      this.botonesCheck[1] = false;
      this.botonesCheck[2] = true;
    } else if (!this.botonesCheck[2]) {
      this.botonesCheck[0] = false;
      this.botonesCheck[1] = false;
      this.botonesCheck[2] = true;
      this.talkService.sendEnrollmentLessThan(
        this.localizacionesArray,
        this.matriculaCantidad,
        this.distanciaEscuelasMatriculas
      );
    } else {
      $("#inputRadio").css("display", "none");
      this.distanciaEscuelasMatriculas = undefined;
      this.botonesCheck[2] = false;
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
    }
  }; //END matriculaMenorAcinco()-------------------------------------------------------------------------------------------------------------------------------------------

  distanciaEscuelas = () => {
    this.distanciaEscuelasMatriculas = $("#Sliderdist").val();
    if (this.filteredArray.length != 0) {
      this.talkService.sendEnrollmentLessThan(
        this.filteredArray,
        this.matriculaCantidad,
        this.distanciaEscuelasMatriculas
      );
    } else {
      this.talkService.sendEnrollmentLessThan(
        this.localizacionesArray,
        this.matriculaCantidad,
        this.distanciaEscuelasMatriculas
      );
    }
  };

  btnConnectInternet = () => {
    if (!this.botonesCheck[0] && this.filteredArray.length != 0) {
      this.filteredArray.push("internetConnect");
      this.talkService.sendReferencias("Conectividad");
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
      this.botonesCheck[0] = true;
      this.botonesCheck[1] = false;
      this.botonesCheck[2] = false;
    } else {
      this.talkService.sendReferencias("Comun");
      this.botonesCheck[0] = false;
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
    }
  }; //END btnConnectInternet()-------------------------------------------------------------------------------------------------------------------------------------------

  btnProviderInternet = () => {
    if (!this.botonesCheck[1] && this.filteredArray.length != 0) {
      this.filteredArray.push("internetProvider");
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
      this.talkService.sendReferencias("Proveedores");
      this.botonesCheck[0] = false;
      this.botonesCheck[1] = true;
      this.botonesCheck[2] = false;
    } else {
      this.talkService.sendReferencias("Comun");
      this.botonesCheck[1] = false;
      this.talkService.sendArrayLocalizacionFiltered(this.filteredArray);
    }
  }; //END btnProviderInternet()-------------------------------------------------------------------------------------------------------------------------------------------

  btnSinGeoreferencia = () => {
    if (!this.botonesCheck[3]) {
      let withGeoArray = [];
      this.localizacionesArray.map((loc: any) => {
        this.filteredIdArray.map((locId: any) => {
          if (loc.id == locId) {
            if (
              loc.domicilio.position[0] != -58.547756155051275 &&
              loc.domicilio.position[1] != -27.28605074864218
            ) {
              withGeoArray.push(loc.id);
            }
          }
        });
      });
      this.filteredIdArray = withGeoArray;
    } else {
      if (this.filteredIdArray.length != 0) {
        //No Hacer Nada
      } else {
        let noGeoArray = [];
        this.localizacionesArray.map((loc: any) => {
          //Nuevas coordenadas para sinGeoreferencia -27.28605074864218, -58.547756155051275
          if (
            loc.domicilio.position[1] == -27.28605074864218 &&
            loc.domicilio.position[0] == -58.547756155051275
          ) {
            noGeoArray.push(loc.id);
          }
        });
        this.filteredIdArray = noGeoArray;
      }
    }
  };

  btnRAGE = () => {
    this.talkService.sendLoadRaGe(this.markerRaGe);
    $("#btnRaGe").css("display", "none");
  };
}
