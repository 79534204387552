import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { RequestsApiService } from "../../services/requestsApi.service";
import { ComponentsTalkService } from "src/app/services/components-talk.service";

import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

declare var $: any;

@Component({
  selector: "app-barra-superior",
  templateUrl: "./barra-superior.component.html",
  styleUrls: ["./barra-superior.component.scss"],
})
export class BarraSuperiorComponent implements OnInit {
  variableTest;

  //select of elements html with library Renderer2
  @ViewChild("dropdownMenu", { static: false }) dropdownMenu: ElementRef;
  @ViewChild("txtBusquedacue", { static: false }) txtBusquedacue: ElementRef;
  @ViewChild("txtBusquedanomb", { static: false }) txtBusquedanomb: ElementRef;
  @ViewChild("txtBusqueda", { static: false }) txtBusqueda: ElementRef;

  //array for store the name of the schools
  indexNameSchools: string[] = [];
  //array for store the cue of the schools
  indexCueSchools: string[] = [];
  //contains all locations
  locationsAll: any;
  //auth user
  currentUser;
  //store notification see map connectivity or view provider map from the filters component
  connectProvidersInternet: string = "off";

  constructor(
    private renderer: Renderer2,
    private requestApiService: RequestsApiService,
    private talkService: ComponentsTalkService,
    private authService: AuthService
  ) {
    this.getDataApi();
  }

  //for autocomplete forms
  myControl = new FormControl();
  filteredNameSchools: Observable<string[]>;

  ngOnInit() {
    this.authService.currentUser.subscribe((data) => {
      this.currentUser = data;
    });

    //for autocomplete forms
    this.filteredNameSchools = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterNameSchools(value))
    );

    //listen if the ver mapa conectivida button or ver proveedores button is pressed
    this.talkService.ObservableConnectProvidersInternet.subscribe((data) => {
      //if the notify is internetPriver or internetConnect, execute btnSearch function
      if (
        data.toString() == "internetProvider" ||
        data.toString() == "internetConnect"
      ) {
        this.connectProvidersInternet = data.toString();
        setTimeout(() => {
          this.btnSearch();
        }, 100);
      }
    });
  } //END ngOnInit()-------------------------------------------------------------------------------------------------------

  //for autocomplete forms
  private _filterNameSchools(value: string): string[] {
    console.log(value);
    const filterValue = value.toLowerCase();
    const filteredNames = this.indexNameSchools.filter((option) => {
      if (option && option.toLowerCase().includes(filterValue)) return true;
      else return false;
    });

    return filteredNames;
  } //END _filterNameSchools()-------------------------------------------------------------------------------------------------------

  //suscripcion to the request data to the api and store the data in the array indexSchools
  getDataApi = () => {
    this.requestApiService.getIndexSchool().subscribe((data) => {
      // Object.values() retturn all the values of an objects (name of the schools)
      this.indexNameSchools = Object.values(data);
      // Object.values() retturn all the keys of an objects (cue of the schools)
      this.indexCueSchools = Object.keys(data);
    });

    this.requestApiService.getLocalizacion().subscribe((locations) => {
      this.locationsAll = locations;
    });
  }; //END getDataApi()-------------------------------------------------------------------------------------------------------

  //functions for hide and show different elements of the barra-superior
  showHideSeeker(htmlComponent) {
    if (htmlComponent == "dropdownMenu") {
      this.renderer.setAttribute(
        this.dropdownMenu.nativeElement,
        "style",
        this.dropdownMenu.nativeElement.style.display ? "" : "display: block"
      );
    }
    if (htmlComponent == "txtBusquedacue") {
      this.renderer.setAttribute(
        this.txtBusquedacue.nativeElement,
        "style",
        "display: block"
      );
      this.renderer.setAttribute(
        this.txtBusquedanomb.nativeElement,
        "style",
        "display: none"
      );
      this.renderer.setAttribute(
        this.txtBusqueda.nativeElement,
        "style",
        "display: none"
      );
    }
    if (htmlComponent == "txtBusquedanomb") {
      this.renderer.setAttribute(
        this.txtBusquedanomb.nativeElement,
        "style",
        "display: block"
      );
      this.renderer.setAttribute(
        this.txtBusquedacue.nativeElement,
        "style",
        "display: none"
      );
      this.renderer.setAttribute(
        this.txtBusqueda.nativeElement,
        "style",
        "display: none"
      );
    }
  } //END showHideSeeker()----------------------------------------------------------------------------------------

  //Search button behavior
  btnSearch = () => {
    this.talkService.sendNotifyBtnSearch("notifyBtnSearch");

    //If name input is visible, it will only send the entered name and not the input cue
    if ($("#txtBusquedanomb").is(":visible")) {
      var sendLocation = [];
      //auxiliary to verify whether or not the name entered exists
      var auxNotName = true;

      for (var i in this.locationsAll) {
        //if the value of the inpt matches any location
        if ($("#txtBusquedanomb").val() == this.locationsAll[i].nombre) {
          //check if press the button ver mapa conectividad or button mapa de proveedores
          if (
            this.connectProvidersInternet == "internetConnect" ||
            this.connectProvidersInternet == "internetProvider"
          ) {
            // array to send the desired location and the notification of the button pressed to the component marcadores
            sendLocation.push(
              this.locationsAll[i],
              this.connectProvidersInternet
            );
            this.talkService.map.setZoom(15);
            this.talkService.map.setCenter(
              new google.maps.LatLng(
                this.locationsAll[i].domicilio.position[1],
                this.locationsAll[i].domicilio.position[0] - 0.015
              )
            );
            this.talkService.sendArrayLocalizacionFiltered(sendLocation);
            //send array to marcadores component
            this.talkService.sendMarkerBarraInfo(this.locationsAll[i]);
            auxNotName = false;
          } else {
            //otherwise, only the location is sent
            sendLocation.push(
              this.locationsAll[i],
              this.connectProvidersInternet
            );
            this.talkService.map.setZoom(15);
            this.talkService.map.setCenter(
              new google.maps.LatLng(
                this.locationsAll[i].domicilio.position[1],
                this.locationsAll[i].domicilio.position[0] - 0.015
              )
            );
            this.talkService.sendArrayLocalizacionFiltered([
              this.locationsAll[i],
            ]);
            this.talkService.sendMarkerBarraInfo(this.locationsAll[i]);
            auxNotName = false;
          }
        }
      }

      //if it does not exists, alert
      if (auxNotName) {
        alert("La institucion buscada no existe");
        this.talkService.sendArrayLocalizacionFiltered([]);
      }
    }

    //If cue input is visible, it will only send the entered cue and not the input name
    if ($("#txtBusquedacue").is(":visible")) {
      var sendLocation = [];

      //remove blanks
      var valueInputCue = $("#txtBusquedacue").val().replace(/ /g, "");
      //crete array of strings with each input of the cue
      var valueInputCue = valueInputCue.split(",");
      //auxiliaries
      var auxNotNumber = false;
      var auxNotNineDigit = false;

      //go through each cue entered to see if they are number and 9 digits
      valueInputCue.forEach((element) => {
        //check if it is number and auxNotNumber is true
        if (isNaN(element)) {
          auxNotNumber = true;
        }
        //check if you have 9 digits and auxNotNineDigits is true
        if (element.length != 9) {
          auxNotNineDigit = true;
        }
      });

      //alert as appropriate
      if (auxNotNineDigit && auxNotNumber) {
        alert("Cada CUE anexo debe ser de tipo numerico y de 9 digitos");
      } else if (auxNotNumber) {
        alert("Cada CUE anexo debe ser de tipo numerico");
      } else if (auxNotNineDigit) {
        alert("Cada CUE anexo debe tener exactamente 9 digitos");
      } else {
        //If the cue were entered correctly, they are sent to render on the map

        //array for save the locaions to the component marcadores
        var cueToSend: string[] = [];
        for (var i in valueInputCue) {
          this.locationsAll.forEach((indexCue) => {
            //if the CUE entered in the input matches that of any location, this location is stored in the array
            if (valueInputCue[i] == indexCue.id) {
              cueToSend.push(indexCue);
            }
          });
        }

        //if there is at least one cue entered, then send it to the marcadores component
        if (cueToSend.length != 0) {
          //check if press the button ver mapa conectividad or button mapa de proveedores
          if (
            this.connectProvidersInternet == "internetConnect" ||
            this.connectProvidersInternet == "internetProvider"
          ) {
            //add to the array of locations the notifications about wich button is pressed
            cueToSend.push(this.connectProvidersInternet);
            //send array to  the marcadores component
            this.talkService.sendArrayLocalizacionFiltered(cueToSend);
          } else {
            //otherwise, only the location is sent
            this.talkService.sendArrayLocalizacionFiltered(cueToSend);
          }
        } else {
          alert("Ningun CUE anexo ingresado es correcto");
        }
      }
    }

    this.connectProvidersInternet = "off";
    console.log(this.connectProvidersInternet);
  }; //END btnSearch()----------------------------------------------------------------------------------------------------
} //END class barra-superior()--------------------------------------------------------------------------------------------------------
