import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FiltrosComponent } from "./components/filtros/filtros.component";
import { HerramientasComponent } from "./components/herramientas/herramientas.component";
import { LocalizacionesComponent } from "./components/localizaciones/localizaciones.component";
import { AreaComponent } from "./components/area/area.component";
import { EstadisticasComponent } from "./components/estadisticas/estadisticas.component";
import { PlanesComponent } from "./components/planes/planes.component";
import { IndicadoresComponent } from "./components/indicadores/indicadores.component";

const routes: Routes = [
  { path: "", component: FiltrosComponent },
  { path: "herramientas", component: HerramientasComponent },
  { path: "localizaciones", component: LocalizacionesComponent },
  { path: "area", component: AreaComponent },
  { path: "estadisticas", component: EstadisticasComponent },
  { path: "planes", component: PlanesComponent },
  { path: "localizacion", component: LocalizacionesComponent },
  { path: "indicadores", component: IndicadoresComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
