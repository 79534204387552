<div #modal style="display:none;">
   <div class="modal" tabindex="-1" role="dialog" (click)="closeOutsideModal($event)">
      <div class="modal-dialog" role="document">
         <div class="modal-content" #insideElement>
            <div class="modal-header">
               <h5 class="modal-title">Ingresar</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <form>
               <div class="modal-body">

                  <div class="user">
                     <input #user id="user" type="text" placeholder="Usuario" class="user-input" style="width: 100%">

                  </div>
                  <div class="pass">
                     <input #pass id="pass" type="password" placeholder="Contraseña" class="pass-input" style="width: 100%">
                  </div>

               </div>
               <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                  <!-- <div class="btn-icon" style="background-color: green">
                     <i class="material-icons">lock</i>
               </div> -->
                  <button id="iniciarsesion" type="submit" class="btn btn-success"
                     (click)="login(user.value,pass.value)">Iniciar Sesión</button>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>