import { Component, OnInit } from "@angular/core";
import { ComponentsTalkService } from "src/app/services/components-talk.service";
import { RequestsApiService } from '../../services/requestsApi.service';
import * as topojson from "topojson-client";
import * as Chart from "chart.js";
import { Element } from '@angular/compiler';

declare var $: any;
declare var require: any;
declare let google: any;
const indicadoress = require("../../data/indicadores.json");
const departaments = require("../../data/departaments.json");

@Component({
  selector: "app-indicadores",
  templateUrl: "./indicadores.component.html",
  styleUrls: ["./indicadores.component.scss"]
})
export class IndicadoresComponent implements OnInit {
  indicadores: any;
  dataPolygon = new google.maps.Data();
  map: google.maps.Map;
  ventanaContainer: any = [];
  ventanaDepto: any = null;
  elementosFiltrados = [];
  filtroAnioHijo = false;
  filtroTasaHijo = false;
  filtroNivelHijo = false;
  filtroDeptoHijo = false;
  filtroSectorHijo = false;
  filtroAnioCursadoHijo = false;
  checkHijosTipoTasa = false;
  checkHijosAnios = false;
  checkHijosNiveles = false;
  checkDeptoPadre = false;
  checkSectorPadre = false;
  checkAnioCursadoPadre = false;
  botonesCheck = [false];
  valueAnioCursado: any;
  tipoTasa: any;
  collapsed = false;
  porcentajeProvincialGlobal: any;
  legend: any;
  anios = [
    { id: 1, descripcion: 2011 },
    { id: 2, descripcion: 2012 },
    { id: 3, descripcion: 2013 },
    { id: 4, descripcion: 2014 },
    { id: 5, descripcion: 2015 },
    { id: 6, descripcion: 2016 },
    { id: 7, descripcion: 2017 },
    { id: 8, descripcion: 2018 }
  ];
  tiposTasa = [
    { id: 1, descripcion: "Promocion efectiva" },
    { id: 2, descripcion: "Tasa Repitencia" },
    { id: 3, descripcion: "Tasa Sobreedad" },
    { id: 4, descripcion: "Tasa de Abandono" }
  ];
  niveles = [
    { id: 1, descripcion: "Primario" },
    { id: 2, descripcion: "Secundario" }
  ];
  deptos: any = [];
  sectores = [
    { id: 1, descripcion: "Estatal" },
    { id: 2, descripcion: "Privado" }
  ];
  anioCursado = [
    { value: "Primer Año", id: "primerAnio" },
    { value: "Segundo Año", id: "segundoAnio" },
    { value: "Tercer Año", id: "tercerAnio" },
    { value: "Cuarto Año", id: "cuartoAnio" },
    { value: "Quinto Año", id: "quintoAnio" },
    { value: "Sexto Año", id: "sextoAnio" }
  ];

  constructor(private talkService: ComponentsTalkService, private requestApi: RequestsApiService) {
    setTimeout(() => {
      this.map = this.talkService.map;
    }, 100);
  }

  ngOnInit() {

    this.requestApi.getIndicadores().subscribe(data => {
      this.indicadores = data;
      this.indicadores.forEach(element => {
        if (!this.deptos.includes(element.departamento)) {
          this.deptos.push(element.departamento);
        }
      });
    });



    setTimeout(() => {
      this.limitarDeptos();
    }, 500);

    this.legend = document.getElementById("legend");
  }

  ngOnDestroy() {
    this.dataPolygon.setMap(null);
    this.despintarMapa();
  }

  collapse() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      $("#sidebar-filters").removeClass("col-md-3");
      $("#sidebar-filters").addClass("collapsed col-md-1");
    } else {
      $("#sidebar-filters").addClass("col-md-3");
      $("#sidebar-filters").removeClass("collapsed");
    }
  }

  limitarDeptos = () => {
    var geoJsonObject = topojson.feature(
      departaments,
      departaments.objects.departamentos
    );
    this.dataPolygon.addGeoJson(geoJsonObject);
    this.dataPolygon.setMap(this.map);
    this.dataPolygon.setStyle(function (feature) {
      var color = "gray";
      if (feature.getProperty("porcent")) {
        color = feature.getProperty("porcent");
      }
      return /** @type {google.maps.Data.StyleOptions} */ {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 2
      };
    });
  };

  showChildFilters = (element: any) => {
    if (element.id == "filtroAnioPadre") {
      this.filtroAnioHijo = !this.filtroAnioHijo;
      this.filtroAnioHijo
        ? $("#filtroAnioHijo").show()
        : $("#filtroAnioHijo").hide();
    }

    if (element.id == "filtroTasaPadre") {
      this.filtroTasaHijo = !this.filtroTasaHijo;
      this.filtroTasaHijo
        ? $("#filtroTasaHijo").show()
        : $("#filtroTasaHijo").hide();
    }

    if (element.id == "filtroNivelPadre") {
      this.filtroNivelHijo = !this.filtroNivelHijo;
      this.filtroNivelHijo
        ? $("#filtroNivelHijo").show()
        : $("#filtroNivelHijo").hide();
    }

    if (element.id == "filtroDeptoPadre") {
      this.filtroDeptoHijo = !this.filtroDeptoHijo;
      this.filtroDeptoHijo
        ? $("#filtroDeptoHijo").show()
        : $("#filtroDeptoHijo").hide();
    }

    if (element.id == "filtroSectorPadre") {
      this.filtroSectorHijo = !this.filtroSectorHijo;
      this.filtroSectorHijo
        ? $("#filtroSectorHijo").show()
        : $("#filtroSectorHijo").hide();
    }

    if (element.id == "filtroAnioCursadoPadre") {
      this.filtroAnioCursadoHijo = !this.filtroAnioCursadoHijo;
      this.filtroAnioCursadoHijo
        ? $("#filtroAnioCursadoHijo").show()
        : $("#filtroAnioCursadoHijo").hide();
    }
  };

  checkInputTasaHijo(elementTasaHijo: any) {
    $("#filtroAnioHijo").show()
    let valueCheckTasaHijo = $(elementTasaHijo).prop("checked");
    $("#filtroTasaHijo input").each((index, elemTasaHijo) => {
      $(elemTasaHijo).prop("checked", false);
    });
    if (valueCheckTasaHijo) {
      this.checkHijosTipoTasa = true;
      $(elementTasaHijo).prop("checked", valueCheckTasaHijo);
    } else {
      this.checkHijosTipoTasa = false;
      this.checkHijosAnios = false;
      this.checkHijosNiveles = false;
    }
    this.filtrar();
  }

  checkInputAnioHijo(elementAnioHijo: any) {
    $("#filtroNivelHijo").show();
    let valueCheckAnioHijo = $(elementAnioHijo).prop("checked");
    $("#filtroAnioHijo input").each((index, elemAnioHijo) => {
      $(elemAnioHijo).prop("checked", false);
    });
    if (valueCheckAnioHijo) {
      this.checkHijosAnios = true;
      $(elementAnioHijo).prop("checked", valueCheckAnioHijo);
    } else {
      this.checkHijosAnios = false;
      this.checkHijosNiveles = false;
    }
    this.filtrar();
  }

  checkInputNivelHijo(elementNivelHijo: any) {
    let valueCheckNivelHijo = $(elementNivelHijo).prop("checked");
    $("#filtroNivelHijo input").each((index, elemNivelHijo) => {
      $(elemNivelHijo).prop("checked", false);
    });
    if (valueCheckNivelHijo) {
      this.checkHijosNiveles = true;
      $(elementNivelHijo).prop("checked", valueCheckNivelHijo);
    } else {
      this.checkHijosNiveles = false;
    }
    this.filtrar();
  }

  onCheckBoxPadre = (element: any) => {
    if (element.id == "checkBoxDeptoPadre") {
      if (element.checked) {
        $("#filtroDeptoHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroDeptoHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
        this.checkDeptoPadre = false;
      }
    }

    if (element.id == "checkBoxSectorPadre") {
      if (element.checked) {
        $("#filtroSectorHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroSectorHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
        this.checkSectorPadre = false;
      }
    }

    if (element.id == "checkBoxAnioCursadoPadre") {
      if (element.checked) {
        $("#filtroAnioCursadoHijo input").each((index, elem) => {
          $(elem).prop("checked", true);
        });
      } else {
        $("#filtroAnioCursadoHijo input").each((index, elem) => {
          $(elem).prop("checked", false);
        });
        this.checkAnioCursadoPadre = false;
      }
    }

    this.filtrar();
  };

  onCheckBoxHijos(elementAnioCursadoHijo?: any) {
    this.checkDeptoPadre = false;
    this.checkSectorPadre = false;
    this.checkAnioCursadoPadre = false;

    //Si hay un hijo checked cambia estado true
    $("#filtroDeptoHijo input:checked").each((i, item) => {
      if (i >= 0) this.checkDeptoPadre = true;
    });
    $("#filtroSectorHijo input:checked").each((i, item) => {
      if (i >= 0) this.checkSectorPadre = true;
    });

    if (elementAnioCursadoHijo) {
      let valueCheckAnioCursadoHijo = $(elementAnioCursadoHijo).prop("checked");
      $("#filtroAnioCursadoHijo input").each((index, elemAnioCursadoHijo) => {
        $(elemAnioCursadoHijo).prop("checked", false);
      });
      if (valueCheckAnioCursadoHijo) {
        this.checkAnioCursadoPadre = true;
        $(elementAnioCursadoHijo).prop("checked", valueCheckAnioCursadoHijo);
      }
    }
    $("#filtroAnioCursadoHijo input:checked").each((i, item) => {
      if (i >= 0) this.checkAnioCursadoPadre = true;
    });

    //Si hay un hijo de cualquier elemento checked marca padre checked
    if (this.checkDeptoPadre) {
      $("#checkBoxDeptoPadre").prop("checked", true);
    } else {
      $("#checkBoxDeptoPadre").prop("checked", false);
    }
    if (this.checkSectorPadre) {
      $("#checkBoxSectorPadre").prop("checked", true);
    } else {
      $("#checkBoxSectorPadre").prop("checked", false);
    }
    if (this.checkAnioCursadoPadre) {
      $("#checkBoxAnioCursadoPadre").prop("checked", true);
    } else {
      $("#checkBoxAnioCursadoPadre").prop("checked", false);
    }

    this.filtrar();
  }

  filtrar() {
    this.elementosFiltrados = [];

    var criterioProvincial: Number;
    var valueTasaHijo: any;
    var valueAnioHijo: any;
    var valueNivelHijo: any;

    $("#filtroTasaHijo input").each((index, elemTasaHijo) => {
      if ($(elemTasaHijo).prop("checked")) {
        valueTasaHijo = elemTasaHijo.value;
        this.tipoTasa = elemTasaHijo.value;
      }
    });

    $("#filtroAnioHijo input").each((index, elemAnioHijo) => {
      if ($(elemAnioHijo).prop("checked")) {
        valueAnioHijo = elemAnioHijo.value;
      }
    });

    $("#filtroNivelHijo input").each((index, elemNivelHijo) => {
      if ($(elemNivelHijo).prop("checked")) {
        valueNivelHijo = elemNivelHijo.value;
      }
    });

    this.indicadores.forEach(element => {
      if (element.tasa == valueTasaHijo) {
        if (element.anio == valueAnioHijo) {
          if (element.nivel == valueNivelHijo) {
            this.elementosFiltrados.push(element);
          }
        }
      }
    });

    this.filtrarPorAnioCursado();

    this.filtrarPorSector();

    if (this.checkDeptoPadre) {
      this.filtrarPorDepto();
    }

    if (this.botonesCheck[0]) {
      this.pintarDatos();
    }
  }

  filtrarPorDepto = () => {
    var arrayAuxiliar: any = [];
    $("#filtroDeptoHijo input:checked")
      .each((i, item) => { })
      .promise()
      .done(e => {
        if (e.length != 0) {
          this.elementosFiltrados.map((ele: any) => {
            for (let i = 0; i < e.length; i++) {
              if (e[i].value == ele.departamento) {
                arrayAuxiliar.push(ele);
              }
            }
          });
        }
      });
    this.elementosFiltrados = arrayAuxiliar;
  };

  filtrarPorSector = () => {
    var arrayAuxiliar: any = [];
    $("#filtroSectorHijo input:checked")
      .each((i, item) => { })
      .promise()
      .done(e => {
        if (e.length == 1) {
          this.elementosFiltrados.map((ele: any) => {
            if (e[0].value == ele.sector) {
              arrayAuxiliar.push(ele);
            }
          });
          this.elementosFiltrados = arrayAuxiliar;
          this.porcentajeProvincialGlobal = this.elementosFiltrados[0][
            this.valueAnioCursado
          ];
        } else if (e.length == 0 || e.length == 2) {
          this.elementosFiltrados.map((ele: any) => {
            if (ele.sector == "Todas") {
              arrayAuxiliar.push(ele);
            }
          });
          this.elementosFiltrados = arrayAuxiliar;
          this.porcentajeProvincialGlobal = this.elementosFiltrados[0][
            this.valueAnioCursado
          ];
        }
      });
    console.log(this.valueAnioCursado)
    console.log(this.elementosFiltrados)
    console.log(this.porcentajeProvincialGlobal)
    console.log(this.elementosFiltrados[0][
      this.valueAnioCursado])
  };

  filtrarPorAnioCursado = () => {
    $("#filtroAnioCursadoHijo input:checked")
      .each((i, item) => { })
      .promise()
      .done(e => {
        if (e.length == 1) {
          this.valueAnioCursado = e[0].value;
        } else {
          this.valueAnioCursado = "total";
        }
      });
  };

  pintarDatos() {
    this.dataPolygon.forEach(function (feature) {
      if (feature.getProperty("porcent")) {
        feature.setProperty("porcent", "gray");
      }
    });
    if (this.map.controls[google.maps.ControlPosition.TOP_CENTER].getLength() == 1) {
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].pop();
    }
    $("#legend").css("display", "block");
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.legend
    );
    var tipoTasa: any;
    if (this.ventanaContainer[0]) {
      this.ventanaContainer[0].close();
    }

    this.ventanaContainer.length = 0;
    this.ventanaDepto = new google.maps.InfoWindow();
    this.ventanaContainer.push(this.ventanaDepto);

    var elementosFiltrados = this.elementosFiltrados;
    var departamentosNombre: any = [];
    elementosFiltrados.forEach(element => {
      departamentosNombre.push(element.departamento.toUpperCase());
    });

    var porcentajeProvincialParteEntera: any = this.porcentajeProvincialGlobal
      .split("%")[0]
      .split(",")[0];
    var porcentajeProvincialParteDecimal: any = this.porcentajeProvincialGlobal
      .split("%")[0]
      .split(",")[1];
    var porcentajeIndividualParteEntera: any;
    var porcentajeIndividualParteDecimal: any;
    var deptoNombreYporcentaje = [];
    var deptoNombre: any;
    console.log(elementosFiltrados)
    var valueAnioCursado = this.valueAnioCursado;
    this.dataPolygon.forEach(function (feature) {
      porcentajeIndividualParteEntera = 0;
      porcentajeIndividualParteDecimal = 0;
      var result = departamentosNombre.filter(function (dpto) {
        return dpto == feature.getProperty("departamento");
      });
      elementosFiltrados.forEach(element => {
        tipoTasa = element.tasa;
        if (element.departamento.toUpperCase() == result[0]) {
          deptoNombre = element.departamento.toUpperCase();
          porcentajeIndividualParteEntera = element[valueAnioCursado]
            .split("%")[0]
            .split(",")[0];
          porcentajeIndividualParteDecimal = element[valueAnioCursado]
            .split("%")[0]
            .split(",")[1];
        }
      });

      var porcentajeDepto = parseFloat(
        porcentajeIndividualParteEntera +
        "." +
        porcentajeIndividualParteDecimal
      )
      var porcentajeProv = parseFloat(
        porcentajeProvincialParteEntera +
        "." +
        porcentajeProvincialParteDecimal
      );

      deptoNombreYporcentaje.push({
        departamento: deptoNombre,
        porcentaje: porcentajeDepto,
        porcentajeGlobal: porcentajeProv,
        tipoTasa: tipoTasa
      });

      if (tipoTasa == "Promocion efectiva") {
        if (porcentajeDepto > porcentajeProv) {
          feature.setProperty("porcent", "green");
        } else if (
          porcentajeDepto == porcentajeProv
        ) {
          feature.setProperty("porcent", "yellow");
        } else if (porcentajeDepto != 0) {
          feature.setProperty("porcent", "red");
        }
      } else if (tipoTasa == "Tasa Repitencia" || tipoTasa == "Tasa de Abandono" || tipoTasa == "Tasa Sobreedad") {
        if (porcentajeDepto > porcentajeProv) {
          feature.setProperty("porcent", "red");
        } else if (
          porcentajeDepto == porcentajeProv
        ) {
          feature.setProperty("porcent", "yellow");
        } else if (porcentajeDepto != 0) {
          feature.setProperty("porcent", "green");
        }
      }



    });

    this.dataPolygon.addListener("mouseover", event => {



      var depto = event.feature.getProperty("departamento");

      this.dataPolygon.forEach(function (feature) {
        if (depto == feature.getProperty("departamento")) {
          feature.setProperty("porcent", "black");
        }
      });
      var result = departamentosNombre.filter(function (dpto) {
        return dpto == depto;
      });
      var contentDepto = document.createElement("div");
      contentDepto.style.textAlign = "center";

      if (result[0]) {
        contentDepto.innerText = depto + " (click para mas info)";
        this.ventanaDepto.setContent(contentDepto);
        this.ventanaDepto.setPosition(event.latLng);
        this.ventanaDepto.open(this.map);
      }



    });

    this.dataPolygon.addListener("mouseout", event => {
      var depto = event.feature.getProperty("departamento");
      this.dataPolygon.forEach(function (feature) {
        if (depto == feature.getProperty("departamento")) {
          var result = deptoNombreYporcentaje.filter(function (dpto) {
            return dpto.departamento == depto;
          });
          if (result[0]) {
            if (result[0]["tipoTasa"] == "Promocion efectiva") {
              if (result[0]["porcentaje"] > result[0]["porcentajeGlobal"]) {
                feature.setProperty("porcent", "green");
              } else if (
                result[0]["porcentaje"] == result[0]["porcentajeGlobal"]
              ) {
                feature.setProperty("porcent", "yellow");
              } else {
                feature.setProperty("porcent", "red");
              }
            } else if (tipoTasa == "Tasa Repitencia" || tipoTasa == "Tasa de Abandono" || tipoTasa == "Tasa Sobreedad") {
              if (result[0]["porcentaje"] > result[0]["porcentajeGlobal"]) {
                feature.setProperty("porcent", "red");
              } else if (
                result[0]["porcentaje"] == result[0]["porcentajeGlobal"]
              ) {
                feature.setProperty("porcent", "yellow");
              } else {
                feature.setProperty("porcent", "green");
              }
            }
          } else {
            feature.setProperty("porcent", "gray");
          }
        }
      });

      this.ventanaDepto.close();
    });

    this.dataPolygon.addListener("click", event => {
      console.log(deptoNombreYporcentaje)
      var myHTML = event.feature.getProperty("departamento");

      var result = deptoNombreYporcentaje.filter(function (dpto) {
        return dpto.departamento == myHTML;
      });

      var div = document.createElement("canvas");
      div.id = "myChart";
      var content = document.createElement("div");
      content.style.textAlign = "center";

      if (result[0]) {
        content.innerHTML = '<h6> PARAMETRO PROVINCIAL: ' + this.porcentajeProvincialGlobal + '</h6>' + "<h6> PORCENTAJE EN " + myHTML + ": " + result[0]["porcentaje"].toFixed(2) + "%" + "</h6>"
        this.ventanaDepto.setContent(content);
        this.ventanaDepto.setPosition(event.latLng);
        this.ventanaDepto.open(this.map);
        content.appendChild(div);
        /*
                var dataFirst = {
                  label: "Car A - Speed (mph)",
                  data: [0, 59, 75, 20, 20, 55, 40],
                  lineTension: 0,
                  fill: false,
                  borderColor: 'red'
                };
        
                var dataSecond = {
                  label: "Car B - Speed (mph)",
                  data: [20, 15, 60, 60, 65, 30, 70],
                  lineTension: 0,
                  fill: false,
                  borderColor: 'blue'
                };
                var speedData = {
                  labels: ["2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
                  datasets: [dataFirst, dataSecond]
                };
        
        
                setTimeout(() => {
                  var canva: any = document.getElementById("myChart");
                  var ctx = canva.getContext("2d");
                  var lineChart = new Chart(ctx, {
                    type: 'line',
                    data: speedData,
                    options: {
                      scales: {
                        yAxes: [{
                          stacked: true
                        }]
                      }
                    }
                  });
                }, 100);*/
      }




    });
  }

  criterioProvincial() {
    this.botonesCheck[0] = !this.botonesCheck[0];
    if (this.botonesCheck[0]) {
      this.pintarDatos();
    } else {
      this.despintarMapa();
    }

  }

  despintarMapa() {
    this.dataPolygon.forEach(function (feature) {
      if (feature.getProperty("porcent")) {
        feature.setProperty("porcent", "gray");
      }
    });

    var departamentosNombre: any = [];
    this.deptos.forEach(element => {
      departamentosNombre.push(element.toUpperCase());
    });


    this.dataPolygon.addListener("mouseover", event => {
      var depto = event.feature.getProperty("departamento");
      this.dataPolygon.forEach(function (feature) {
        if (depto == feature.getProperty("departamento")) {
          feature.setProperty("porcent", "black");
        }
      });

      this.ventanaDepto.close();
    });

    this.dataPolygon.addListener("mouseout", event => {
      var depto = event.feature.getProperty("departamento");
      this.dataPolygon.forEach(function (feature) {
        if (depto == feature.getProperty("departamento")) {
          feature.setProperty("porcent", "gray");
        }
      });
      this.ventanaDepto.close();
    });
  }
}
