<div id="sidebar-mainmenu" class="sidebar-mainmenu col-sm-1 scrollbar">
  <ul id="barracaracha">
    <li class="menuitem" id="mapaed" [routerLink]="['']">
      <i class="material-icons">map</i>
      <p>Mapa</p>
    </li>
    <li class="menuitem" id="herramientas" routerLink="/herramientas">
      <i class="material-icons">settings</i>
      <p>Herramientas</p>
    </li>
    <li
      class="menuitem"
      id="estradios"
      routerLink="/localizaciones"
      *ngIf="currentUser && currentUser.rol == 'ADMIN'"
    >
      <i class="material-icons">location_on</i>
      <p>Localizaciones</p>
    </li>

    <li class="menuitem" id="poligonos" routerLink="/area" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
      <i class="material-icons ">crop_free</i>
      <p>Areas</p>
    </li>
    <li
      class="menuitem"
      id="estadisticas"
      routerLink="/estadisticas"
      *ngIf="currentUser && currentUser.rol == 'ADMIN'"
    >
      <i class="material-icons">equalizer</i>
      <p>Estadisticas</p>
    </li>
    <li class="menuitem" id="planes" routerLink="/planes" *ngIf="currentUser && currentUser.rol == 'ADMIN'">
      <i class="material-icons">location_city</i>
      <p>Planes y Obras</p>
    </li>
    <li
      class="menuitem"
      id="indicadores"
      routerLink="/indicadores"
      *ngIf="currentUser && currentUser.rol == 'ADMIN'"
    >
      <i class="material-icons">filter_list</i>
      <p>Indicadores</p>
    </li>

    <li
      *ngIf="!currentUser"
      id="modal"
      class="menuitem last"
      data-toggle="modal"
      data-target="#myModal"
      (click)="modal()"
    >
      <i class="material-icons">account_circle</i>
    </li>

    <li
      *ngIf="currentUser"
      id="modal"
      class="menuitem last"
      data-toggle="modal"
      data-target="#myModal"
      (click)="logout()"
    >
      <i class="material-icons">account_circle</i><br class="logOut" />Salir
    </li>
  </ul>

  <!-- <div *ngIf="!currentUser" id="modal" class="menuitem last" data-toggle="modal" data-target="#myModal"
    (click)="modal()"><i class="material-icons">account_circle</i></div>
  <div *ngIf="currentUser" id="modal" class="menuitem last" data-toggle="modal" data-target="#myModal"
    (click)="logout()"><i class="material-icons">account_circle</i><br>Salir</div> -->

  <!--Si no esta logueado s
        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog">
          
            <div class="modal-content">
              <div class="modal-header">
                
                <h4 class="modal-title">Ingresar</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                
  
               
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                    <input type="text" id="usuario" class="span4 form-control" name="usuario" placeholder="Usuario">
                  </div>
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
                    <input type="password" id="password" class="span4 form-control" name="password" placeholder="Contraseña">
                  </div>
                  <br>
                  <div class="row buttons col-6">
                    <button type="submit" name="submit" class="btn btn-main green">
                      <div class="btn-icon">
                        <span class="fas fa-lock"></span>
                      </div>
                      <p class="btn-content m-0 p-2">
                        Iniciar Sesión
                      </p>
                    </button>
                  </div>
                  </form>
  
          </div>
        </div>
        
      </div>
    </div>
        -->

  <!--Si esta logueado
          <div id="logout" class="menuitem last"><i class="fas fa-user-circle"></i><br>Salir</div>
    -->

  <!--
    <?php if($this->router->method=='mifuturo') :?>
      <?php if(isset($_POST['name'])) :?>
        
        <span class="metadata" id="metadata-size-of-widget" title='<?php echo (json_encode(trim($_POST['name']))); ?>'></span>
      <?php else: redirect('/')?>
      <?php endif;?>
    <?php endif;?>
    -->
</div>
<router-outlet></router-outlet>
<app-barra-info></app-barra-info>
