<!-- Sidebar Estadisticas-->
<div style="display:none; background-color: rgb(0, 0, 0); text-align: center; margin-left: 5%;" id="legend">
    <p style="margin-bottom: 0px; font-size: 15px; color: white">Cantidad de <br> internet en <br> porcentajes</p>
    <div  style="font-size: 12px; color: black; background-color: white;">
        <div style="height: 30px; width: 80px; background-color: rgba(255, 0, 0, 0.466); display: flex; justify-content: center; align-items: center;border: 2px solid red">
            0 - 25%
        </div>
        <div style="height: 30px; width: 80px; background-color: rgba(255, 166, 0, 0.425);display: flex; justify-content: center; align-items: center; border: 2px solid orange">
            26% - 50%
        </div>
        <div style="height: 30px; width: 80px; background-color: rgba(255, 255, 0, 0.466);display: flex; justify-content: center; align-items: center; border: 2px solid yellow">
            51% - 75%
        </div>
        <div style="height: 30px; width: 80px; background-color: rgba(0, 128, 0, 0.452);display: flex; justify-content: center; align-items: center; border: 2px solid green">
            76% - 100%
        </div>
    </div>
</div>
<div id="sidebar-estadisticas" class="barras sidebar-filters col-md-3 scrollbar" >
   
    <div class="row bar-title-wrap d-flex flex-row align-items-start">

        <h3 class="bar-title col-11">Estadisticas</h3>
        <button type="button" class="navbar-toggler col-1 sidebarFilter" data-toggle="collapse" data-target=".navbar-collapse" (click)="collapse()">
            <i class="material-icons" style="cursor: pointer">view_headline</i>
        </button>

    </div>
    <ul class="sidebar-nav">

        <div class="row buttons">

            <button (click)="getInternetStats()" id="internetstats" class="btn btn-main green col-xl-6">
                <div class="btn-icon" style="justify-content: center;"><i *ngIf="botonesCheck[0]" class="material-icons">done</i><span class="fas fa-wifi"></span></div>
                <p class="btn-content m-0 p-2">Internet Por Departamento</p>
            </button>
            

            
        </div>

      

    </ul>

    

</div>
<!-- FIN Estadisticas -->
