<div style="display:none; background-color:white; text-align: center; margin-left: 5%; width: 10%" id="legend">
  <p style="margin-bottom: 0px; font-size: 15px; color: black">Criterio Provincial</p>
  <div *ngIf="tipoTasa == 'Promocion efectiva' ; else referenciaDos"
    style="font-size: 12px; color: black; background-color: white;">
    <div
      style="height: 100%; width: 100%; background-color: rgba(0, 128, 0, 0.452); display: flex; justify-content: center; align-items: center;border: 2px solid green">
      Superior al parametro provincial
    </div>
    <div
      style="height: 100%; width: 100%; background-color: rgba(255, 255, 0, 0.466);display: flex; justify-content: center; align-items: center; border: 2px solid yellow">
      Igual al parametro provincial
    </div>
    <div
      style="height: 100%; width: 100%; background-color: rgba(255, 0, 0, 0.466) ;display: flex; justify-content: center; align-items: center; border: 2px solid red">
      Inferior al parametro provincial
    </div>
  </div>
  <ng-template #referenciaDos>
    <div style="font-size: 12px; color: black; background-color: white;">
      <div
        style="height: 100%; width: 100%; background-color: rgba(0, 128, 0, 0.452); display: flex; justify-content: center; align-items: center;border: 2px solid green">
        Inferior al parametro provincial
      </div>
      <div
        style="height: 100%; width: 100%; background-color: rgba(255, 255, 0, 0.466);display: flex; justify-content: center; align-items: center; border: 2px solid yellow">
        Igual al parametro provincial
      </div>
      <div
        style="height: 100%; width: 100%; background-color: rgba(255, 0, 0, 0.466) ;display: flex; justify-content: center; align-items: center; border: 2px solid red">
        Superior al parametro provincial
      </div>
    </div>
  </ng-template>
</div>
<div id="sidebar-filters" class="barras sidebar-filters col-md-3 scrollbar active float-left">
  <div class="row bar-title-wrap d-flex flex-row align-items-start justify-content-between">
    <h3 class="bar-title col-10">Indicadores</h3>
    <button type="button" class="navbar-toggler col-2" data-toggle="collapse" data-target=".navbar-collapse"
      (click)="collapse()">
      <i class="material-icons" style="cursor: pointer;">view_headline</i>
    </button>
  </div>
  <ul class="sidebar-nav">
    <!-- Tipo Tasas -->
    <div>
      <li style="display: grid">
        <div #filtroTasaPadre id="filtroTasaPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroTasaPadre)">
          <div class="item">
            <div class="noprop"></div>
            <p>Tipo de Tasa</p>
          </div>
        </div>
      </li>

      <div #filtroTasaHijo id="filtroTasaHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div id="tasabox" class="checkboxes">
          <div class="item" *ngFor="let data of tiposTasa">
            <div class="chkbox">
              <input #checkBoxTasaHijo id="{{ data.descripcion }}" value="{{ data.descripcion }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter" (click)="checkInputTasaHijo(checkBoxTasaHijo)" />
              <label for="{{ data.descripcion }}"></label>
            </div>
            <p>{{ data.descripcion }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Anios -->
    <div>
      <li style="display: grid">
        <div #filtroAnioPadre id="filtroAnioPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroAnioPadre)">
          <div class="item">
            <div class="noprop"></div>
            <p>Año</p>
          </div>
        </div>
      </li>

      <div #filtroAnioHijo id="filtroAnioHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div *ngIf="checkHijosTipoTasa; else noAnio" id="aniobox" class="checkboxes">
          <div class="item" *ngFor="let data of anios">
            <div class="chkbox">
              <input #checkBoxAnioHijo id="{{ data.descripcion }}" value="{{ data.descripcion }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter" (click)="checkInputAnioHijo(checkBoxAnioHijo)" />
              <label for="{{ data.descripcion }}"></label>
            </div>
            <p>{{ data.descripcion }}</p>
          </div>
        </div>
        <ng-template #noAnio>
          <p>
            Seleccione un tipo de tasa
          </p>
        </ng-template>
      </div>
    </div>

    <!-- Niveles -->
    <div>
      <li style="display: grid">
        <div #filtroNivelPadre id="filtroNivelPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroNivelPadre)">
          <div class="item">
            <div class="noprop"></div>
            <p>Nivel</p>
          </div>
        </div>
      </li>

      <div #filtroNivelHijo id="filtroNivelHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div *ngIf="checkHijosAnios; else noNivel" id="nivelbox" class="checkboxes">
          <div class="item" *ngFor="let data of niveles">
            <div class="chkbox">
              <input #checkBoxNivelHijo id="{{ data.descripcion }}" value="{{ data.descripcion }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter"
                (click)="checkInputNivelHijo(checkBoxNivelHijo)" />
              <label for="{{ data.descripcion }}"></label>
            </div>
            <p>{{ data.descripcion }}</p>
          </div>
        </div>
        <ng-template #noNivel>
          <p>
            Seleccione un año
          </p>
        </ng-template>
      </div>
    </div>

    <!-- Departamentos -->
    <div *ngIf="checkHijosTipoTasa && checkHijosAnios && checkHijosNiveles">
      <li style="display: grid">
        <div #filtroDeptoPadre id="filtroDeptoPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroDeptoPadre)">
          <div class="item">
            <div class="chkbox noprop">
              <input #checkBoxDeptoPadre value="" type="checkbox" id="checkBoxDeptoPadre"
                class="chkbox masterbox masterloc " (click)="onCheckBoxPadre(checkBoxDeptoPadre)" />
              <label for="checkBoxDeptoPadre" class=""></label>
            </div>
            <p>Departamento</p>
          </div>
        </div>
      </li>

      <div #filtroDeptoHijo id="filtroDeptoHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div id="aniobox" class="checkboxes">
          <div class="item" *ngFor="let data of deptos">
            <div class="chkbox">
              <input #checkBoxDeptoHijo id="{{ data }}" value="{{ data }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter" (click)="onCheckBoxHijos()" />
              <label for="{{ data }}"></label>
            </div>
            <p>{{ data }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- sectores -->
    <div *ngIf="checkHijosTipoTasa && checkHijosAnios && checkHijosNiveles">
      <li style="display: grid">
        <div #filtroSectorPadre id="filtroSectorPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroSectorPadre)">
          <div class="item">
            <div class="chkbox noprop">
              <input #checkBoxSectorPadre value="" type="checkbox" id="checkBoxSectorPadre"
                class="chkbox masterbox masterloc " (click)="onCheckBoxPadre(checkBoxSectorPadre)" />
              <label for="checkBoxSectorPadre" class=""></label>
            </div>
            <p>Sector</p>
          </div>
        </div>
      </li>

      <div #filtroSectorHijo id="filtroSectorHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div id="aniobox" class="checkboxes">
          <div class="item" *ngFor="let data of sectores">
            <div class="chkbox">
              <input #checkBoxSectorHijo id="{{ data.descripcion }}" value="{{ data.descripcion }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter" (click)="onCheckBoxHijos()" />
              <label for="{{ data.descripcion }}"></label>
            </div>
            <p>{{ data.descripcion }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Anio Cursado -->
    <div *ngIf="checkHijosTipoTasa && checkHijosAnios && checkHijosNiveles">
      <li style="display: grid">
        <div #filtroAnioCursadoPadre id="filtroAnioCursadoPadre" class="togglers filterButton btn btn-sidebar"
          (click)="showChildFilters(filtroAnioCursadoPadre)">
          <div class="item">
            <div class="chkbox noprop">
              <input #checkBoxAnioCursadoPadre value="" type="checkbox" id="checkBoxAnioCursadoPadre"
                class="chkbox masterbox masterloc " (click)="onCheckBoxPadre(checkBoxAnioCursadoPadre)" />
              <label for="checkBoxAnioCursadoPadre" class=""></label>
            </div>
            <p>Año de cursado</p>
          </div>
        </div>
      </li>

      <div #filtroAnioCursadoHijo id="filtroAnioCursadoHijo" class="toToggle checkbox chkboxdep changes changesdep"
        style="display: none">
        <div id="aniobox" class="checkboxes">
          <div class="item" *ngFor="let data of anioCursado">
            <div class="chkbox">
              <input #checkBoxAnioCursadoHijo id="{{ data.id }}" value="{{ data.id }}" type="checkbox"
                class="chkbox masterbox masterdept childrenDptoFilter"
                (click)="onCheckBoxHijos(checkBoxAnioCursadoHijo)" />
              <label for="{{ data.id }}"></label>
            </div>
            <p>{{ data.value }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Botones -->
    <p style="margin-top: 10px;" *ngIf="checkHijosTipoTasa && checkHijosAnios && checkHijosNiveles">
      Elige un criterio de comparacion:
    </p>
    <div class="row buttons" *ngIf="checkHijosTipoTasa && checkHijosAnios && checkHijosNiveles">
      <div class="col-xl-6 p-0">
        <button (click)="criterioProvincial()" class="showInternetIcon btn btn-main green">
          <div class="btn-icon" style="justify-content: center;">
            <i *ngIf="botonesCheck[0]" class="material-icons">done</i>
          </div>
          <p class="btn-content m-0 p-2">
            Promedio Provincial
          </p>
        </button>
      </div>
    </div>
  </ul>
</div>