import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ComponentsTalkService } from 'src/app/services/components-talk.service';
import { RequestsApiService } from 'src/app/services/requestsApi.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('modal', { static: false }) modal: ElementRef
  @ViewChild('insideElement', { static: false }) insideElement

  constructor(private renderer: Renderer2, private talkService: ComponentsTalkService, private authService: AuthService) { }

  ngOnInit() {
    this.talkService.ObservableModal.subscribe(modal => {
      switch (modal) {
        case 'open':
          this.renderer.setAttribute(this.modal.nativeElement, "style", "margin: 20vh 100%;height: 70vh;width: 70vh;z-index: 10000;background-color: white;overflow: hidden;display: inline;")
          break;

        case 'close':
          this.renderer.setAttribute(this.modal.nativeElement, "style", "display: none;")
          break;
      }

    })
  }

  closeModal() {
    if (this.talkService.modal == 'open') {
      this.talkService.sendModal('close')
    }
  }

  closeOutsideModal(targetElement) {
    const clickedInside = this.insideElement.nativeElement.contains(targetElement.target)
    if (!clickedInside) {
      this.talkService.sendModal('close')
    }
  }

  login(usr, pass) {
    this.authService.login(usr, pass)
    .subscribe(
      data => {
        this.closeModal()
      },
      err => {
        console.log(err)
      })
  }


}
