import { Component, OnInit, Input } from '@angular/core';
/*
Component for transform JSON data to CSV data and download.
Requeriments:
  *
  *

*/
@Component({
  selector: 'app-json-to-csv',
  templateUrl: './json-to-csv.component.html',
  styleUrls: ['./json-to-csv.component.scss']
})
export class JsonToCsvComponent implements OnInit {
  @Input("JSON") jsonData
  @Input("Report") reportData
  @Input("ShowLabel") showLabelData: boolean
  @Input("Title") titleData
  @Input("aName") name

  constructor() { }

  ngOnInit() {
    //JSONToCSVC
  }


  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel, title) {
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';


    //crea el header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {

        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      for (var index in arrData[i]) {
        if (Array.isArray(arrData[i][index])) {
          row += '"array",';
        } else {
          if (typeof arrData[i][index] == 'object') {
            row += '"object",';
          } else {
            row += '"' + arrData[i][index] + '",';
          }

        }
      }

      row.slice(0, row.length - 1);

      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "Reporte_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // crea el elemento para agregar a la barra
    var link = document.createElement("a");
    link.href = uri;
    link.download = fileName + ".csv";
    link.title = title;
    link.classList.add("download-" + title);
    return link.click()
    var div = document.createElement('div');
    let htmlString = '<i class="fas fa-arrow-alt-circle-down"></i>';
    div.innerHTML = htmlString.trim();
    link.append(div);
  }
}
